.pro{
    &Card{
        margin-top: 1.5rem;

        .card-wrap{
            margin-top: 0;
        }

        .card{
            border-color:transparent;
            overflow: hidden;
            p{
                @include fontSize(18px);
                @include mobile{
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
            }
            &:nth-child(2n) {
                transform: translateY(0);
            }
            &:hover{
                border-color: #646464
            }
        }

        .card__item{
            position: absolute;
            left: 10%;
            top: 6%;
            z-index: 1;
        }

        .card__image {
            margin-top: 1rem;
        }
        
        .card__title,
        .card__priceInfo{
            @include fontSize(20px);
        }

        .card__title{
            color:#484747;
        }

        .card__body {
            padding-bottom: 2.75rem;
        }

        @include pad{
            .card__caption {
                margin-top: 0.5rem;
                margin-bottom: 1rem;
            }
            
            .card__body {
                padding-bottom: 1rem;
            }
        }
    }

    &-wrapper{
        @extend %setFlex;
        align-items: flex-start;
        .pro_sidemenu {
            width: 230px;
            padding: 15px 0;
        }
        .rightContent {
            width: calc(100% - 230px);
            padding-left: get-vw(125px);
            padding-top: 0.75%;
        }
        
        @include setWidthRwd(1101px,1500px){
            .pro_sidemenu {
                width: 180px;
            }

            .rightContent {
                width: calc(100% - 180px);
                padding-left: get-vw(50px);
            }
        }

        @include pad1024{
            .pro_sidemenu,
            .rightContent {
                width: 100%;
                padding: 0 0 15px 0;
            }
        }
    }

    // 樣式
    &_sideTitle{
        @include fontSize(30px);
        font-weight: 600;
        color:#080404;
        margin-bottom: 1rem;
    }

    // 
    &_sidemenu_ul{
        &>li {
            list-style: none;
            width: 100%;
            
            &>a {
                color:#676666;
                @include fontSize(22px);
                line-height: 1.5;
                display: block;
                position: relative;
                width: 100%;
                padding: 10px 20px;
                &::after {
                    content: "\f107";
                    font-family: "FontAwesome";
                    display: block;
                    position: absolute;
                    top: 10px;
                    right: 0;
                    font-size: 1.5rem;
                    transform: rotate(0deg);
                    transition: 0.3s;
                }
            }
            
            &>.first_menu{
                display: none;
                &>li {
                    list-style: none;
                    padding:15px 10px;
                    margin-bottom: 2px;
                    &> a {
                        @include fontSize(18px);
                        color: #7f7f7f;
                        display: block;
                        padding-left: 20px;
                        position: relative;
                        line-height: 1;
                    }
                }
            }
            
            &.active{
                &> a{
                    color:#000;
                    &::after {
                        transform: rotate(180deg);
                      }
                }
                &>.first_menu{
                    display: block;
                }
            }
        }
    
        @include setMaxRwd{
            display: none;
            &.active{
                display: block;
            }
        }
    }

    &_sideMobilebtn{
        position: absolute;
        top: 0;
        right: 0;
        .btn{
            padding: 0.5rem 1rem !important;
            color: $colorMain !important;
            border-color: $colorMain !important;
            background-color: transparent !important;
        }
        @include pad1024{
            display: block !important;
        }
    }
}

// 詳細頁
$breakpoint: 480px;

.txtIntro{
    color:$colorTxtIntro
}

.prod{
    &_title{
        display: flex;
        align-items: center;
        border-bottom: 1px solid #313131;
        padding-bottom: 16px;
    }

    &_name{
        padding-left: 10px;
    }

    &_txt{
        color:#5f5f5f;
        margin-top: 1.75rem;
        margin-bottom: 1.75rem;
    }

    &_info{
        border-top: 2px dotted #d1d1d1;
        border-bottom: 2px dotted #d1d1d1;
        padding: 30px 0;

        dl{
            position: relative;
            display: flex;
            margin-bottom: 0;
            padding-left: 20px;
            &+dl{
                margin-top: 0.75rem;
            }
            &.style__dot.pro::before{
                top: 15px;
                transform: translateY(0);
            }
        }
        dt{
            flex: 0 0 100px;
            color:#5f5f5f;
            font-weight: 100;
            padding-right: 10px;
            display: inline-flex;
            justify-content: space-between;
            &:after{
                content: ':';
            }
        }
        dd{ 
            margin-bottom: 0;
        }

        @include setMaxRwd{
            margin-top: 1.5rem;
        }
    }

    &-rightContent{
        align-items: flex-start;
    }

    &Wrap_img{
        margin-top: 2rem;
        .swiper-container {
            overflow: hidden;
            width: 100%;
            margin-left: auto;
            margin-right: auto;
            @include setMinRwd {
              min-height: 320px;
            }
            &-wrapper {
              display: flex;
              flex-flow: column nowrap;
              @include setMinRwd {
                flex-flow: row nowrap;
                align-items: center;
              }

              @include pad1080{
                flex-flow: column wrap;
              }
              width: 100%;
            }
        }
          
        .swiper-button {
            &-next,
            &-prev {
                width: 25px !important;
                height: 27px !important;
                background-image: url('../images/icon-arrow-right.png') !important;
                background-size: 25px 27px;
            }

            @include setStyleMinRwd(1101px){
                &-prev,
                &-next{
                    height: 19px !important;
                    left: calc(10% - 13px);
                    background-size: 25px 19px;
                }

                &-prev{
                    top: 14%;
                    background-image: url('../images/icon-arrow-top.png') !important;
                }

                &-next{
                    top: auto;
                    bottom: 9%;
                    background-image: url('../images/icon-arrow-bottom.png') !important;
                }
            }

            @include pad1080{
                &-prev,
                &-next{
                    bottom: 9.5%;
                    top: auto;
                }

                &-prev{
                    left: -5px;
                    transform: translateX(0%) rotate(-180deg);
                }

                &-next{
                    right: -5px;
                }
            }
            @include mobile{
                &-prev,
                &-next{
                    bottom: 6%;
                }
            }
        }
        
        .swiper-slide {
            text-align: center;
            background-size: cover;
            background-position: center;
            background-color: #fff;
            
            /* Center slide text vertically */
            display: flex;
            flex-flow: column nowrap;
            justify-content: center;
            align-items: center;
        
            /* Slide content */
            .description,
            .title {
                display: block;
                opacity: 0;
                transition: 0.5s ease 0.5s;
            }
            
            &-active {
                .description,
                .title {
                opacity: 1;
                }
                .title {
                margin-bottom: 0.5rem;
                font-size: 24px;
                color: #000;
                transition: opacity 0.5s ease 0.5s;
                }
                .description {
                font-size: 16px;
                color: #777;
                transition: opacity 0.5s ease 0.75s;
                }
            }
        }
        
        .gallery-top {
            position: relative;
            width: 100%;
            border-radius: 40px;
            border:#686868 1px solid;
            @include setMinRwd {
                width: 80%;
                margin-left: 10px;
                height: 480px;
            }

            @include setWidthRwd(1101px,1500px){
                height: 380px;
            }
            
            @include pad1080{
                width: 100%;
                height: 500px;
                margin-left: 0;
            }

            @include mobile{
                height: 40vh;
            }
        }
        
        .gallery-thumbs {
            width: 100%;
            padding-top: 10px;
            @include setMinRwd {
                width: 20%;
                height: calc(50vh / 3);
                padding: 0px 0.75rem 0px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
            }

            @include setWidthRwd(1101px,1500px){
                min-height: 250px;
            }

            @include pad1080{
                order: 1;  
                width: calc(100% - 40px);
                margin: 10px 20px 0;
                min-height: 2vh;
            }

            @include pad{
                height: 16vh;
            }

            @include mobile{
                height: 13vh;
            }

            .swiper-wrapper {
                flex-direction: row;
                @include setStyleMinRwd(1101px) {
                    flex-direction: column;
                }
            }

            .swiper-slide {
                width: 25%;
                flex-flow: row nowrap;
                overflow: hidden;
                @include setMinRwd {
                    flex-flow: column nowrap;
                    width: 99%;
                }
                
                height: 100%;
                opacity: 0.75;
                border-radius: 20px;
                border:#e4e4e4 1px solid;
                cursor: pointer;
                .swiper-button-next,
                .swiper-button-prev {
                    position: static;
                    transform: translateX(0%) rotate(90deg);
                }
                .swiper-button-prev{
                    order: -1;
                }
            }
            .swiper-slide-thumb-active {
                opacity: 1;
                border:#686868 1px solid;
            }
        }
    }

    &Wrap_intro{
        margin-top: 2rem;
        @include setMinRwd{
            padding-left: 2rem;
        }
    }

    &Wrap_info{
        margin-top: 5rem;
        .nav-tabs{
            border-top: 1px solid #323232;
            border-bottom-width:1px;
            
            &>li{
                padding-left: 50px;
                padding-right: 50px;
                &>a{
                    position: relative;
                    display: block;
                    text-align: center;
                    color:#706f6f;
                    padding: 15px;
                    &::before{
                        display: none !important;
                    }
                    &:hover,
                    &.active{
                        color:#000;
                        &::after {
                            content: '';
                            display: block;
                            width: 100%;
                            height: 3px;
                            background-color: #515151;
                            position: absolute;
                            left: 50%;
                            bottom: -2.95px;
                            transform: translateX(-50%);
                        }
                    }
                }
            }

            @include setMinRwd{
                padding-left: 40px;
                &>li{
                    @include fontSize(20px);
                }
            }

            @include setMaxRwd{
                &>li{
                    flex: 1;
                    padding: 0;
                    font-size: get-vw-mobile(22px);
                    &>a{
                        padding-left: 0;
                        padding-right: 0;
                    }
                }
            }
        }

        @include setMaxRwd{
            margin-top: 1rem;
        }
    }

    @include pad1080{
        &Wrap_img,
        &Wrap_intro{
            max-width: 100%;
            flex: 0 0 100%;
        }
    }
}

// 
.tab-content{
    border-bottom: 1px solid #323232;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    .orderNote-txt{
        color:#51504f;
        @include fontSize(16px);
        .style__dot{
            padding-left: 1.5rem;
            &::before {
                left: 7px;
                top: 12px;
            }
        }
    }

    // 
    .orderNote-title{
        @include setMinRwd{
            flex: 0 0 337px;
            padding-right: 2rem;
            margin-left: 0.5rem;
        }
    }
}

// 產品詳細頁 : 表格用
.prod_intro{
    @include borderRadius(20);
    border: #B5B5B5 2px solid;
    background-color: #fff;
    padding:0.5rem;
    margin:1.5rem 3rem;

    &_head,
    &_body{
        display: flex;
        align-items: center;
        justify-content: space-around;
        @include borderRadius(12);
        line-height: 1.5;
        padding: 0.75rem 0;
        @include fontSize(18px);
        .item{
            flex: 1;
            text-align: center;
        }
    }

    &_head{
        background-color: $colorMain;
        color:#fff
    }

    &_body{
        &:nth-child(odd){
            background-color: #FAE5D2;
        }
    }

    @include setMaxRwd{
        margin:0;
        &_head,
        &_body{
            font-size: get-vw-mobile(20px);   
        }
    }
}

.prod_introContent{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    @include setMinRwd{
        * + .imgSample{
            margin-left: 15px;
        }
    }
    @include mobile{
        flex-direction: column
    }
}

.imgSample{
    @extend %setFlex;
    margin-top: 5px;
    .imgList{
        margin: 0 2px 2px;
    }
}

.prod_step{
    @extend %setFlex-center;
    justify-content: center;
    @include setMinRwd{
        margin:4rem 0 8rem;
    }

    li{
        position: relative;

        &:nth-child(1){
            z-index: 5;
        }
        &:nth-child(2){
            z-index: 4;
        }
        &:nth-child(3){
            z-index: 3;
        }
        &:nth-child(4){
            z-index: 2;
        }

        &::after {
            content: '';
            display: block;
            width: 150px;
            height: 40px;
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            background-image: url("../images/partner-shadow01.png");
            position: absolute;
            bottom: -50px;
            left: 50%;
            transform: translateX(-50%);
            opacity: 0.75;
        }

        @include setMinRwd{
            &+li{
                margin-left: -4rem;
            }
        }

        @include setMaxRwd{
            &::after {
                bottom: -40px;
            }
        }

        @include pad{
            flex: 0 0 50%;
            margin-bottom: 3rem;
        }
    }

    &Img{
        position:relative;
    }

    &Caption{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        line-height: 1.5;
        text-align: center;
        .title{
            @include fontSize(24px);
            &Stpe01{
                color:#f54545;
            }
            &Stpe02{
                color:#e6cb00;
            }
            &Stpe03{
                color:#11c2c9;
            }
            &Stpe04{
                color:#9265a3;
            }
            &Stpe05{
                color:#41404a;
            }
        }
        .txtStep{
            @include fontSize(16px);
            color:#41404a;
            text-transform: uppercase;
        }

        @include pad{
            line-height: 1.25;
            .title{
                font-size: get-vw-mobile(25px);
            }
            .txtStep{
                font-size: get-vw-mobile(20px);
            }
        }
    }
}
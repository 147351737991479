.news{
    &__info{
        margin-top:0.75rem;
        .title{
            display: flex;
            align-items: center;
            &-head{
                position: relative;
                margin-bottom: 5px;
                line-height: 1.25;
            }
        }

        .btn{
            border-radius: 25px;
            padding: 0.5rem 1rem;
            margin-right: 1rem;
            margin-bottom: 5px;
            border-style: dotted;
            @include fontSize(20px);
            cursor: default !important;
        }
        .txt{
            @include fontSize(18px);
            color:#706e6e;
            padding-top: 5px;
            padding: 0.5rem;
        }

        @include setMinRwd{
            padding-right: 2rem;
            .title{
                &-head{
                    &::after{
                        content: '';
                        display: block;
                        width: 30px;
                        height: 30px;
                        @include setBackSet(center,contain);
                        background-image: url('../images/icon-clock.png');
                        position: absolute;
                        right: -40px;
                        top: 0;
                        opacity: 0;
                    }
                }
            }
        }

        @include mobile{
            .btn{
                flex: none;
            }
        }
    }

    &__list{
        display: flex;
        width: 100%;
        border-bottom: 2px solid #cecece;
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        .card{
            border:#aaaaaa 1px solid;

            &__img,
            &__body{
                display: flex;
            }

            &__img{
                width: 100%;
                align-items: center;
                justify-content: center;
            }

            &__body{
                max-width: 100%;
                flex-direction: row;
                justify-content: space-around;
            }
        }

        .timeList{
            padding:0.5rem 1rem;
        }

        &:hover{
            background-color: #fff;
            .btn{
                background-color: #000;
                color:#fff;
            }
            .title-head{
                &::after{
                    opacity: 1;
                }
            }
        }

        @include setMinRwd{
            .card{
                &__img{
                    flex: 0 0 280px;
                }
            }
            .timeList{
                padding-top: 1rem;
                padding-left: 4rem;
                padding-right: 4rem;
            }
        }

        @include mobile{
            .card__img{
                img{
                    width: 100%;
                }
            }

            &,
            .card__body{
                flex-direction: column;
                align-items: flex-start
            }
            .timeList {
                display: flex;
                align-items: flex-end;
                p{
                    margin-bottom: 0;
                }
                .date-ym{
                    padding-left: 1rem;
                }
            }
        }
    }

    // 詳細頁
    &__detail_title{
        .card__body{
            justify-content: flex-start;
        }

        .btn{
            background-color: #000;
            color: #fff !important;
        }
        .title-head{
            &::after{
                opacity: 1;
            }
        }
    }

    &__detail_txt{
        padding: 1rem 0.5rem;
        border-bottom: 1px solid #323232;
        @include setMinRwd{
            padding: 3rem;
        }
    }
} 

.content-news-d{
    padding: 0 1rem 1rem;
    @include setMinRwd{
        padding: 2rem;
    }
}

// 日期樣式
.timeList{
    font-family: $NotoSerif;
    text-align: center;
    line-height: 1;
    font-weight: 600;
    .date{
        color:$colorMain;
        @include fontSize(48px);
        font-style: italic;
    }
    .date-ym{
        color:#838282;
        @include fontSize(20px);
    }
}
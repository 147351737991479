// 聯絡我們
.contact{
    &-form {
        legend{
            line-height:1.5;
            color:#656565;
            @include fontSize(18px);
            margin-bottom: 1rem;
        }

        label{
            margin-bottom: 0;
        }

        .form{
            &-control{
                &,
                &:hover,
                &:focus{
                    border:1px solid #9c9b9c;
                    background-color: #fff;
                }
            }

            &-wrap{
                margin:1.5rem auto 0;
                @include setMinRwd{
                    margin-top: 3rem;
                    padding-left: 2.5rem;
                    padding-right: 2.5rem;
                }
                @include setWidthRwd(1100px,1600px){
                    padding-left: 0.5rem;
                }
            }

            &-group{
                display: flex;
                align-items: flex-start;
                &>label{
                    flex: none;
                    padding-top: 0.5rem;
                    padding-left: 22px;
                    padding-right: 2rem;
                    margin-right: 0;
                    @include fontSize(18px);
                    position: relative;
                    &:first-child{
                        &::before {
                            content: "";
                            background: #56555a;
                            width: 10px;
                            height: 10px;
                            border-radius: 100%;
                            position: absolute;
                            left: 0;
                            top: 20px;
                        }
                    }
                }

                @include setMinRwd{
                    align-items: center;
                    margin-bottom: 1.3rem;
                }
    
                @include setWidthRwd(1101px,1600px){
                    margin-bottom: 1.48rem;
                }

                @include pad1024{
                    flex-wrap: wrap;
                    align-items: center;
                    &>label{
                        width: 100%;
                        margin-bottom: 0.25rem;
                        padding-right: 0;
                    }
                    
                    &>.form-control{
                        flex: 1;
                    }

                    &>.formOtherWrap{
                        padding-left: 0.75rem;
                        max-width: 10rem;
                    }
                }
            }
        }

        .formOtherWrap{
            display: flex;
            width: 100%;
            padding-left: 2rem;
            &>label{
                padding-right: 1rem;
                flex: none;
                margin-right: 0;
            }
            @include setMinRwd{
                justify-content: space-between;
            }
            
            @include setWidthRwd(1100px,1600px){
                padding-left: 1rem;
                .radio_style{
                    margin-top: 15px;
                }
            }

            @include pad1024{
                padding-top: 1rem;
                padding-left: 0rem;
            }
        }

        .radio_style{
            margin-top: 0 !important;
            input{
                &+label{
                    @include fontSize(18px);
                }
            }
        }

        .btn-wrap{
            li{
                display: flex;
                justify-content: center;
                width:100%;
            }
            @include mobile{
                flex-direction: column;
                li{
                    margin-bottom: 10px;
                }
            }
        }
        
        @include setMaxRwd{
            .radio_style{
                margin-top: 0 !important;
                margin-right: 10px;
            }
        }
    }

    &-info{
        &>.txt{
            width: 100%;
            color:#656565;
            @include fontSize(18px);
            @include pad1024{
                @include fontSize(18px);
                margin-top: 1rem;
            }
        }

        &-wrap{
            position: relative;
            background-color: #313131;
            padding: 3rem 2rem 1.25rem;
            margin-top: 60px;
            @include fontSize(18px);

            &::before{
                content: '';
                display: block;
                width: 60px;
                height: 60px;
                background-color: $colorMain;
                position: absolute;
                left: -30px;
                top: -30px;
            }

            li{
                display: flex;
                align-items: flex-start;
                @include setMinRwd{
                    margin-bottom: 1rem;
                }
            }

            .icon{
                width: 45px;
                text-align: center;
                flex: none;
                margin-right: 10px;
                margin-top: -3px;
            }
            
            .txt{
                color:#fff;
            }
            
            .text-muted{
                color:#c0bfbf !important;
            }
            
            @include pad1024{
                padding-left: 1rem;
                padding-right: 1rem;
            }
        }
    }
    
    &-block {
        position: relative;
        background: #fff;
        padding:1.5rem;
        @include setMinRwd{
            display: flex;
            padding:3rem 4rem;
            .contact-form,
            .contact-info{
                width: 50%;
            }
            .contact-form{
                padding-right: 70px;
            }
            
            .contact-info{
                padding-left: 70px;
            }
        }

        @include setWidthRwd(1100px,1600px){
            padding:2rem;
            .contact-form{
                padding-right: 1rem;
            }
            
            .contact-info{
                padding-left: 1rem;
            }
        }

        @include pad1024{
            .contact-info{
                padding-top: 0.5rem;
            }
        }

        .formPickWrap{
            display: flex;
            justify-content: space-between;
            width: 100%;
            @include setMinRwd{
                padding-left: 1rem;
            }
        }

        // 付款回報用
        &.payReturn{
            background-color: transparent;
            .contact-form {
                width: 100%;
                padding-right: 0px;
                .form-group{
                    &>label{
                        padding-top: 0;
                        &:first-child{
                            &::before {
                                width: 6px;
                                height: 6px;
                                top: 15px;
                            }
                        }
                    }

                    .form-control{
                        background-color: transparent;
                        &+ .form-control{
                            margin-top: 0.25rem;
                        }
                        @include setMinRwd{
                            &+ .form-control{
                                margin-left: 20px;
                                margin-top: 0;
                            }
                        }
                    }

                    @include setMinRwd{
                        &>label{
                            flex: 0 0 145px;
                        }
                    }
                    
                    @include pad1024{
                        flex-direction: column;
                    }
                }
                
                
                .formMainWrap{
                    @extend %setFlex-center;
                    width: 100%;
                }
     
                .formPickWrap{
                    @include setMinRwd{
                        max-width: 250px;
                    }

                    @include pad1024{
                        justify-content: flex-start;
                    }
                }

                select,
                input{
                    @include setMinRwd{
                        &.form-control{
                            flex: none;
                            max-width: 350px;
                        }
                    }
                }

                .zip{
                    &-county,
                    &-districe{
                        max-width: 160px;
                        @include setStyleMinRwd(1400px){
                            max-width: 182px;
                        }
                        @include pad1024{
                            max-width: 100%;
                        }
                    }
                }

                .formOtherWrap {
                    max-width: 100%;
                    padding-left: 0;
                    @include setMinRwd{
                        flex: 0 0 900px;
                        padding-left: 1rem;
                    }

                    @include pad1024{
                        align-items: flex-start !important;
                        &,
                        &>.twzipcode-style{
                            flex-direction: column;
                        }

                        &>span{
                            padding-bottom: 10px;
                        }
                        
                        .select_style{
                            margin-bottom: 10px;
                        }
                    }
                }
            }

            .form-wrap {
                margin-top: 0.5rem;
                padding-left: 0rem;
                padding-right: 0rem;
            }

            .btn-wrap{
                li{
                    width: auto;
                    flex: none;
                }
            }

            @include pad1024{
                padding-top: 0.25rem;

                .icon{
                    display: none;
                }

                .btn-wrap{
                    flex-direction: row;
                }
            }
        }
    }
}

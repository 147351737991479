.nav-fostrap {
  @extend %setFlex-center;
  justify-content: space-between;
  background: $colorBack;

  ul {
    width: 100%;
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: block;
    z-index:2;
    @include setMinRwd{
      text-align: right;
    }
  }

  li {
    list-style-type: none;
    margin: 0;
    display: inline-block;
    position: relative;

    a {
      position: relative;
      z-index: 1;
      @include fontSize(20px);
      line-height: 1;
      color: $colorSubs;
      display: inline-block;
      outline: 0;
    }

    &::before{
      content: '';
      display: block;
      width: 45px;
      height: 10px;
      @include setBackSet(center,contain);
      background-image: url('../images/linkActive.png');
      position: absolute;
      bottom: 0;
      left: 50%;
      margin-left: -27.5px;
      opacity: 0;
    }

    ul.dropdown {
      width: 100%;
      min-width: 100px;
      position: absolute;
      top: calc(100% + 15px);
      left: 50%;
      transform: translateX(-55%);
      padding-top: 0;
      opacity: 0;
      visibility: hidden;
      z-index: 100;
      text-align: center;
      background-color: #fff;
      border:#898989 1px solid;
      padding: 3px;

      &::before,
      &::after{
        content: '';
        width: 0;
        height: 0;
        border-bottom: #898989 10px solid;
        border-top: transparent 10px solid;
        border-left: transparent 10px solid;
        border-right:transparent 10px solid ;
        position: absolute;
        top: -20px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;
      }
      &::before{
        border-bottom-color:#fff;
        top: -19px;
        z-index: 2;
      }

      li {
        &::before{
          display: none;
        }

        &,
        a{
          display: block;
          width: 100%;
        }

        a {
          padding: 0.65rem 1rem;
          font-weight: 400;
          color: #000;
          line-height: 1;
          @include fontSize(18px);
          &::after{
            display: none;
          }
        }
        &:hover{
          background-color: #E5E5E5;
        }
      }
    }
    
    &:hover,
    &.active{
      &::before{
        opacity: 1;
      }

      a {
        color: $colorMain;
        &::after{
          opacity: 1;
        }
      }
    }
    
    &:hover {
      ul.dropdown {
        opacity: 1;
        visibility: visible;
      }
    }

    @include setMinRwd{
      &+li{
        &::after{
          content: '';
          display: block;
          @include setSize(5px,5px);
          background-color: $colorSubs;
          position: absolute;
          top: 50%;
          left: -12px;
          transform: translate3d(0,-50%,0);
          border-radius: 50%;
          transition:0.25s;
          opacity: 1;
          z-index: -1;
        }
      }
      

      ul.dropdown {
        li {
          &::after{
            display: none;
          }
        }
      }
    }
  }

  &-link{
    display: flex;
    align-items: center;
    flex: 1;
    @include setMinRwd{
      &>ul{
        &>li {
          padding:15px 30px 15px 15px;
        }
      }
    }

    @include setStyleRwd(1400px){
      &>ul{
        &>li {
          padding-left:1rem;
          padding-right:1rem;
        }
      }
    }

    @include setStyleRwd(1200px){
      &>ul{
        &>li {
          padding-left:0.75rem;
          padding-right:0.75rem;
        }
      }
    }
    
    @include pad1024{
      flex-direction: column;
      padding-left: 10px;
      padding-right: 10px;
      ul{
        width: 100%;
      }
    }
  }

  @include setMinRwd{
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  @include setWidthRwd(1005px,1600px){
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  @include setWidthRwd(1005px,1200px){
    li{
      a{
        font-size: 16px;
      }
    }
  }

  @include setMaxRwd{
   li{
    &::before{
      display: none;
    }
   }
  }

}

.nav-header{
  position: sticky;
  top: 0;
  z-index: 1003;
  background: #fff;
  border-bottom: 2px solid #d2d2d2;
  @include setMinRwd{
    &-index{
      border-bottom: none;
      &,
      .nav-fostrap{
        background-color: transparent;
      }
    }
  }
}

.nav-fostrap-menuBg{
  position: absolute;
  left: 0;
  top: 5rem;
  @include setSize(100%,65px);
  background: rgba(#d4e8ce,.37);
  z-index: 1;
  display: none;
  &.active{
    display: block;
  }
}

.arrow-down {
  position: absolute;
  right: 1rem;
}

.title-mobile {
  display: none;
}

@include setStyleMinRwd(1025px) {
  .container {
    width: 96%;
  }
  
  .nav-bg-fostrap,
  .arrow-down {
    display: none;
  }

}

@include setStyleRwd(1200px){
  .nav-fostrap {
    ul{
      li{
        ul.dropdown {
          li {
            a {
              @include fontSize(16px);
            }
          }
        }
      }
    }
  }
}

@include pad1024{
  .nav-fostrap {
    background: #fff;
    @include setSize(55vw,100%);
    display: block;
    position: fixed;
    left: -100%;
    top: 0px;
    transition: left 0.25s ease;
    z-index:12;
    li{
      a{
        &::after{
          display: none !important;
        }
      }
    }
  }

  .title-mobile {
    display: block;
    max-width: 25vw;
  }

  .nav-logo{
    display: block;
    margin: auto;
    text-align: center;
    padding-top: 5px;
  }

  .mobile-closeBtn{
    @include setSize(45px,45px);
    background-color: #000;
    text-align: center;
    color:$colorBack;
    position: absolute;
    right: -45px;
    top: calc(-90px - 1rem);
    z-index: 6;
    @include fontSize(25px);
    a{
      display: flex;
      align-items: center;
      justify-content: center;
      @include setSize(100%,100%);
    }
  }

  .nav-fostrap{
      &.visible {
          left: 0px;
          transition: left 0.25s ease;
      }
  }

  .nav-bg-fostrap {
    display: inline-block;
    vertical-align: middle;
    width: 100%;
    min-height: 65px;
    margin: 0;
    padding-top: 6px;
    padding-bottom: 6px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    z-index:3;
  }

  .navbar-fostrapClick {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    margin: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    padding: 12px;

    span {
      height: 2px;
      background: $colorMain;
      margin: 3px 5px;
      display: block;
      width: 20px;

      &:nth-child(2),
      &:nth-child(3) {
        width: 20px;
      }
    }

    .txt{
      @include fontSize(14px)
    }
  }

  .nav-fostrap {
    ul {
      padding-top: 10px;
    }

    li {
      display: block;
      border-bottom: #eee 1px solid;
      a {
        display: block;
        @include fontSize(18px);
        padding: 12px;
      }

      &:first-child:hover a {
        border-radius: 0;
      }

      ul.dropdown {
        position: static;
        display: none !important;
        border: none;
        margin-left: 0;
        margin-top: 2px;
        transform: translateX(0);
        &::before,
        &::after{
          display: none;
        }

        &.active{
          display: block !important;
          opacity: 1;
          visibility: visible;
        }

        li {
          padding: 0;
          a {
            border-bottom: none;
            text-align: left;
          }
        }
      }

      &:hover,
      &.active {
        a {
          background: $colorMain;
          color: #fff !important;
        }
      }

      a{
        &.active{
          background: darken($colorMain, 5%);
          color: #fff !important;
          .arrow-down{
            transform: rotate(180deg);
          }
        }
      }

      ul.dropdown {
        margin-bottom: 10px;
        li {
          a {
            padding: 10px;
            @include fontSize(16px);
            background-color: #fff !important;
            color:$colorSubs !important;
          }
          &~li{
            border-bottom: none;
          }
        }
      }
    }
  }
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
}

.nav-overlay {
  @include setSize(100%,100%);
  background: rgba(0,0,0,0.4);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  display: none;
  opacity: 0;
  &.show{
      display: block;
      opacity: 1;
  }
}

// 付款回報單按鈕
.dropdown-reply{
  flex:none;
  .dropdown-toggle{
    display: flex;
    align-items: center;
    @include fontSize(16px);
    line-height: 27px;
    background-color: #000;
    color:#fff;
    box-shadow: 0 2px 10px 2px rgba(0,0,0,0.2);
    border-radius: 20px;
    padding: 0em 0.75em 0.1em 0.5em;
    &:hover{
      background-color: $colorMain;
    }
    &::after{
      display: none;
    }
    .icon{
      flex:none;
      margin-right: 0.1rem;
      margin-top: 3px;
    }
  }

  .dropdown-menu{
    min-width: 5.75rem;
    border-color:$colorMain;
    padding:0;
    margin-top: 0.25rem;
    border-radius: 1rem;
    overflow: hidden;
    .dropdown-item {
      text-align: center;
      padding-top: 0.35rem;
      padding-bottom: 0.35rem;
      @include setMinRwd{
        &:hover{
          background-color: $colorMain;
          color:#fff;
        }
        &~.dropdown-item{
          border-top: 1px solid $colorMain;
        }
      }
    }
  }

  @include setMinRwd{
    margin-left: 2rem;
  }

  @include setMaxRwd{
    width: 100%;
    margin-top: 1rem;
  }
}
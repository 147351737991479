.index{
    &-content{
        padding: 2rem 1rem;
        overflow: hidden;
        @include setMaxRwd{
            padding: 3rem 1.5rem;
        }
    }



    // News
    &-news{
        padding-bottom: 10%;
        margin-top: 3%;
        margin-bottom: -7%;
        &::before{
            content: '';
            display: block;
            width: 105%;
            height: 10%;
            background-color: #fff;
            border-top-left-radius: 80% 40%;
            border-top-right-radius: 80% 40%;
            min-height: 600px;
            position: absolute;
            left: 50%;
            top: 5px;
            transform: translateX(-50%);

            @include pad{
                border-top-left-radius: 80% 30%;
                border-top-right-radius: 80% 30%;
                min-height: get-vw-mobile(300px);
                height: 5%;
                top: 0px;
            }

            @include mobile{
                min-height: get-vw-mobile(1000px);
                border-top-left-radius: 100% 30%;
                border-top-right-radius: 100% 30%;
            }
        }

        &::after{
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            background-color: #fff;
            position: absolute;
            top: 15%;
            left: 0;
            z-index: -1;
        }

        @include setMinRwd{
            padding-top: 5%;
        }

        @include setMaxRwd{
            margin-top: 10%;
            padding-bottom: 20%;
        }

        @include setWidthRwd(1101px, 1400px){
            padding-top: 8%;
            padding-left: 5rem;
            padding-right: 5rem;
            margin-bottom: -10%;
        }

        @include setWidthRwd(768px, 1000px){
            padding-top: 15%;
            margin-top: 10%;
            margin-bottom: -11%;
        }

        @include mobile{
            margin-top: 0;
            padding-left: 0;
            padding-right: 0;
            padding-top: 20%;
            .wrapper-1400,
            .wrapper-1500{
                padding-left: 1rem;
                padding-right: 1rem;
            }
        }

    }

    // 作品之星
    &-work{
        background-color: #fff;
        overflow: visible;
        margin-bottom: get-vw(-500px);
        &>div{
            z-index: 2;
        }

        &::after{
            content: '';
            display: block;
            width: 100%;
            height: get-vw(475px);
            @include setBackSet(bottom,contain);
            background-image: url('../images/index-news-bottomBg.png');
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 0;
        }

        .symbol{
            padding-top: 0;
            &::before {
                top: -30px;
                @include setMaxRwd{
                    top: -2.5rem;
                }
            }
        }

        @include setWidthRwd(1101px, 1400px){
            padding-left: 5rem;
            padding-right: 5rem;
            margin-bottom: get-vw(-600px);
        }

        @include pad{
            margin-bottom: get-vw-mobile(-320px);
        }
    }

    &-workCard{
        margin-top: 2rem;
        .card-wrap{
            justify-content: center;
        }
        .card{
            border-radius: 2rem;
            overflow: visible;

            .styleRadiu__image{
                border-radius: 2rem;
                box-shadow: 0 11px 15px -2px rgba(0,0,0,0.25);
                overflow: hidden;

                height: 0;
                padding-bottom: 100%;
                img{
                    @extend %imgPositon-center;
                    height: 100%;
                }
                @include setMinRwd{
                    max-width: calc(100% - (60px / 2));
                    border-radius: 70px;
                    padding-bottom: 88%;
                }
            }

            &__body {
                padding: 1rem;
                @include setMinRwd{
                    padding: 1.5rem 2.75rem 3.75rem;
                }
                @include setWidthRwd(1100px,1500px){
                    padding: 1.25rem 1rem 2.25rem 2rem;
                }
            }

            &__title {
                @include setMinRwd{
                    @include fontSize(24px);
                }
            }

            &__caption {
                margin-top: 1rem;
                margin-bottom: 1.25rem;
            }

            // 
            @include setMinRwd{
                border-radius: 70px;
                
                p{
                    @include fontSize(16px);
                }

                &:nth-child(odd){
                    .styleRadiu__image{
                        transform: rotate(-11deg);
                        transform-origin: 18% calc(50% - 60px);
                    }
                }
                &:nth-child(even){
                    transform: translateY(-5rem);
                    .styleRadiu__image{
                        transform: rotate(11deg);
                        transform-origin: 90% calc(50% + 100px);
                    }
                }
            }

            @include pad1080{
                &__btn{
                    .btn{
                        display: block;
                    }
                }
            }
        }

        @include setMinRwd{
            margin-top: 10rem;
        }
        
        @include setWidthRwd(1101px, 1400px){
            margin-top: 8rem;
            .card{
                flex: 0 0 calc((100% / 4) - 20px);
                max-width: calc((100% / 4) - 20px);
                margin-left: 10px;
                margin-right: 10px;
            }
        }
    }

    // 服務項目
    &-service{
        padding-top: get-vw(400px);
        padding-bottom: get-vw(250px);
        margin-bottom: get-vw(-250px);

        &::before{
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            @include setBackSet(top,cover);
            background-image: url('../images/index-service-bg.png');
            position: absolute;
            left: 0;
            top: 0;
        }

        // 
        .partner-portfolio-Wrap{
            text-align: center;
            margin-top: 5rem;
            @include setMaxRwd{
                padding-top: 4rem;
            }
            .partnerTitle{
                display: inline-block;
                width: auto;
                margin-top: -2rem;
                @include setMinRwd{
                    &::before {
                        top: 15px;
                    }
                }
                @include setMaxRwd{
                    &::before {
                        display: none;
                    }
                }
            }
            &::before {
                content: 'Product Item';
                width: 100%;
                color: rgb(252, 255, 255);
                -webkit-text-stroke: 10px #fff;
                opacity: 0.5;
                @include setMaxRwd{
                    -webkit-text-stroke: 5px #fff;
                    font-size: get-vw-mobile(71px);
                    letter-spacing: 1px;
                }
            }
        }
        
        @include setMaxRwd{
            &::before{
                top: -140vw;
                height: 765vw;
            }
        }

        @include pad1080{
            padding-top: get-vw-mobile(70px);
            margin-bottom: get-vw-mobile(-130px);
        }
        
        @include mobile{
            &::before{
                top: -126vw;
            }
        }
    }

    &-serviceCard{
        .card{
            text-align: center;
            border:none;
            background: transparent;
            @include setMinRwd{
                flex:0 0 calc(100% / 6 - 50px);
                max-width: calc(100% / 6 - 50px);
            }
            @include setMaxRwd{
                flex:0 0 calc(100% / 3 - 10px);
                max-width: calc(100% / 3 - 10px);
                margin-bottom: 1rem;
                &__image > img {
                    height: auto
                }
            }

            &__caption{
                margin-top: 0;
                font-size: get-vw(24px);
                @include setMaxRwd{
                    font-size: get-vw-mobile(25px);
                }
            }
        }
    }
}

// Banner
.index-banner{
    position: relative;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
    padding-top: 2rem;
    padding-bottom: 8rem;
    &::after{
        content: '';
        display: block;
        width: 100%;
        height: 10vw;
        @include setBackSet(bottom,cover);
        background-image: url('../images/index-banner-bottomBg.png');
        position: absolute;
        bottom: 0;
        left: 0;
    }

    // 
    .myIntrotxt{
        width: 100%;
        padding-left: 3rem;
        padding-top: 1rem;
        padding-bottom: 1rem;
        &__title{
            font-size: get-vw(60px);
            color:#212020;
            font-weight: 900;
            line-height: 1.25; 
        }
        
        &__text{
            font-size: get-vw(24px);
            color:#676666;
            margin: 40px 0;
        }

        &__btn{
            .btn{
                font-size: get-vw(24px);
                padding: 1.5rem 3rem !important;
                border: none;
                color:#fff;
                /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#40bafe+0,a442ff+100 */
                background: rgb(64,186,254); /* Old browsers */
                background: -moz-linear-gradient(left,  rgba(64,186,254,1) 0%, rgba(164,66,255,1) 100%); /* FF3.6-15 */
                background: -webkit-linear-gradient(left,  rgba(64,186,254,1) 0%,rgba(164,66,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
                background: linear-gradient(to right,  rgba(64,186,254,1) 0%,rgba(164,66,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#40bafe', endColorstr='#a442ff',GradientType=1 ); /* IE6-9 */             
                transition: 0.25s;
                &:hover{
                    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#fc02af+0,feb109+99 */
                    background: rgb(252,2,175); /* Old browsers */
                    background: -moz-linear-gradient(left,  rgba(252,2,175,1) 0%, rgba(254,177,9,1) 99%); /* FF3.6-15 */
                    background: -webkit-linear-gradient(left,  rgba(252,2,175,1) 0%,rgba(254,177,9,1) 99%); /* Chrome10-25,Safari5.1-6 */
                    background: linear-gradient(to right,  rgba(252,2,175,1) 0%,rgba(254,177,9,1) 99%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fc02af', endColorstr='#feb109',GradientType=1 ); /* IE6-9 */
                }
            }
        }

        &__iconBottom{
            position: absolute;
            &.left{
                left: 0;
                bottom: -8vw;
                opacity: 1;
                animation: swing ease-in-out 1s infinite alternate;
            }

            &.right{
                right: 4vw;
                bottom: -6vw;
                animation: swing ease-in-out 1s 0.5s infinite alternate;
            }

            @include setWidthRwd(1101px, 1500px){
                &.left{
                    bottom: -12vw;
                }
                &.right{
                    bottom: -8vw;
                }
            }
        }

        @include setMinRwd{
            padding-top: get-vw(75px);
        }

        @include setMaxRwd{
            &__title{
                font-size: get-vw-mobile(40px);
            }
            
            &__text{
                font-size: get-vw-mobile(20px);
            }
    
            &__btn{
                .btn{
                    font-size: get-vw-mobile(18px);
                    padding: 1rem 3rem !important;
                }
            }
            
            &__iconBottom{
                &.left{
                    left: 1rem;
                    bottom: -8rem;
                }
            }
        }

        @include mobile{
            &__title{
                font-size: get-vw-mobile(60px);
            }
            
            &__text{
                font-size: get-vw-mobile(24px);
            }
    
            &__btn{
                .btn{
                    font-size: get-vw-mobile(26px);
                    padding: 1rem 3rem !important;
                }
            }
        }
    }

    // 
    .mySwiper {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .main {
        position: relative;
        width: 100% !important;
        @include setMaxRwd{
        }
    }
    
    .center {
        position: absolute;
        left: 50%;
        width: get-vw(960px);
        height: get-vw(935px);
    
        @include setMaxRwd {
            width: 100%;
            height: 96%;
        }
    
        .bottle-bg {
            width: 880px;
            height: 800px;
            object-fit: cover;
            @include setMaxRwd{
                width: 260px;
                height: 390px;
            }

            @include mobile{
                width: 220px;
                height: 340px;
            }
        }
        
        .bottle-bgColor{
            display: block;
            width: get-vw(870px);
            height: 0;
            padding-bottom: 100%;
            position: absolute;
            top: 0;
            z-index: -1;
            transform: translateX(-50%);
            @include setBackSet(top,contain);
            background-image: url('../images/index-banner-radius.png');

            @include setStyleRwd(1500px){
                width: 54vw;
            }

            @include setMaxRwd{
                width: 100%;
            }
        }

        .bottle-bgLine{
            display: block;
            width: get-vw(1000px);
            height: 0;
            padding-bottom: 100%;
            position: absolute;
            top: 0;
            z-index: -2;
            transform: translateX(-50%);
            @include setBackSet(center,contain);
            background-image: url('../images/index-banner-radius02.png');

            @include setMinRwd{
                top: get-vw(-80px);
            }

            @include setMaxRwd{
                width: 100%;
                top: get-vw-mobile(-60px);
            }
        }

        .right-side{
            &__img{
                width: 100%;
                height: 100%;
            }

            &__icon{
                position: absolute;
                &.top{
                    top: 7rem;
                    left: 45%;
                    
                    @include setWidthRwd(1101px, 1400px){
                        top: 5rem;
                    }
                }
                &.bottom{
                    left: 32.5%;
                    top: get-vw(350px);

                    z-index: 1;
                    @include setStyleRwd(1400px){
                        left: get-vw(350px);
                        top: get-vw(400px);
                    }
                }
                    
                @include setMaxRwd{
                    display: none;
                }
            }

            &__color{
                max-width: 40vw;
                position: absolute;
                bottom: get-vw(200px);
                left: get-vw(130px);
                z-index: 1;
                @include setWidthRwd(1101px, 1600px){
                    bottom: get-vw(120px);
                }
                @include setMaxRwd{
                    max-width: 35vw;
                    top: auto;
                    bottom: 1rem;
                    left: get-vw(250px);
                }
            }
        }
    }

    @include setMinRwd{
        flex-direction: row;
        padding-left: get-vw(180px);
        padding-top: 5rem;
        z-index: 1002;
        margin-top: -3rem;
        .myIntrotxt, .mySwiper {
            width: 50%;
        }

        .mySwiper {
            margin-right: get-vw(140px);
            margin-top: -2rem;
        }

        .swiper-container{
            padding-bottom: 1.5rem;
            margin-bottom: 1rem;
            overflow: visible;
        }
    }

    @include setStyleRwd(1700px){
        padding-left: get-vw(70px);
        .swiper-container{
            padding-bottom: 1.5rem;
            margin-bottom: -1rem;
        }
    }

    @include setWidthRwd(1100px, 1500px) {
        .mySwiper {
            width: 62%;
        }
    }

    @include setWidthRwd(800px, 1050px) {
        .swiper-container{
            padding-bottom: 1.5rem;
            margin-bottom: 0rem;
        }
    }

    @include setMaxRwd{
        padding-left: 0;
        padding-top: 0;
        padding-bottom: 1rem;

        .mySwiper {
            margin-top: 8rem;
            margin-bottom: 3rem;
        }

        .myIntrotxt{
            padding-left: 1.5rem;
        }
    }

    .swiper-slide .bottle-img {
        transition-duration: 0.8s;
        transform: scale(0.5);
        opacity: 0;
        border-radius: 69% 65% 73% 61% / 70% 60% 80% 60%;
    }
    
    .swiper-slide-active .bottle-img {
        opacity: 1;
        transform: scale(1);
        margin-top: 2rem;
        margin-left: -1.25rem;
        @include setMaxRwd{
            margin-left: 0;
        }
    }
    
    .swiper-pagination {
        position: relative;
        top: 1rem;
        z-index: 2;
        &-bullet{
            width: 20px;
            height: 20px;
            background-color: #ffffff;
            border:#192563 3px solid;
            opacity: 1;
            margin: 0 0.5rem;
            &-active{
                background-color: #192563;
            }
        }
        @include setStyleRwd(1500px){
            top: 4rem;
        }
        @include setMaxRwd{
            top: 1rem;
        }
    }
}

@keyframes iconOpacity{
    to {
		opacity: 0;
	}
}

@keyframes swing{
    0% { transform: rotate(9deg); }
    100% { transform: rotate(-9deg); }
}

// iMage style : Wave
.imgStyle-wave{
    border:none;
    width: 100%;
    height: 20px;
    @include setBackSet(center, cover);
    background-image: url('../images/imgLine-wave.png');
}

// 
.serviceItem-list{
    position: relative;
    padding: 5rem 0;
    min-height: get-vw(800px);
    .iconList{
        position: absolute;

        &-01{
            @include setSize(get-vw(185px),get-vw(185px));
            top: get-vw(200px);
        }
        &-02{
            @include setSize(get-vw(160px),get-vw(160px));
            top: get-vw(325px);
            left: get-vw(180px);
        }
        &-03{
            @include setSize(get-vw(240px),get-vw(240px));
            top: get-vw(500px);
            left: get-vw(50px);
        }
        &-04{
            @include setSize(get-vw(250px),get-vw(250px));
            top: get-vw(45px);
            left: get-vw(220px);
        }
        &-05{
            @include setSize(get-vw(180px),get-vw(180px));
            top: get-vw(210px);
            left: get-vw(460px);
        }
        &-06{
            @include setSize(get-vw(215px),get-vw(215px));
            top: get-vw(400px);
            left: get-vw(350px);
        }
        &-07{
            @include setSize(get-vw(250px),get-vw(250px));
            top: get-vw(130px);
            left: get-vw(670px);
        }
        &-08{
            @include setSize(get-vw(180px),get-vw(180px));
            top: get-vw(380px);
            left: get-vw(590px);
        }
        &-09{
            @include setSize(get-vw(160px),get-vw(160px));
            top: get-vw(580px);
            left: get-vw(540px);
        }
        &-10{
            @include setSize(get-vw(175px),get-vw(175px));
            top: get-vw(40px);
            left: get-vw(900px);
        }
        &-11{
            @include setSize(get-vw(180px),get-vw(180px));
            top: get-vw(350px);
            left: get-vw(860px);
        }
        &-12{
            @include setSize(get-vw(220px),get-vw(220px));
            top: get-vw(510px);
            left: get-vw(980px);
        }
        &-13{
            @include setSize(get-vw(230px),get-vw(230px));
            top: get-vw(230px);
            left: get-vw(1040px);
        }
        &-14{
            @include setSize(get-vw(160px),get-vw(160px));
            top: get-vw(460px);
            left: get-vw(1210px);
        }
        &-15{
            @include setSize(get-vw(160px),get-vw(160px));
            top: get-vw(100px);
            left: get-vw(1250px);
        }
        &-16{
            @include setSize(get-vw(190px),get-vw(190px));
            top: get-vw(280px);
            left: get-vw(1300px);
        }
        
        @include setMaxRwd{
            position: static;
            display: inline-block;
            width: calc(33% - 4px);
            height: auto;
        }
        
        @include pad{
            width: calc(33% - 4px);
        }
        
        @include mobile{
            width: calc(50% - 4px);
        }
    }

    @include setStyleMinRwd(1800px){
        margin-left: get-vw(-40px);
    }

    @include setWidthRwd(1500px,1700px){
        margin-left: get-vw(60px);
    }

    @include setWidthRwd(1400px,1499px){
        margin-left: get-vw(140px);
    }

    @include setWidthRwd(1101px,1399px){
        margin-left: get-vw(180px);
    }

    @include setMaxRwd{
        padding-top: 1rem;
        padding-bottom: 0;
        margin-left: -15px;
        margin-right: -15px;
    }
}


// 服務項目的圓圈圈樣式
.circle{
    &Wrap{
        width: 100%;
        height: 0;
        padding-bottom: calc(100% - 6px);
        border-radius: 50%;
        border:#212020 3px solid;
        z-index: 1;
        cursor: pointer;
        transform: scale(1);
        transition:0.25s;

        .txt{
            font-size: get-vw(28px);
            line-height: 1.25;
            margin-bottom: 0;
        }

        .subtxt{
            display: block;
            margin-bottom: 5px;
        }
    
        .circle{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            text-align: center;
            @include setSize(100%,100%);
            @extend %setFlex-center;
            justify-content: center;
            flex-direction: column;
            flex-wrap: nowrap;
            overflow: hidden;
            &::after{
                content: '';
                display: block;
                width: get-vw(42px);
                height: get-vw(42px);
                @include setBackSet(center,contain);
                background-image: url('../images/icon-arrowCircle.png');
                position: absolute;
                transform: translateY(1000%);
                transition: 0.3s;
            }
        }

        @include setMinRwd{
            .img{
                margin-bottom: get-vw(10px);
                img{
                    width: get-vw(90px);
                    height: 100%;
                    object-fit: contain;
                    object-position: center;
                }
            }
            
            &:hover{
                border-width: get-vw(8px) !important;
                border-color:#fff;
                padding-bottom: calc(100% - 16px);
                transform: scale(1.15);
                box-shadow: 0 0 10px rgba(0,0,0,0.25);
                @include setWidthRwd(1101px,1500px){
                    padding-bottom: calc(100% - 12px);
                }
                .img{
                    margin-bottom: 10px;
                    @include setWidthRwd(1101px,1400px){
                        margin-bottom: 5px;
                    }
                }
                
                .txt{
                    margin-bottom: 5px;
                }

                .circle{
                    &::after{
                        position: relative;
                        transform: translateY(10px);
                        opacity: 1;
                        visibility: visible;
                        @include setWidthRwd(1101px,1400px){
                            width: get-vw(30px);
                            height: get-vw(30px);
                            transform: translateY(0px);
                        }
                    }
                }
                
                .txt,
                .subtxt{
                    color: #fff;
                }
            }
        }

        @include setMaxRwd{
            .txt {
                font-size: get-vw-mobile(30px);
            }
            &:hover{
                .txt,
                .subtxt {
                    color: #fff !important;
                }
            }
        }
    }

    &-01{
        &{
            border-color:#c82270;
        }
        .txt{
            color:#c82270;
        }
        &:hover{
            background-color:#c82270;
        }
    }

    &-02{
        &{
            border-color:#8174f0
        }
        .txt{
            color:#8174f0
        }
        &:hover{
            background-color:#8174f0;
        }
    }

    &-03{
        &{
            background-color: #fff;
        }
        .txt{
            color:#4c4b4b
        }
        .circle{
            .img{
                img[src="assets/images/index-serviceItem-icon05-active.png"]{
                    display: none;
                }
            }
        }
        &:hover{
            background-color:#4c4b4b;
            .circle{
                .img{
                    img[src="assets/images/index-serviceItem-icon05.png"]{
                        display: none;
                    }
                    img[src="assets/images/index-serviceItem-icon05-active.png"]{
                        display: block;
                    }
                }
            }
        }

        &.border-w6{
            padding-bottom: calc(100% - 12px);
        }
    }

    &-04{
        &{
            border-color:#bc3497
        }
        .txt{
            color:#bc3497
        }
        &:hover{
            background-color:#bc3497;
        }
    }

    &-05{
        &{
            border-color:transparent;
            &::before{
                content: '';
                display: block;
                @include setSize(104%,104%);
                border-radius: 50%;
                background-image: linear-gradient(to bottom right, #fb91cf 0%, #b3bcd9 100%);
                @extend %setPosCenter;
                z-index: -1;
            }
        }
        .circle {
            background-color: #fff;
            border-radius:50%;
        }
        .txt{
            color:#bc3497
        }
        &:hover{
            &::before{
                display: none;
            }
            .circle { 
                background-image: linear-gradient(to bottom right, #fb91cf 0%, #b3bcd9 100%);
            }
        }
    }

    &-06{
        &{
            border-color:#aa75d6
        }
        .txt{
            color:#aa75d6
        }
        &:hover{
            background-color:#aa75d6;
        }
    }

    &-07{
        &{
            border-color: transparent;
        }

        .circle {
            background-image: linear-gradient(to bottom right, #2aa5f9 0%, #65ddd2 100%);
            border-radius:50%;
        }
    }

    &-08{
        &{
            border-color: transparent;
        }
        .circle {
            background-image: linear-gradient(to bottom right, #ec8bc4 0%, #f5bdac 100%);
            border-radius:50%;
        }
    }

    &-09{
        &{
            border-color: transparent;
        }
        .circle {
            background-image: linear-gradient(to bottom right, #f86e67 0%, #fd578e 100%);
            border-radius:50%;
        }
    }

    &-10{
        &{
            border-color:#ae5da1;
            background-color: #ae5da1;
        }
    }

    &-11{
        &{
            border-color: transparent;
        }
        .circle {
            background-image: linear-gradient(to bottom right, #c14092 0%, #174ebf 100%);
            border-radius:50%;
        }
    }
}

.border-w6{
    border-width: 6px;
}
.about{
  *{
    line-height: 1.5;
    @include setMinRwd{
      line-height: 1.8;
    }
  }

  .bigTxt{
    @include fontSize(22px);
    color:$colorList;
  }

  &-text{
    @include fontSize(17px);
    color:#5f5f5f;
  }

  &-textFlex{
    @extend %setFlex-center;
    margin-top: 1rem;
    margin-bottom: 1rem;
    .img{
      margin-bottom: 1rem;
    }

    .txt{
      flex: 1;
      &Title{
        color:#202020;
      }
    }

    @include setMinRwd{
      margin-top: 4rem;
      margin-bottom: 4rem;
      .img{
        &+.txt{
          padding-left: 4rem;
        }
        &.order-lg-1{
          &+.txt{
            padding-left: 0rem;
            padding-right: 4rem;
          }
        }
      }
    }

    @include setMaxRwd{
      flex-direction: column;
      margin-top: 4rem;
      .img{
        flex: 1;
      }
    }

  }

  // 時間軸
  &-timeLine{
    @include setBackSet(top,cover);
    background-image: url('../images/about-timeLine-bg.png');
    padding-top: 3rem;
    &-Wrap{
      margin-top: 5rem;
      @include setMinRwd{
        display: flex;
        justify-content: flex-end;
        width: 100%;
        max-width: 70%;
        margin: -5em auto auto;
      }
    }
    @include setMaxRwd{
      padding: 1.25rem 1rem !important;
    }
  }

  // 設計團隊
  &-DesignList{
    @extend %setFlex-center;
    justify-content: space-around;
    margin-bottom: 2rem;
    &+.about-DesignList{
      margin-top: 2rem;
    }

    .txt{
      background-color: #fff;
      box-shadow: 0 0 15px rgba($color: #000000, $alpha: 0.25);
      border-radius: 100px 0 100px 0;

      margin-top: 1rem;
      margin-bottom: 1rem;
      padding: 3rem 3.75rem;
      z-index: 2;

      &Title{
        margin-top: 2rem;
        @include fontSize(24px);
        color:$colorList;
      }

      ul.info{
        list-style-type: disc;
        color:#5f5f5f;
        padding-left: 35px;
        margin: 1.25rem 0 2.75rem;
      }
    }

    @include setMinRwd{
      &+.about-DesignList{
        margin-top: 8rem;
      }

      .txt{
        position: absolute;
        margin-bottom: -8rem;
      }

      &.top{
        .img{
          &.left{
            flex: 0 0 560px;
          }
          &.right{
            flex: 0 0 400px;
          }
        }
      }
      
      &.bottom{
        .img{
          &.left{
            flex: 0 0 395px;
          }
          &.right{
            flex: 0 0 560px;
          }
        }
      }
    }

    @include mobile{
      .txt{
        order: -1;
        padding: 2rem 1.5rem;
        &Title{
          font-size: get-vw-mobile(30px);
        }
      }

      .img{
        margin-bottom: 1rem;
      }
    }

  }
}
// 文字顏色
.text-main {
    color: $colorMain !important
}

.text-underLink{
    color:#0092f8;
    text-decoration: underline
}

.style-underLine{
    text-decoration: underline
}

.text-red{
    color:#f00;
}

.text-blue{
    color:#00f;
}

// 麵包屑
.breadcrum-wrap {
    .breadcrumb {
        @include fontSize(16px);
        color: #404040;
        background-color: transparent;
        margin-bottom: .25rem;
        padding: 0.5rem 0;
        .icon{
            margin-top: -11px;
            margin-right: 4px;
        }
        .breadcrumb-item{
            padding-left: 0;
            &+.breadcrumb-item::before {
                content: "";
                display: inline-block;
                width: 20px;
                height: 19px;
                background: url('../images/icon-arrow03.png') no-repeat center;
                padding-top: 1.75rem;
            }
            &.active{
                color:#323232;
            }
            @include setMinRwd{
                &+.breadcrumb-item::before {
                    padding-right: 2rem;
                }
            }
        }
    }
}

.flex-none{
    flex: none;
}

.select_style {
    select {
        width: 100%;
        // background: transparent;
        // appearance: none;
    }
    select,
    input {
        background: transparent;
        border: none !important;
        height: 100% !important;
    }
    .disabled {
        background-color: #e9ecef;
        select,
        input {
            background: transparent;
        }
    }
}

// 頁碼
.pagination-wrap {
    margin-top: 2rem;
    margin-bottom: 2rem;
    .pagination {
        justify-content: center;
        .page-link {
            width: 40px;
            height: 40px;
            line-height: 40px;
            text-align: center;
            border-radius: 0;
            border: none;
            padding: 0;
            margin-right: 1rem;
            color: #6e6d6d;
            @include fontSize(20px);
            font-family: Arial;
            background-color: transparent;
            border:#424242 1px solid;
            &:hover,
            &.active {
                color: #fff;
                background-color:#424242;
            }
        }
    }
    @include pad1024{
        .pagination-mobile{
            display:flex !important;
            align-items:center;
            width:100%;
            .select_style {
                flex:1;
                margin:0 10px;
                .form-control {
                    border: 1px solid #ced4da !important;
                    background: #fff;
                    text-align:center;
                }
            }
        }
    }
}

// 特用標題
.block-title-box{
    line-height: 1.25;
    margin-bottom: 1.25rem;
    z-index: 10;
    p{
        margin-bottom: 0;
        line-height: 1.5;
    }

    .txt{
        margin-top: 0.75rem;
        color:$colorSubsCont !important;
        font-size: 22px !important;
        line-height: 1.8 !important;
    }

    .ch{
        color:$colorText;
        font-weight: 600;
        margin-bottom: 5px;
        @include fontSize(30px);
        .font{
            color:$colorSecondary
        }
        &-small{
            color:$colorMain;
            @include fontSize(18px);
        }
        &-small-gray{
            color:#939294;
            @include fontSize(22px);
            font-weight: 100;
        }
        &-medium{
            color:#374446;
            @include fontSize(24px);
            font-weight: 100;
        }
        &-large{
            font-size: get-vw-mobile(40px);
            margin-bottom: 10px;
            @include setMinRwd{
                @include fontSize(36px);
                margin-bottom: 2rem;
            }
        }
    }

    .en{
        color:$colorList;
        @include fontSize(45px);
        margin-top: 5px;
        margin-bottom: 5px;
        font-family: $Poppins;
        line-height: 1;
        b{
            color: #d5d5d5;
            font-family: $fontNotosanc-tc;
        }
        &-big{
            color:#d0d5dc;
            @include fontSize(60px);
            @include pad1024{
                @include fontSize(40px);
            }
        }
        &-small{
            font-weight: 600;
            @include fontSize(28px);
            color:$colorMain;
            b{
                color:#0160fb;
            }
        }
    }

    .large{
        @include fontSize(18px);
        color:$colorList
    }

    .small{
        @include fontSize(16px);
        color:$colorList
    }
    
    @include mobile{
        margin-bottom: 0.25rem;
    }
}

.block-titleImg-box {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
    .block-img{}
    .block-title {
        margin: 1rem 0rem 1rem 0.75rem;
        line-height: 1.3;
        p{ 
            margin-bottom: 5px;
        }
        .txt-small {
            color:#888787
        }
        .txt-big{
            @include fontSize(24px);
            color:#010101;
        }
    }
}

.symbol{
    padding-top: 2.5rem;
    &::before{
        content: '';
        display: block;
        width: 46px;
        height: 41px;
        @include setBackSet(center,contain);
        background-image: url('../images/icon-symbol.png');
        position: absolute;
        left: -60px;
        top: 30px;
        @include pad1080{
            left: 0;
            top: 0;
        }
    }
}

// 邊距
@include setMinRwd{
    .block-pl85{
        padding-left: 85px;
    }
    
    .block-pl45{
        padding-left: 45px;
    }
    
    .block-pl35{
        padding-left: 35px;
    }
}

article {
    width: 100%;
    font-size: 100%;
    iframe {
        width: 100%;
        height: 750px;
        @include pad {
            height: 350px;
        }
    }
}


// For 內頁區塊
.normal-content {
    padding:2em 1em;
    overflow: hidden;
    
    @include setWidthRwd(1101px,1600px){
        padding-left: 3rem;
        padding-right: 3.5rem;
        padding-top: 2rem;
    }

    @include pad1024{
        padding:1.25rem 1rem !important;
        article{
            padding-left: 0.5rem;
            padding-right: 0.5rem;
        }
    }
}

// 
.content{
    @extend %setFlex;
    &-box{
        background-color:#fff;
        padding: 1rem;
        margin-top: 1rem;
    }

    &-main{
        @include setMinRwd{
            padding: 1rem 2rem;
        }
        @include setMaxRwd{
            article{
                padding-left: 0;
                padding-right: 0;
            }
        }
    }

    &-info{
        @include setMinRwd{
            padding-top: 2rem;
            padding-right: 4.5rem;
        }
    }

    &-list{
        max-width: 100%;
        flex: 1;
        @include setMinRwd{
            margin-left: auto;
        }

        @include setWidthRwd(1005px,1200px){
            margin-left: 2rem;
        }
    }

    &-header{
        justify-content: space-between;
        margin-bottom: 1rem;
        @include setMinRwd{
            padding-top: 1.1rem;
            .block-title-box{
                padding-left: 2rem;
            }
            .breadcrum-wrap{
                padding-right: 2rem;
            }
        }
        @include setWidthRwd(1100px,1600px){
            padding-top: 0rem;
        }
    }

    &-header-main{
        justify-content: center;
        border-bottom: #434343 2px solid;
        .breadcrum-wrap{
            position: absolute;
            right: 2rem;
        }
    }

    &-header-pro{
        justify-content: space-between;
        .block-title-box{
            display: flex;
            .icon{
                margin-right: 17px;
                margin-top: 0.25rem;
            }
        }
        @include setMinRwd{
            padding-left: 75px;
            .breadcrum-wrap{
                position: absolute;
                right: 2rem;
            }
        }
    }

    @include pad1024{
        flex-direction: column;
    }
}

// 頁面背景色
.bg-intro{
    background-color: #eeeae9;
}

.bg-second{
    background-color:$bgSecondary !important;
}

// 內頁主連結
.headingList-wrap{
    @extend %setFlex;
    margin-bottom: 0.75rem;
    border-bottom: rgba($color: #000000, $alpha: 0.35) 2px solid;
    a{
        display: block;
        position: relative;
        @include fontSize(20px);
        color:#676666;
        line-height: 1;
        padding: 30px 50px;
        &:hover,
        &.active{
            &::before {
                content: "";
                background: $colorMain;
                @include setSize(6px,6px);
                border-radius: 100%;
                position: absolute;
                left: 30px;
                top: 50%;
                transform: translateY(-50%);
            }

            &::after{
                content: '';
                display: block;
                width: 100%;
                height: 3px;
                background-color: #515151;
                position: absolute;
                left: 50%;
                bottom: -2.95px;
                transform: translateX(-50%);
            }
        }
    }
}

// 底線樣式
.style__underLine{
    border-bottom-width: 4px;
    border-bottom-style: solid;
    border-bottom-color: #fff;
    &__small{
        border-top: none;
        border-bottom-width:2px;
        border-bottom-style: dotted;
    }
}

// 樣式 : 點點 or + 配標題
.style__dot{
    position: relative;
    &::before {
        content: "";
        background: $colorMain;
        @include setSize(6px,6px);
        border-radius: 100%;
        position: absolute;
        left: 30px;
        top: 50%;
        transform: translateY(-50%);
    }
    &__main{
        padding-left: 1.5rem;
        &::before{
            width: 15px;
            height: 15px;
            left: -5px;
            top: -6px;
            transform: translateY(0); 
        }
        .title {
            @include fontSize(22px);
            margin-bottom: 5px;
        }
    }
    &.pro{
        &::before {
            left: 0;
            background-color: #000;
        }
    }
}

// 項目
.tag-item {
    display: inline-block;
    width: auto;
    line-height: 1.1;
    margin: 2.5px;
    padding: 15px;
    text-align: center;
    color: white;
    background: $colorMain;
    line-height: 1.2;
    &.main {
        border: $colorMain 2px solid;
        color: $colorMain !important;
        font-size: 15px !important;
        border-radius: 20px;
        background-color: #fff !important;
        padding: 5px 14px !important;
        line-height: 1;
    }
}

.badge{
    background-color: #4fd1d9;
    border-radius: 50%;
    color: #fff;
    padding: 0;
    width: 20px;
    height: 20px;
    line-height: 22px;
    font-family: $Inter;
}

.form-control{
    border:none;
    height: 50px;
    &,
    &:focus{
        background-color: #eeeeee;
    }
}

select.form-control{
    border:#ddd 1px solid;
    background-color: #fff;
}

.form-control,
.btn{
    border-radius: 0;
}

.modal {
    text-align: center;
    padding-right: 0 !important;
    .modal-content{
        border-color: #accccd;
        border-width: 3px;
    }
    &-open{
        padding-right: 0 !important;
    }
}

@include setStyleMinRwd(500px){
    .modal-dialog {
        width: 100%;
        max-width: 85rem;
    }
}

@include setWidthRwd(1100px,1500px){
    .modal-dialog {
        max-width: 70rem;
    }
}
    
.modal-autoheight{
    .modal-body {
        position: relative;
        overflow-y: auto;
        overflow-x: hidden;
        min-height: 100px !important;
        max-height: 100vh !important;
        @include setStyleMinRwd(500px){
            max-height: calc(100vh - 50px) !important
        }
        @include pad1080{
            height: calc(100vh - 1.5rem) !important;
        }
    }
}

// Checkbox & radio
.checkbox_style {
    &>input {
        &[type="checkbox"] {
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: 1;
            opacity: 0;
            cursor: pointer;
        }
        &+label {
            position: relative;
            display: flex;
            align-items: center;
            text-align: left;
            margin-top: 2px;
            margin-bottom: 5px;
            margin-right: 2px;
            margin-left: 0;
            padding-left: 1.75rem;
            cursor: pointer;
            border: none;
            &::before {
                content: "";
                display: inline-block;
                width: 20px;
                height: 20px;
                font-size: 1.5rem;
                position: absolute;
                left: 0;
                top: 2px;
                border: #000 1px solid;
            }
            &::after {
                content: "\f00c";
                font-family: FontAwesome;
                position: absolute;
                left: 2px;
                font-size: 1rem;
                opacity: 0;
            }
        }
        &:checked+label::after {
            opacity: 1;
        }
    }
    p {
        margin-top: 10px;
    }
}

.radio_style {
    @extend %setFlex-center;
    margin-top: 10px !important;
    input {
        &[type="radio"] {
            display: none;
        }
        &+label {
            position: relative;
            display: flex;
            align-items: center;
            line-height: 1;
            margin-bottom: 0;
            margin-right: 0;
            &::before,
            &::after {
                content: "";
                display: inline-block;
                border-radius: 50%;
            }
            &::before {
                flex: none;
                width: 20px;
                height: 20px;
                border: #cecece 1px solid;
                background: #fff;
                vertical-align: middle;
                margin-right: 10px;
                margin-top: 3px;
            }
            &::after {
                width: 10px;
                height: 10px;
                background-color: transparent;
                position: absolute;
                left: 5px;
                top: calc(50% + 1.5px);
                transform: translate(0%, -50%);
            }
        }
        &:checked {
            &+label {
                &::after {
                    background-color: $colorText;
                }
            }
        }
    }
    @include setMinRwd {
        &~.radio_style {
            margin-left: 1rem;
        }
    }
}

// 按鈕
.btn-wrap {
    @extend %setFlex-center;
    flex-wrap: nowrap;
    justify-content: center;
    width: 100%;
    margin: 2rem 0 1rem;
    &>li {
        flex: 1;
        &+li {
            margin-left: 1.5rem;
        }
    }
    @include pad1080 {
        margin-top: 1rem;
        &>li {
            &+li {
                margin-left: 0.5rem;
            }
        }
    }
}

.btn{
    padding-top: 0.75em;
    padding-bottom: 0.75em;
    @include fontSize(17px);
    &.large-ptb0-35{
        padding-top: 0.35rem;
        padding-bottom: 0.35rem;
    }

    &.large-ptb0-5{
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
    }

    &.large-p1{
        padding-left: 1rem;
        padding-right: 1rem;
    }

    &.large-p2{
        padding-left: 2rem;
        padding-right: 2rem;
    }

    &.large-p3{
        padding-left: 3rem;
        padding-right: 3rem;
        @include fontSize(18px);
    }
    &.large-p4{
        padding-left: 4rem;
        padding-right: 4rem;
        @include fontSize(19px);
    }
    &.large-p5{
        padding-left: 5rem;
        padding-right: 5rem;
        @include fontSize(20px);
    }
    &.large-p6{
        padding-left: 6rem;
        padding-right: 6rem;
        @include fontSize(21px);
    }

    &.large-p1-radius{
        border-radius: 2rem;
        padding-left: 1rem;
        padding-right: 1rem;
        padding-top: 0.2rem;
        padding-bottom: 0.25rem;
        @include fontSize(16px);
    }

    &.large-p2-radius{
        border-radius: 2rem;
        padding-left: 2rem;
        padding-right: 2rem;
        @include fontSize(16px);
    }

    &.large-p3-radius{
        border-radius: 3rem;
        padding-left: 3rem;
        padding-right: 3rem;
        @include fontSize(18px);
    }
    
    &.large-p4-radius{
        border-radius: 4rem;
        padding-left: 4rem;
        padding-right: 4rem;
        @include fontSize(19px);
    }

    &-styleDotted{
        @include borderWidth(2);
        @include borderStyle(dotted);
        @include borderRadius(20);
        @include borderColor( #535353)
    }

    @include setStyleRwd(1500px){    
        @include fontSize(16px);
        padding-left: 1rem !important;
        padding-right: 1rem !important;
    }
}

@mixin borderStyle($style) {
    border-style:$style;
}

@mixin borderRadius($num) {
   border-radius: $num+px;
}
.fileUpload{
    &-info{
        padding-top: 10px;
        padding-bottom: 3rem;
        .orderNote-txt{
            padding-left: 40px;
            padding-top: 0.5rem;
        }
        @include pad1080{
            padding-top: 2rem;
        }
    }
    &-form{
        .contact-block{
            padding-top: 0;
            padding-left: 1rem;
            padding-right: 0;
            &.payReturn .contact-form input.form-control {
                flex-grow: 1;
                flex: auto;
                max-width: 100%;
            }
            @include setWidthRwd(1100px,1600px){
                &.payReturn .contact-form .form-group > label {
                    flex: none;
                }
            }
        }
    }
}

.file-upload {
    display: block;
    text-align: center;
    font-size: 17px;
  
    .file-select {
      display: block;
      border: 1px solid #9c9b9c;
      color: #5f5f5f;
      cursor: pointer;
      padding: 10px 5px;
      text-align: left;
      overflow: hidden;
      position: relative;
  
      &-button {
        display: inline-block;
        line-height: 1.5;
        color: #fff;
        background: #292828;
        border-radius: 20px;
        padding: 5px 25px;
      }
  
      &-name {
        line-height: 30px;
        display: inline-block;
        padding: 0 10px;
      }
  
      &:hover {
        border-color: #34495e;
        transition: all .2s ease-in-out;
        .file-select-button {
          background: #34495e;
          color: #FFFFFF;
          transition: all .2s ease-in-out;
        }
      }
    }
  
    &.active {
        .file-select {
            border-color: $colorMain;
            &,
            &-button{
              transition: all .2s ease-in-out;
            } 
             
            &-button {
              background: $colorMain;
              color: #FFFFFF;
            }
        }
    }
  
    .file-select {
      input[type=file] {
        z-index: 100;
        cursor: pointer;
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        opacity: 0;
        filter: alpha(opacity = 0);
      }
  
      &.file-select-disabled {
        opacity: 0.65;
  
        &:hover {
          cursor: default;
          display: block;
          border: 2px solid #dce4ec;
          color: #34495e;
          cursor: pointer;
          height: 40px;
          line-height: 30px;
          margin-top: 5px;
          text-align: left;
          background: #FFFFFF;
          overflow: hidden;
          position: relative;
  
          .file-select-button {
            background: #dce4ec;
            color: #666666;
            padding: 0 10px;
            display: inline-block;
            height: 40px;
            line-height: 30px;
          }
  
          .file-select-name {
            line-height: 30px;
            display: inline-block;
            padding: 0 10px;
          }
        }
      }
    }
}

.uploadForm {
  width: 100%;
}

.file-upload-wrapper {
  $defaultColor: #4daf7c;
  $height: 60px;
  
  position: relative;
  width: 100%;
  height: $height;
  @extend %setFlex-center;

  border: 1px solid #9c9b9c;
  color: #5f5f5f;
  cursor: pointer;
  padding-left: 5px;
  text-align: left;
  overflow: hidden;
  position: relative;
  
  &:after {
    content: attr(data-text);
    font-size: 18px;
    padding: 10px;
    display: block;
    pointer-events: none;
    z-index: 20;
    line-height: $height - 20px;
    color: #999;
    border-radius: 5px 10px 10px 5px;
    font-weight: 300;
  }
  
  &:before {
    content: '選擇檔案';
    display: inline-block;
    line-height: 1.5;
    color: #fff;
    background: #292828;
    border-radius: 20px;
    padding: 5px 25px;
    z-index: 25;
  }
  
  &:hover {
    &:before {
      background: darken($defaultColor, 10%);
    }
  }
   input {
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99;
    height: $height - 20px;
    margin: 0;
    padding: 0;
    display: block;
    cursor: pointer;
    width: 100%;
  }
}
footer {
  @include setBackSet(bottom,cover);
  background-image: url('../images/footer-bg.png');
  height: 0;
  padding-top: 4.5%;
  padding-bottom: 8.5%;
  margin-top: 10px;
  .wrapper {
      display: flex;
      align-items: flex-end;
  }

  @include setWidthRwd(1100px,1800px){
    background-position: left;
    padding-left: 6rem;
    padding-right: 1rem;
    padding-top: 8.5%;
    padding-bottom: 10.5%;
  }

  @include setWidthRwd(1100px,1300px){
    padding-bottom: 14.5%;
  }
  
  @include setWidthRwd(800px,1000px){
    .wrapper{
      flex-wrap:wrap;
    }

    .divider{
      display: none;
    }
  }

  @include pad1080{
    background-position: 18%;
    height: 100%;
    padding: 4% 3% 3%;
    margin-bottom: 45px;
  }

  @include pad{
    .wrapper{
      flex-direction: column;
      align-items: flex-start;
    }
  }
}

.divider {
  width: 2px;
  height: 100%;
  background: #bfb1a5;
}

.footer-logo {
  position: relative;
  display: flex;
  align-items: center;
  img {
      display: block;
      max-width: 100%;
  }
  @include setMinRwd{
    &::before{
      content: '';
      display: block;
      width: 100%;
      height: 100%; 
      min-width: 435px;
      min-height: 160px;
      @include setBackSet(top,cover);
      background-image: url('../images/footer-style.png');
      position: absolute;
      left: 50%;
      top: -50px;
      transform: translateX(-50%);
      mix-blend-mode: color-dodge;
      opacity: 0.7;
    }
  }
}

.footer {
  display: flex;

  &-info{
    .list{
      display: flex;
      align-items: center;
      .img{
        width: 50px;
        flex:none;
        margin-right: 10px;
        @include pad1080{
          margin-right: 5px;
        }
      }

      small{
        display: block;
        color:#383838;
        margin-bottom: 3px;
        margin-top: 7px;
      }

      .time{
        padding-right: 10px;  
      }

      .txt{
        letter-spacing: 1px;
        padding: 8px 0 0 10px;
        line-height: 1.5;
        a{
          text-decoration: underline;
          margin-left: 5px;
        }
        p{
          @include fontSize(16px);
          margin-bottom: 15px;
        }
        &-big{
          @include fontSize(24px);
          margin-bottom: 5px !important;
        }

        @include setMinRwd{
          p{
           &>span{
             flex:none;
             padding-right: 5px;
           } 
          }
        }
      }
    }

    @include pad1080{
      width: 100%;
      margin-top: 5px;
      margin-bottom: 5px;
      .list{
        align-items: flex-start !important;
        .txt{
          display: flex;
          flex-direction: column;
          p{
            line-height: 1.5;
            margin-left: 0 !important;
            margin-bottom: 10px;
          }
          &-big{
            @include fontSize(20px)
          }
        }
      }
    }
  }
  
  &-social{
    &-icon{
      @extend %setFlex-center;
      justify-content: space-around;
      background-color: rgba($color: #fff, $alpha: 0.7);
      border-radius: 20px;
      padding: 5px 12px;
      .link{
        &:not(:last-child){
          border-right: #656058 1px dotted;
          padding-right: 10px;
          margin-right: 10px;
        }
        @include setMaxRwd{
          &:not(:last-child){
            border-right: #656058 1px dotted;
          }
          margin-right: 0 !important;
          padding-left: 5px;
          padding-right: 5px !important;
          flex: 1;
          text-align: center;
        }
      }
    }

    @include setMinRwd{
      margin-top: 5px;
      margin-left: 20px;
      text-align: right;
    }

    @include pad1024{
      margin-top: 10px;
    }
  }

  &.left,
  &.center,
  &.right{
    flex-grow:1;
    max-width: 100%;
    display: inline-flex;
    align-items: flex-start;
  }

  &.left{
    @include setMinRwd{
      .footer-info{
        padding-left: 1.25rem;
      }
    }
    @include pad{
      flex-direction: column;
    }
  }

  &.right{
    @include setMinRwd{
      flex: none;;
      padding-left: 60px;
    }
  }

  &.center{
    .txt{
      line-height: 2.2;
      p{
        &~p{
          small{
            color:#10100f;
            @include fontSize(16px);
          }
        }
      }
      @include pad1080{
        line-height: 1.5;
        p{
          &~p{
            small{
              span{
                display: block;
                margin-bottom: 5px;
              }
            }
          }
        }
      }
    }
  }

  @include setWidthRwd(1025px,1100px){
    &.center{
      margin-left: 15px;
      margin-right: 5px;
    }
  }

  @include setMinRwd{
    &.left{
      .list{
        .txt p{
          br{
            display: none;
          }
        }
      }
    }
  }

  @include pad{
    &.left,
    &.center,
    &.right,
    &-social{
      width: 100%;
    }
  }
}

.iware {
  color: $colorBlack;
  font-size: 13px;
  font-size: 73%;
  padding: 5px 8px 6px;
  &,
  b{
    font-weight: 100;
  }
  a {
      &:hover {
          text-decoration: underline;
      }
  }
}


.float-link {
  right: -100%;
  position: fixed;
  bottom: 200px;
  z-index: 1003;
  transition: 0.3s;
  .link-btn {
    &+.link-btn{
      margin-top: 0.2vw;
    }

    a {
      // .circle{
      //   background: $colorMain;
      //   border-radius: 50%;
      //   width:50px;
      //   height:50px;
      //   position: relative;
      //   display: flex;
      //   justify-content: center;
      //   align-items: center;
      //   transition: 0.3s;
      //   margin-bottom: 5px;
      //   &::before{
      //     content: '';
      //     display: block;
      //     width: calc(100% - 10px);
      //     height: calc(100% - 10px);
      //     // border: rgba(255,255,255,0.5) 1px solid;
      //     border-radius: 100%;
      //     position: absolute;
      //     z-index: 1;
      //     left: 5px;
      //     top: 5px;
      //   }
      //   &::after {
      //       content: "\f077";
      //       font-family: 'FontAwesome';
      //       display: block;
      //       @include fontSize(25px);
      //       color: #fff;
      //   }
      // }

      // .txt{
      //   display: block;
      //   color: $colorMain;
      //   @include fontSize(17px);
      //   line-height: 1.2;
      //   text-align: center;
      // }
      &:hover {
          opacity: 0.65;
      }
    }
  }

  &.active {
      right: 5px;
  }

  @include setStyleRwd(1600px){
    &.active {
        right: 10px;
    }
  }

  @include pad1080{
    bottom: 0px;
    right: 0 !important;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 45px;
    overflow: hidden;
    .link-btn {
      flex: 1;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      
      a {
        opacity: 1 !important;
      }
      
      img{
        height: 50px;
        object-fit: cover;
        object-position: center;
      }

      &.tel{
        background-color: #0084ff;
      }
      &.mail{
        background-color: #d92c7c;
      }
      &.fb{
        background-color: #4666b1;
      }
      &.line{
        background-color: #06c755;
      }
      &.top{
        background-color: #212020;
        img{
          height: 28px;
        }
      }
    }
  }
}
@charset "UTF-8";
@import url("//fonts.googleapis.com/css2?family=Noto+Sans+TC&display=swap");
@import url("//fonts.googleapis.com/css2?family=Montserrat&display=swap");
@import url("//fonts.googleapis.com/css2?family=Inter:wght@200&display=swap");
@import url("//fonts.googleapis.com/css2?family=Poppins:wght@100&display=swap");
@import url("//fonts.googleapis.com/css2?family=Noto+Serif+TC&display=swap");
@import url("//fonts.googleapis.com/css2?family=Rubik&display=swap");
body, a {
  color: #000;
  font-size: 18px;
  font-family: "Noto Sans TC", "Microsoft JhengHei", "微軟正黑體", "Arial", sans-serif;
  -webkit-text-stroke: 0.1px;
  line-height: 1.8;
  font-weight: 400;
}

html, body, form, input, .sprite {
  margin: 0px;
  padding: 0px;
}

.circle-05::before {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.nav-fostrap, .footer-social-icon, .radio_style, .btn-wrap, .contact-block.payReturn .contact-form .formMainWrap, .about-textFlex, .about-DesignList, .partner-timeLine-list, .file-upload-wrapper, .circleWrap .circle, .prod_step, .content, .headingList-wrap, .dowload-item, .dowload-list li, .pro-wrapper, .imgSample {
  display: flex;
  flex-flow: row wrap;
}

.nav-fostrap, .footer-social-icon, .radio_style, .btn-wrap, .contact-block.payReturn .contact-form .formMainWrap, .about-textFlex, .about-DesignList, .partner-timeLine-list, .file-upload-wrapper, .circleWrap .circle, .prod_step {
  align-items: center;
}

.partnerCertify .swiper-slide-circle img, .index-workCard .card .styleRadiu__image img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  max-height: 100%;
}

.noJS > div,
.noJS > ul {
  display: none;
}

.noJS > noscript {
  display: block;
  width: 100%;
  line-height: 10em;
}

* {
  box-sizing: border-box;
}

html {
  overflow: -moz-scrollbars-vertical;
}

body {
  background: url("../images/bg.jpg") repeat top;
  visibility: visible;
}

body.fixed {
  overflow: hidden;
}

div {
  position: relative;
}

ol,
ul {
  list-style: none;
  margin: 0;
  padding-left: 0;
}

.sh_sans {
  font-family: "source-han-sans-traditional", "微軟正黑體", sans-serif;
}

.sh_serif {
  font-family: "source-han-serif-tc", serif;
}

form {
  font-size: 16px;
}

label {
  display: inline-flex;
  align-items: center;
  margin-right: 12px;
  cursor: pointer;
}

input {
  -webkit-autofill: unset;
  font-size: 16px;
  background-color: #eeeeee;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px #fcfcfc inset;
}

input:focus {
  outline: 0;
}

input[type='text'], input[type='password'] {
  vertical-align: top;
}

input[type="radio"] {
  margin-right: 5px;
  cursor: pointer;
  vertical-align: middle;
}

input[type=number]::-webkit-inner-spin-button,
input [type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

input.hid {
  display: none;
}

a:focus,
input:focus,
select:focus,
button:focus {
  box-shadow: none !important;
  outline: none;
}

select {
  border: 0px;
  outline: none;
}

textarea {
  padding: 5px;
  border: 1px solid rgba(216, 216, 216, 0.8);
  box-sizing: border-box;
  resize: none;
  font-size: 16px;
  background-color: #eeeeee;
}

a {
  cursor: pointer;
  color: inherit;
  font-size: inherit;
  text-decoration: none;
}

a:hover, a:focus {
  text-decoration: none;
  color: currentColor;
}

img {
  max-width: 100%;
}

.sprite {
  fill: #ed8f3c;
  vertical-align: top;
  width: 100%;
  height: 100%;
  transition: .3s ease-in-out;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

.font-button .far,
.font-button .fas {
  color: #666;
  transition: 0.08s cubic-bezier(0.37, 0.99, 0.92, 0.96);
}

.font-button:hover .far,
.font-button:hover .fas {
  color: #ed8f3c;
}

.zero-size {
  font-size: 0px;
  letter-spacing: 0px;
  word-spacing: 0px;
}

.required {
  color: #ed8f3c;
  margin: 0 0 0 8px;
}

.red {
  color: #ff0016;
}

.space {
  display: inline-block;
  width: 7px;
}

.clear {
  clear: both;
  height: 0px;
  line-height: 0px;
  font-size: 0px;
  overflow: hidden;
}

.ellipsis, .card-wrap.index-card-wrap .card__title p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.hide {
  display: none !important;
}

@media (max-width: 1024px) {
  .hide {
    display: block !important;
  }
}

@keyframes menushow {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes visitsLoad {
  0% {
    transform: translate3d(-100%, 0, 0);
  }
  30% {
    transform: translate3d(-70%, 0, 0);
  }
  80% {
    transform: translate3d(-20%, 0, 0);
  }
  95% {
    transform: translate3d(-5%, 0, 0);
  }
  100% {
    transform: translate3d(0%, 0, 0);
  }
}

@keyframes visitsShow {
  0% {
    opacity: 0;
    transform: translate3d(0, 45px, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes moveCircle {
  0%,
  100% {
    transform: translateY(-10%);
  }
  50% {
    transform: translateY(-5%);
  }
}

.table {
  border-top: 1px solid #c8dae8;
  border-right: 1px solid #c8dae8;
  border-left: 1px solid #c8dae8;
}

.input {
  width: 100%;
}

.textarea {
  width: 100%;
  height: 100px;
}

.searchBar {
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.icon {
  display: inline-block;
  vertical-align: middle;
}

.wrapper-1800 {
  width: 100%;
  max-width: 1800px;
  margin: auto;
}

.wrapper-1750 {
  width: 100%;
  max-width: 1750px;
  margin: auto;
}

.wrapper-1700 {
  width: 100%;
  max-width: 1700px;
  margin: auto;
}

.wrapper-1650 {
  width: 100%;
  max-width: 1650px;
  margin: auto;
}

.wrapper {
  width: 100%;
  max-width: 1600px;
  margin: auto;
}

.wrapper-1550 {
  width: 100%;
  max-width: 1550px;
  margin: auto;
}

.wrapper-1500 {
  width: 100%;
  max-width: 1500px;
  margin: auto;
}

.wrapper-1450 {
  width: 100%;
  max-width: 1450px;
  margin: auto;
}

.wrapper-1400 {
  width: 100%;
  max-width: 1400px;
  margin: auto;
}

.wrapper-1350 {
  width: 100%;
  max-width: 1350px;
  margin: auto;
}

.wrapper-1300 {
  width: 100%;
  max-width: 1300px;
  margin: auto;
}

.wrapper-1250 {
  width: 100%;
  max-width: 1250px;
  margin: auto;
}

.wrapper-1200 {
  width: 100%;
  max-width: 1200px;
  margin: auto;
}

.wrapper-1100 {
  width: 100%;
  max-width: 1100px;
  margin: auto;
}

.wrapper-1000 {
  width: 100%;
  max-width: 1000px;
  margin: auto;
}

.wrapper-900 {
  width: 100%;
  max-width: 900px;
  margin: auto;
}

.wrapper-800 {
  width: 100%;
  max-width: 800px;
  margin: auto;
}

.detail_label {
  display: inline-block;
  vertical-align: middle;
  margin-top: -3px;
  padding: 0 6px;
  height: 20px;
  text-align: center;
  background-color: #fff;
  line-height: 20px;
  vertical-align: middle;
  font: 13px/25px Arial;
  border: 1px solid #fff;
}

.detail_label.fc-LightSalmon {
  border-color: #e9a3a8;
  color: #e9a3a8;
  line-height: 20px;
}

.nav-fostrap {
  justify-content: space-between;
  background: #fff;
}

.nav-fostrap ul {
  width: 100%;
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: block;
  z-index: 2;
}

@media (min-width: 1025px) {
  .nav-fostrap ul {
    text-align: right;
  }
}

.nav-fostrap li {
  list-style-type: none;
  margin: 0;
  display: inline-block;
  position: relative;
}

.nav-fostrap li a {
  position: relative;
  z-index: 1;
  font-size: 20px;
  font-size: 1.25rem;
  line-height: 1;
  color: #3d3d3d;
  display: inline-block;
  outline: 0;
}

.nav-fostrap li::before {
  content: '';
  display: block;
  width: 45px;
  height: 10px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../images/linkActive.png");
  position: absolute;
  bottom: 0;
  left: 50%;
  margin-left: -27.5px;
  opacity: 0;
}

.nav-fostrap li ul.dropdown {
  width: 100%;
  min-width: 100px;
  position: absolute;
  top: calc(100% + 15px);
  left: 50%;
  transform: translateX(-55%);
  padding-top: 0;
  opacity: 0;
  visibility: hidden;
  z-index: 100;
  text-align: center;
  background-color: #fff;
  border: #898989 1px solid;
  padding: 3px;
}

.nav-fostrap li ul.dropdown::before, .nav-fostrap li ul.dropdown::after {
  content: '';
  width: 0;
  height: 0;
  border-bottom: #898989 10px solid;
  border-top: transparent 10px solid;
  border-left: transparent 10px solid;
  border-right: transparent 10px solid;
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}

.nav-fostrap li ul.dropdown::before {
  border-bottom-color: #fff;
  top: -19px;
  z-index: 2;
}

.nav-fostrap li ul.dropdown li::before {
  display: none;
}

.nav-fostrap li ul.dropdown li,
.nav-fostrap li ul.dropdown li a {
  display: block;
  width: 100%;
}

.nav-fostrap li ul.dropdown li a {
  padding: 0.65rem 1rem;
  font-weight: 400;
  color: #000;
  line-height: 1;
  font-size: 18px;
  font-size: 1.125rem;
}

.nav-fostrap li ul.dropdown li a::after {
  display: none;
}

.nav-fostrap li ul.dropdown li:hover {
  background-color: #E5E5E5;
}

.nav-fostrap li:hover::before, .nav-fostrap li.active::before {
  opacity: 1;
}

.nav-fostrap li:hover a, .nav-fostrap li.active a {
  color: #ed8f3c;
}

.nav-fostrap li:hover a::after, .nav-fostrap li.active a::after {
  opacity: 1;
}

.nav-fostrap li:hover ul.dropdown {
  opacity: 1;
  visibility: visible;
}

@media (min-width: 1025px) {
  .nav-fostrap li + li::after {
    content: '';
    display: block;
    width: 5px;
    height: 5px;
    background-color: #3d3d3d;
    position: absolute;
    top: 50%;
    left: -12px;
    transform: translate3d(0, -50%, 0);
    border-radius: 50%;
    transition: 0.25s;
    opacity: 1;
    z-index: -1;
  }
  .nav-fostrap li ul.dropdown li::after {
    display: none;
  }
}

.nav-fostrap-link {
  display: flex;
  align-items: center;
  flex: 1;
}

@media (min-width: 1025px) {
  .nav-fostrap-link > ul > li {
    padding: 15px 30px 15px 15px;
  }
}

@media (max-width: 1400px) {
  .nav-fostrap-link > ul > li {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (max-width: 1200px) {
  .nav-fostrap-link > ul > li {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
}

@media (max-width: 1024px) {
  .nav-fostrap-link {
    flex-direction: column;
    padding-left: 10px;
    padding-right: 10px;
  }
  .nav-fostrap-link ul {
    width: 100%;
  }
}

@media (min-width: 1025px) {
  .nav-fostrap {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}

@media (min-width: 1005px) and (max-width: 1600px) {
  .nav-fostrap {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

@media (min-width: 1005px) and (max-width: 1200px) {
  .nav-fostrap li a {
    font-size: 16px;
  }
}

@media (max-width: 1024px) {
  .nav-fostrap li::before {
    display: none;
  }
}

.nav-header {
  position: sticky;
  top: 0;
  z-index: 1003;
  background: #fff;
  border-bottom: 2px solid #d2d2d2;
}

@media (min-width: 1025px) {
  .nav-header-index {
    border-bottom: none;
  }
  .nav-header-index,
  .nav-header-index .nav-fostrap {
    background-color: transparent;
  }
}

.nav-fostrap-menuBg {
  position: absolute;
  left: 0;
  top: 5rem;
  width: 100%;
  height: 65px;
  background: rgba(212, 232, 206, 0.37);
  z-index: 1;
  display: none;
}

.nav-fostrap-menuBg.active {
  display: block;
}

.arrow-down {
  position: absolute;
  right: 1rem;
}

.title-mobile {
  display: none;
}

@media (min-width: 1025px) {
  .container {
    width: 96%;
  }
  .nav-bg-fostrap,
  .arrow-down {
    display: none;
  }
}

@media (max-width: 1200px) {
  .nav-fostrap ul li ul.dropdown li a {
    font-size: 16px;
    font-size: 1rem;
  }
}

@media (max-width: 1024px) {
  .nav-fostrap {
    background: #fff;
    width: 55vw;
    height: 100%;
    display: block;
    position: fixed;
    left: -100%;
    top: 0px;
    transition: left 0.25s ease;
    z-index: 12;
  }
  .nav-fostrap li a::after {
    display: none !important;
  }
  .title-mobile {
    display: block;
    max-width: 25vw;
  }
  .nav-logo {
    display: block;
    margin: auto;
    text-align: center;
    padding-top: 5px;
  }
  .mobile-closeBtn {
    width: 45px;
    height: 45px;
    background-color: #000;
    text-align: center;
    color: #fff;
    position: absolute;
    right: -45px;
    top: calc(-90px - 1rem);
    z-index: 6;
    font-size: 25px;
    font-size: 1.5625rem;
  }
  .mobile-closeBtn a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
  .nav-fostrap.visible {
    left: 0px;
    transition: left 0.25s ease;
  }
  .nav-bg-fostrap {
    display: inline-block;
    vertical-align: middle;
    width: 100%;
    min-height: 65px;
    margin: 0;
    padding-top: 6px;
    padding-bottom: 6px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    z-index: 3;
  }
  .navbar-fostrapClick {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    margin: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    padding: 12px;
  }
  .navbar-fostrapClick span {
    height: 2px;
    background: #ed8f3c;
    margin: 3px 5px;
    display: block;
    width: 20px;
  }
  .navbar-fostrapClick span:nth-child(2), .navbar-fostrapClick span:nth-child(3) {
    width: 20px;
  }
  .navbar-fostrapClick .txt {
    font-size: 14px;
    font-size: 0.875rem;
  }
  .nav-fostrap ul {
    padding-top: 10px;
  }
  .nav-fostrap li {
    display: block;
    border-bottom: #eee 1px solid;
  }
  .nav-fostrap li a {
    display: block;
    font-size: 18px;
    font-size: 1.125rem;
    padding: 12px;
  }
  .nav-fostrap li:first-child:hover a {
    border-radius: 0;
  }
  .nav-fostrap li ul.dropdown {
    position: static;
    display: none !important;
    border: none;
    margin-left: 0;
    margin-top: 2px;
    transform: translateX(0);
  }
  .nav-fostrap li ul.dropdown::before, .nav-fostrap li ul.dropdown::after {
    display: none;
  }
  .nav-fostrap li ul.dropdown.active {
    display: block !important;
    opacity: 1;
    visibility: visible;
  }
  .nav-fostrap li ul.dropdown li {
    padding: 0;
  }
  .nav-fostrap li ul.dropdown li a {
    border-bottom: none;
    text-align: left;
  }
  .nav-fostrap li:hover a, .nav-fostrap li.active a {
    background: #ed8f3c;
    color: #fff !important;
  }
  .nav-fostrap li a.active {
    background: #eb8225;
    color: #fff !important;
  }
  .nav-fostrap li a.active .arrow-down {
    transform: rotate(180deg);
  }
  .nav-fostrap li ul.dropdown {
    margin-bottom: 10px;
  }
  .nav-fostrap li ul.dropdown li a {
    padding: 10px;
    font-size: 16px;
    font-size: 1rem;
    background-color: #fff !important;
    color: #3d3d3d !important;
  }
  .nav-fostrap li ul.dropdown li ~ li {
    border-bottom: none;
  }
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
}

.nav-overlay {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  display: none;
  opacity: 0;
}

.nav-overlay.show {
  display: block;
  opacity: 1;
}

.dropdown-reply {
  flex: none;
}

.dropdown-reply .dropdown-toggle {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-size: 1rem;
  line-height: 27px;
  background-color: #000;
  color: #fff;
  box-shadow: 0 2px 10px 2px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  padding: 0em 0.75em 0.1em 0.5em;
}

.dropdown-reply .dropdown-toggle:hover {
  background-color: #ed8f3c;
}

.dropdown-reply .dropdown-toggle::after {
  display: none;
}

.dropdown-reply .dropdown-toggle .icon {
  flex: none;
  margin-right: 0.1rem;
  margin-top: 3px;
}

.dropdown-reply .dropdown-menu {
  min-width: 5.75rem;
  border-color: #ed8f3c;
  padding: 0;
  margin-top: 0.25rem;
  border-radius: 1rem;
  overflow: hidden;
}

.dropdown-reply .dropdown-menu .dropdown-item {
  text-align: center;
  padding-top: 0.35rem;
  padding-bottom: 0.35rem;
}

@media (min-width: 1025px) {
  .dropdown-reply .dropdown-menu .dropdown-item:hover {
    background-color: #ed8f3c;
    color: #fff;
  }
  .dropdown-reply .dropdown-menu .dropdown-item ~ .dropdown-item {
    border-top: 1px solid #ed8f3c;
  }
}

@media (min-width: 1025px) {
  .dropdown-reply {
    margin-left: 2rem;
  }
}

@media (max-width: 1024px) {
  .dropdown-reply {
    width: 100%;
    margin-top: 1rem;
  }
}

footer {
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
  background-image: url("../images/footer-bg.png");
  height: 0;
  padding-top: 4.5%;
  padding-bottom: 8.5%;
  margin-top: 10px;
}

footer .wrapper {
  display: flex;
  align-items: flex-end;
}

@media (min-width: 1100px) and (max-width: 1800px) {
  footer {
    background-position: left;
    padding-left: 6rem;
    padding-right: 1rem;
    padding-top: 8.5%;
    padding-bottom: 10.5%;
  }
}

@media (min-width: 1100px) and (max-width: 1300px) {
  footer {
    padding-bottom: 14.5%;
  }
}

@media (min-width: 800px) and (max-width: 1000px) {
  footer .wrapper {
    flex-wrap: wrap;
  }
  footer .divider {
    display: none;
  }
}

@media (max-width: 1080px) {
  footer {
    background-position: 18%;
    height: 100%;
    padding: 4% 3% 3%;
    margin-bottom: 45px;
  }
}

@media (max-width: 810px) {
  footer .wrapper {
    flex-direction: column;
    align-items: flex-start;
  }
}

.divider {
  width: 2px;
  height: 100%;
  background: #bfb1a5;
}

.footer-logo {
  position: relative;
  display: flex;
  align-items: center;
}

.footer-logo img {
  display: block;
  max-width: 100%;
}

@media (min-width: 1025px) {
  .footer-logo::before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    min-width: 435px;
    min-height: 160px;
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
    background-image: url("../images/footer-style.png");
    position: absolute;
    left: 50%;
    top: -50px;
    transform: translateX(-50%);
    mix-blend-mode: color-dodge;
    opacity: 0.7;
  }
}

.footer {
  display: flex;
}

.footer-info .list {
  display: flex;
  align-items: center;
}

.footer-info .list .img {
  width: 50px;
  flex: none;
  margin-right: 10px;
}

@media (max-width: 1080px) {
  .footer-info .list .img {
    margin-right: 5px;
  }
}

.footer-info .list small {
  display: block;
  color: #383838;
  margin-bottom: 3px;
  margin-top: 7px;
}

.footer-info .list .time {
  padding-right: 10px;
}

.footer-info .list .txt {
  letter-spacing: 1px;
  padding: 8px 0 0 10px;
  line-height: 1.5;
}

.footer-info .list .txt a {
  text-decoration: underline;
  margin-left: 5px;
}

.footer-info .list .txt p {
  font-size: 16px;
  font-size: 1rem;
  margin-bottom: 15px;
}

.footer-info .list .txt-big {
  font-size: 24px;
  font-size: 1.5rem;
  margin-bottom: 5px !important;
}

@media (min-width: 1025px) {
  .footer-info .list .txt p > span {
    flex: none;
    padding-right: 5px;
  }
}

@media (max-width: 1080px) {
  .footer-info {
    width: 100%;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .footer-info .list {
    align-items: flex-start !important;
  }
  .footer-info .list .txt {
    display: flex;
    flex-direction: column;
  }
  .footer-info .list .txt p {
    line-height: 1.5;
    margin-left: 0 !important;
    margin-bottom: 10px;
  }
  .footer-info .list .txt-big {
    font-size: 20px;
    font-size: 1.25rem;
  }
}

.footer-social-icon {
  justify-content: space-around;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 20px;
  padding: 5px 12px;
}

.footer-social-icon .link:not(:last-child) {
  border-right: #656058 1px dotted;
  padding-right: 10px;
  margin-right: 10px;
}

@media (max-width: 1024px) {
  .footer-social-icon .link {
    margin-right: 0 !important;
    padding-left: 5px;
    padding-right: 5px !important;
    flex: 1;
    text-align: center;
  }
  .footer-social-icon .link:not(:last-child) {
    border-right: #656058 1px dotted;
  }
}

@media (min-width: 1025px) {
  .footer-social {
    margin-top: 5px;
    margin-left: 20px;
    text-align: right;
  }
}

@media (max-width: 1024px) {
  .footer-social {
    margin-top: 10px;
  }
}

.footer.left, .footer.center, .footer.right {
  flex-grow: 1;
  max-width: 100%;
  display: inline-flex;
  align-items: flex-start;
}

@media (min-width: 1025px) {
  .footer.left .footer-info {
    padding-left: 1.25rem;
  }
}

@media (max-width: 810px) {
  .footer.left {
    flex-direction: column;
  }
}

@media (min-width: 1025px) {
  .footer.right {
    flex: none;
    padding-left: 60px;
  }
}

.footer.center .txt {
  line-height: 2.2;
}

.footer.center .txt p ~ p small {
  color: #10100f;
  font-size: 16px;
  font-size: 1rem;
}

@media (max-width: 1080px) {
  .footer.center .txt {
    line-height: 1.5;
  }
  .footer.center .txt p ~ p small span {
    display: block;
    margin-bottom: 5px;
  }
}

@media (min-width: 1025px) and (max-width: 1100px) {
  .footer.center {
    margin-left: 15px;
    margin-right: 5px;
  }
}

@media (min-width: 1025px) {
  .footer.left .list .txt p br {
    display: none;
  }
}

@media (max-width: 810px) {
  .footer.left, .footer.center, .footer.right, .footer-social {
    width: 100%;
  }
}

.iware {
  color: #000;
  font-size: 13px;
  font-size: 73%;
  padding: 5px 8px 6px;
}

.iware,
.iware b {
  font-weight: 100;
}

.iware a:hover {
  text-decoration: underline;
}

.float-link {
  right: -100%;
  position: fixed;
  bottom: 200px;
  z-index: 1003;
  transition: 0.3s;
}

.float-link .link-btn + .link-btn {
  margin-top: 0.2vw;
}

.float-link .link-btn a:hover {
  opacity: 0.65;
}

.float-link.active {
  right: 5px;
}

@media (max-width: 1600px) {
  .float-link.active {
    right: 10px;
  }
}

@media (max-width: 1080px) {
  .float-link {
    bottom: 0px;
    right: 0 !important;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 45px;
    overflow: hidden;
  }
  .float-link .link-btn {
    flex: 1;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  .float-link .link-btn a {
    opacity: 1 !important;
  }
  .float-link .link-btn img {
    height: 50px;
    object-fit: cover;
    object-position: center;
  }
  .float-link .link-btn.tel {
    background-color: #0084ff;
  }
  .float-link .link-btn.mail {
    background-color: #d92c7c;
  }
  .float-link .link-btn.fb {
    background-color: #4666b1;
  }
  .float-link .link-btn.line {
    background-color: #06c755;
  }
  .float-link .link-btn.top {
    background-color: #212020;
  }
  .float-link .link-btn.top img {
    height: 28px;
  }
}

.text-main {
  color: #ed8f3c !important;
}

.text-underLink {
  color: #0092f8;
  text-decoration: underline;
}

.style-underLine {
  text-decoration: underline;
}

.text-red {
  color: #f00;
}

.text-blue {
  color: #00f;
}

.breadcrum-wrap .breadcrumb {
  font-size: 16px;
  font-size: 1rem;
  color: #404040;
  background-color: transparent;
  margin-bottom: .25rem;
  padding: 0.5rem 0;
}

.breadcrum-wrap .breadcrumb .icon {
  margin-top: -11px;
  margin-right: 4px;
}

.breadcrum-wrap .breadcrumb .breadcrumb-item {
  padding-left: 0;
}

.breadcrum-wrap .breadcrumb .breadcrumb-item + .breadcrumb-item::before {
  content: "";
  display: inline-block;
  width: 20px;
  height: 19px;
  background: url("../images/icon-arrow03.png") no-repeat center;
  padding-top: 1.75rem;
}

.breadcrum-wrap .breadcrumb .breadcrumb-item.active {
  color: #323232;
}

@media (min-width: 1025px) {
  .breadcrum-wrap .breadcrumb .breadcrumb-item + .breadcrumb-item::before {
    padding-right: 2rem;
  }
}

.flex-none {
  flex: none;
}

.select_style select {
  width: 100%;
}

.select_style select,
.select_style input {
  background: transparent;
  border: none !important;
  height: 100% !important;
}

.select_style .disabled {
  background-color: #e9ecef;
}

.select_style .disabled select,
.select_style .disabled input {
  background: transparent;
}

.pagination-wrap {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.pagination-wrap .pagination {
  justify-content: center;
}

.pagination-wrap .pagination .page-link {
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 0;
  border: none;
  padding: 0;
  margin-right: 1rem;
  color: #6e6d6d;
  font-size: 20px;
  font-size: 1.25rem;
  font-family: Arial;
  background-color: transparent;
  border: #424242 1px solid;
}

.pagination-wrap .pagination .page-link:hover, .pagination-wrap .pagination .page-link.active {
  color: #fff;
  background-color: #424242;
}

@media (max-width: 1024px) {
  .pagination-wrap .pagination-mobile {
    display: flex !important;
    align-items: center;
    width: 100%;
  }
  .pagination-wrap .pagination-mobile .select_style {
    flex: 1;
    margin: 0 10px;
  }
  .pagination-wrap .pagination-mobile .select_style .form-control {
    border: 1px solid #ced4da !important;
    background: #fff;
    text-align: center;
  }
}

.block-title-box {
  line-height: 1.25;
  margin-bottom: 1.25rem;
  z-index: 10;
}

.block-title-box p {
  margin-bottom: 0;
  line-height: 1.5;
}

.block-title-box .txt {
  margin-top: 0.75rem;
  color: #374a6f !important;
  font-size: 22px !important;
  line-height: 1.8 !important;
}

.block-title-box .ch {
  color: #080404;
  font-weight: 600;
  margin-bottom: 5px;
  font-size: 30px;
  font-size: 1.875rem;
}

.block-title-box .ch .font {
  color: #0160f8;
}

.block-title-box .ch-small {
  color: #ed8f3c;
  font-size: 18px;
  font-size: 1.125rem;
}

.block-title-box .ch-small-gray {
  color: #939294;
  font-size: 22px;
  font-size: 1.375rem;
  font-weight: 100;
}

.block-title-box .ch-medium {
  color: #374446;
  font-size: 24px;
  font-size: 1.5rem;
  font-weight: 100;
}

.block-title-box .ch-large {
  font-size: 6.25vw;
  margin-bottom: 10px;
}

@media (min-width: 1025px) {
  .block-title-box .ch-large {
    font-size: 36px;
    font-size: 2.25rem;
    margin-bottom: 2rem;
  }
}

.block-title-box .en {
  color: #202020;
  font-size: 45px;
  font-size: 2.8125rem;
  margin-top: 5px;
  margin-bottom: 5px;
  font-family: "Poppins";
  line-height: 1;
}

.block-title-box .en b {
  color: #d5d5d5;
  font-family: "Noto Sans TC";
}

.block-title-box .en-big {
  color: #d0d5dc;
  font-size: 60px;
  font-size: 3.75rem;
}

@media (max-width: 1024px) {
  .block-title-box .en-big {
    font-size: 40px;
    font-size: 2.5rem;
  }
}

.block-title-box .en-small {
  font-weight: 600;
  font-size: 28px;
  font-size: 1.75rem;
  color: #ed8f3c;
}

.block-title-box .en-small b {
  color: #0160fb;
}

.block-title-box .large {
  font-size: 18px;
  font-size: 1.125rem;
  color: #202020;
}

.block-title-box .small {
  font-size: 16px;
  font-size: 1rem;
  color: #202020;
}

@media (max-width: 640px) {
  .block-title-box {
    margin-bottom: 0.25rem;
  }
}

.block-titleImg-box {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.block-titleImg-box .block-title {
  margin: 1rem 0rem 1rem 0.75rem;
  line-height: 1.3;
}

.block-titleImg-box .block-title p {
  margin-bottom: 5px;
}

.block-titleImg-box .block-title .txt-small {
  color: #888787;
}

.block-titleImg-box .block-title .txt-big {
  font-size: 24px;
  font-size: 1.5rem;
  color: #010101;
}

.symbol {
  padding-top: 2.5rem;
}

.symbol::before {
  content: '';
  display: block;
  width: 46px;
  height: 41px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../images/icon-symbol.png");
  position: absolute;
  left: -60px;
  top: 30px;
}

@media (max-width: 1080px) {
  .symbol::before {
    left: 0;
    top: 0;
  }
}

@media (min-width: 1025px) {
  .block-pl85 {
    padding-left: 85px;
  }
  .block-pl45 {
    padding-left: 45px;
  }
  .block-pl35 {
    padding-left: 35px;
  }
}

article {
  width: 100%;
  font-size: 100%;
}

article iframe {
  width: 100%;
  height: 750px;
}

@media (max-width: 810px) {
  article iframe {
    height: 350px;
  }
}

.normal-content {
  padding: 2em 1em;
  overflow: hidden;
}

@media (min-width: 1101px) and (max-width: 1600px) {
  .normal-content {
    padding-left: 3rem;
    padding-right: 3.5rem;
    padding-top: 2rem;
  }
}

@media (max-width: 1024px) {
  .normal-content {
    padding: 1.25rem 1rem !important;
  }
  .normal-content article {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}

.content-box {
  background-color: #fff;
  padding: 1rem;
  margin-top: 1rem;
}

@media (min-width: 1025px) {
  .content-main {
    padding: 1rem 2rem;
  }
}

@media (max-width: 1024px) {
  .content-main article {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (min-width: 1025px) {
  .content-info {
    padding-top: 2rem;
    padding-right: 4.5rem;
  }
}

.content-list {
  max-width: 100%;
  flex: 1;
}

@media (min-width: 1025px) {
  .content-list {
    margin-left: auto;
  }
}

@media (min-width: 1005px) and (max-width: 1200px) {
  .content-list {
    margin-left: 2rem;
  }
}

.content-header {
  justify-content: space-between;
  margin-bottom: 1rem;
}

@media (min-width: 1025px) {
  .content-header {
    padding-top: 1.1rem;
  }
  .content-header .block-title-box {
    padding-left: 2rem;
  }
  .content-header .breadcrum-wrap {
    padding-right: 2rem;
  }
}

@media (min-width: 1100px) and (max-width: 1600px) {
  .content-header {
    padding-top: 0rem;
  }
}

.content-header-main {
  justify-content: center;
  border-bottom: #434343 2px solid;
}

.content-header-main .breadcrum-wrap {
  position: absolute;
  right: 2rem;
}

.content-header-pro {
  justify-content: space-between;
}

.content-header-pro .block-title-box {
  display: flex;
}

.content-header-pro .block-title-box .icon {
  margin-right: 17px;
  margin-top: 0.25rem;
}

@media (min-width: 1025px) {
  .content-header-pro {
    padding-left: 75px;
  }
  .content-header-pro .breadcrum-wrap {
    position: absolute;
    right: 2rem;
  }
}

@media (max-width: 1024px) {
  .content {
    flex-direction: column;
  }
}

.bg-intro {
  background-color: #eeeae9;
}

.bg-second {
  background-color: #eeeeee !important;
}

.headingList-wrap {
  margin-bottom: 0.75rem;
  border-bottom: rgba(0, 0, 0, 0.35) 2px solid;
}

.headingList-wrap a {
  display: block;
  position: relative;
  font-size: 20px;
  font-size: 1.25rem;
  color: #676666;
  line-height: 1;
  padding: 30px 50px;
}

.headingList-wrap a:hover::before, .headingList-wrap a.active::before {
  content: "";
  background: #ed8f3c;
  width: 6px;
  height: 6px;
  border-radius: 100%;
  position: absolute;
  left: 30px;
  top: 50%;
  transform: translateY(-50%);
}

.headingList-wrap a:hover::after, .headingList-wrap a.active::after {
  content: '';
  display: block;
  width: 100%;
  height: 3px;
  background-color: #515151;
  position: absolute;
  left: 50%;
  bottom: -2.95px;
  transform: translateX(-50%);
}

.style__underLine {
  border-bottom-width: 4px;
  border-bottom-style: solid;
  border-bottom-color: #fff;
}

.style__underLine__small {
  border-top: none;
  border-bottom-width: 2px;
  border-bottom-style: dotted;
}

.style__dot {
  position: relative;
}

.style__dot::before {
  content: "";
  background: #ed8f3c;
  width: 6px;
  height: 6px;
  border-radius: 100%;
  position: absolute;
  left: 30px;
  top: 50%;
  transform: translateY(-50%);
}

.style__dot__main {
  padding-left: 1.5rem;
}

.style__dot__main::before {
  width: 15px;
  height: 15px;
  left: -5px;
  top: -6px;
  transform: translateY(0);
}

.style__dot__main .title {
  font-size: 22px;
  font-size: 1.375rem;
  margin-bottom: 5px;
}

.style__dot.pro::before {
  left: 0;
  background-color: #000;
}

.tag-item {
  display: inline-block;
  width: auto;
  line-height: 1.1;
  margin: 2.5px;
  padding: 15px;
  text-align: center;
  color: white;
  background: #ed8f3c;
  line-height: 1.2;
}

.tag-item.main {
  border: #ed8f3c 2px solid;
  color: #ed8f3c !important;
  font-size: 15px !important;
  border-radius: 20px;
  background-color: #fff !important;
  padding: 5px 14px !important;
  line-height: 1;
}

.badge {
  background-color: #4fd1d9;
  border-radius: 50%;
  color: #fff;
  padding: 0;
  width: 20px;
  height: 20px;
  line-height: 22px;
  font-family: "Inter";
}

.form-control {
  border: none;
  height: 50px;
}

.form-control, .form-control:focus {
  background-color: #eeeeee;
}

select.form-control {
  border: #ddd 1px solid;
  background-color: #fff;
}

.form-control,
.btn {
  border-radius: 0;
}

.modal {
  text-align: center;
  padding-right: 0 !important;
}

.modal .modal-content {
  border-color: #accccd;
  border-width: 3px;
}

.modal-open {
  padding-right: 0 !important;
}

@media (min-width: 500px) {
  .modal-dialog {
    width: 100%;
    max-width: 85rem;
  }
}

@media (min-width: 1100px) and (max-width: 1500px) {
  .modal-dialog {
    max-width: 70rem;
  }
}

.modal-autoheight .modal-body {
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  min-height: 100px !important;
  max-height: 100vh !important;
}

@media (min-width: 500px) {
  .modal-autoheight .modal-body {
    max-height: calc(100vh - 50px) !important;
  }
}

@media (max-width: 1080px) {
  .modal-autoheight .modal-body {
    height: calc(100vh - 1.5rem) !important;
  }
}

.checkbox_style > input[type="checkbox"] {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  opacity: 0;
  cursor: pointer;
}

.checkbox_style > input + label {
  position: relative;
  display: flex;
  align-items: center;
  text-align: left;
  margin-top: 2px;
  margin-bottom: 5px;
  margin-right: 2px;
  margin-left: 0;
  padding-left: 1.75rem;
  cursor: pointer;
  border: none;
}

.checkbox_style > input + label::before {
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  font-size: 1.5rem;
  position: absolute;
  left: 0;
  top: 2px;
  border: #000 1px solid;
}

.checkbox_style > input + label::after {
  content: "\f00c";
  font-family: FontAwesome;
  position: absolute;
  left: 2px;
  font-size: 1rem;
  opacity: 0;
}

.checkbox_style > input:checked + label::after {
  opacity: 1;
}

.checkbox_style p {
  margin-top: 10px;
}

.radio_style {
  margin-top: 10px !important;
}

.radio_style input[type="radio"] {
  display: none;
}

.radio_style input + label {
  position: relative;
  display: flex;
  align-items: center;
  line-height: 1;
  margin-bottom: 0;
  margin-right: 0;
}

.radio_style input + label::before, .radio_style input + label::after {
  content: "";
  display: inline-block;
  border-radius: 50%;
}

.radio_style input + label::before {
  flex: none;
  width: 20px;
  height: 20px;
  border: #cecece 1px solid;
  background: #fff;
  vertical-align: middle;
  margin-right: 10px;
  margin-top: 3px;
}

.radio_style input + label::after {
  width: 10px;
  height: 10px;
  background-color: transparent;
  position: absolute;
  left: 5px;
  top: calc(50% + 1.5px);
  transform: translate(0%, -50%);
}

.radio_style input:checked + label::after {
  background-color: #080404;
}

@media (min-width: 1025px) {
  .radio_style ~ .radio_style {
    margin-left: 1rem;
  }
}

.btn-wrap {
  flex-wrap: nowrap;
  justify-content: center;
  width: 100%;
  margin: 2rem 0 1rem;
}

.btn-wrap > li {
  flex: 1;
}

.btn-wrap > li + li {
  margin-left: 1.5rem;
}

@media (max-width: 1080px) {
  .btn-wrap {
    margin-top: 1rem;
  }
  .btn-wrap > li + li {
    margin-left: 0.5rem;
  }
}

.btn {
  padding-top: 0.75em;
  padding-bottom: 0.75em;
  font-size: 17px;
  font-size: 1.0625rem;
}

.btn.large-ptb0-35 {
  padding-top: 0.35rem;
  padding-bottom: 0.35rem;
}

.btn.large-ptb0-5 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.btn.large-p1 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.btn.large-p2 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.btn.large-p3 {
  padding-left: 3rem;
  padding-right: 3rem;
  font-size: 18px;
  font-size: 1.125rem;
}

.btn.large-p4 {
  padding-left: 4rem;
  padding-right: 4rem;
  font-size: 19px;
  font-size: 1.1875rem;
}

.btn.large-p5 {
  padding-left: 5rem;
  padding-right: 5rem;
  font-size: 20px;
  font-size: 1.25rem;
}

.btn.large-p6 {
  padding-left: 6rem;
  padding-right: 6rem;
  font-size: 21px;
  font-size: 1.3125rem;
}

.btn.large-p1-radius {
  border-radius: 2rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.2rem;
  padding-bottom: 0.25rem;
  font-size: 16px;
  font-size: 1rem;
}

.btn.large-p2-radius {
  border-radius: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;
  font-size: 16px;
  font-size: 1rem;
}

.btn.large-p3-radius {
  border-radius: 3rem;
  padding-left: 3rem;
  padding-right: 3rem;
  font-size: 18px;
  font-size: 1.125rem;
}

.btn.large-p4-radius {
  border-radius: 4rem;
  padding-left: 4rem;
  padding-right: 4rem;
  font-size: 19px;
  font-size: 1.1875rem;
}

.btn-styleDotted {
  border-width: 2px;
  border-style: dotted;
  border-radius: 20px;
  border-color: #535353;
}

@media (max-width: 1500px) {
  .btn {
    font-size: 16px;
    font-size: 1rem;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
}

.dowload-wrap {
  margin-bottom: 1rem;
}

.dowload-wrap p {
  margin-bottom: 0;
}

.dowload-wrap .btn {
  text-align: left;
  padding: 0.5rem 0.75rem;
  padding-right: 2rem;
  color: #8c8b8b;
  border-color: #8c8b8b;
  background-repeat: no-repeat;
  background-position: calc(100% - 10px);
  background-size: auto;
  background-image: url("../images/icon-download-g.png");
  font-size: 16px;
  font-size: 1rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.dowload-wrap .btn, .dowload-wrap .btn:hover {
  background-color: #fff;
}

.dowload-wrap .btn:hover {
  color: #000;
  border-color: #000;
  background-image: url("../images/icon-download-b.png");
}

.dowload-item, .dowload-list {
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 20px;
  text-align: center;
}

.dowload-item {
  font-size: 18px;
  font-size: 1.125rem;
  color: #5f5f5f;
  border-bottom: 2px solid #a7a7a7;
  justify-content: space-between;
  padding-top: 10px;
}

@media (max-width: 640px) {
  .dowload-item {
    display: none;
  }
}

.dowload-list {
  font-size: 20px;
  font-size: 1.25rem;
  color: #646464;
  line-height: 1.5;
}

.dowload-list li {
  justify-content: space-between;
  border-bottom: 2px solid #e1e1e1;
  padding-top: 1.5rem;
  padding-bottom: 0.75rem;
}

.dowload-list li .dowload-fileName {
  text-align: left !important;
  padding-left: 3rem;
}

@media (max-width: 640px) {
  .dowload-list {
    padding-left: 5px;
    padding-right: 5px;
  }
  .dowload-list li {
    display: block;
    text-align: left;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .dowload-list li .dowload-fileName {
    padding-left: 0;
  }
  .dowload-list li > div {
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;
  }
  .dowload-list li > div::before {
    content: attr(data-title);
    display: inline-block;
    font-size: 14px;
    font-size: 0.875rem;
    font-weight: 100;
    line-height: 1.1;
    margin: 2.5px;
    padding: 10px;
    text-align: center;
    color: white;
    background: #f1aa6b;
    flex: 0 0 100px;
    margin-right: 10px;
  }
  .dowload-list li > div .btn {
    padding-top: 0.32rem;
    padding-bottom: 0.32rem;
  }
}

.dowload-num {
  flex: 0 0 65px;
}

.dowload-act {
  flex: 0 0 300px;
}

.dowload-fileName {
  flex: 1;
}

.contact-form legend {
  line-height: 1.5;
  color: #656565;
  font-size: 18px;
  font-size: 1.125rem;
  margin-bottom: 1rem;
}

.contact-form label {
  margin-bottom: 0;
}

.contact-form .form-control, .contact-form .form-control:hover, .contact-form .form-control:focus {
  border: 1px solid #9c9b9c;
  background-color: #fff;
}

.contact-form .form-wrap {
  margin: 1.5rem auto 0;
}

@media (min-width: 1025px) {
  .contact-form .form-wrap {
    margin-top: 3rem;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
}

@media (min-width: 1100px) and (max-width: 1600px) {
  .contact-form .form-wrap {
    padding-left: 0.5rem;
  }
}

.contact-form .form-group {
  display: flex;
  align-items: flex-start;
}

.contact-form .form-group > label {
  flex: none;
  padding-top: 0.5rem;
  padding-left: 22px;
  padding-right: 2rem;
  margin-right: 0;
  font-size: 18px;
  font-size: 1.125rem;
  position: relative;
}

.contact-form .form-group > label:first-child::before {
  content: "";
  background: #56555a;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  position: absolute;
  left: 0;
  top: 20px;
}

@media (min-width: 1025px) {
  .contact-form .form-group {
    align-items: center;
    margin-bottom: 1.3rem;
  }
}

@media (min-width: 1101px) and (max-width: 1600px) {
  .contact-form .form-group {
    margin-bottom: 1.48rem;
  }
}

@media (max-width: 1024px) {
  .contact-form .form-group {
    flex-wrap: wrap;
    align-items: center;
  }
  .contact-form .form-group > label {
    width: 100%;
    margin-bottom: 0.25rem;
    padding-right: 0;
  }
  .contact-form .form-group > .form-control {
    flex: 1;
  }
  .contact-form .form-group > .formOtherWrap {
    padding-left: 0.75rem;
    max-width: 10rem;
  }
}

.contact-form .formOtherWrap {
  display: flex;
  width: 100%;
  padding-left: 2rem;
}

.contact-form .formOtherWrap > label {
  padding-right: 1rem;
  flex: none;
  margin-right: 0;
}

@media (min-width: 1025px) {
  .contact-form .formOtherWrap {
    justify-content: space-between;
  }
}

@media (min-width: 1100px) and (max-width: 1600px) {
  .contact-form .formOtherWrap {
    padding-left: 1rem;
  }
  .contact-form .formOtherWrap .radio_style {
    margin-top: 15px;
  }
}

@media (max-width: 1024px) {
  .contact-form .formOtherWrap {
    padding-top: 1rem;
    padding-left: 0rem;
  }
}

.contact-form .radio_style {
  margin-top: 0 !important;
}

.contact-form .radio_style input + label {
  font-size: 18px;
  font-size: 1.125rem;
}

.contact-form .btn-wrap li {
  display: flex;
  justify-content: center;
  width: 100%;
}

@media (max-width: 640px) {
  .contact-form .btn-wrap {
    flex-direction: column;
  }
  .contact-form .btn-wrap li {
    margin-bottom: 10px;
  }
}

@media (max-width: 1024px) {
  .contact-form .radio_style {
    margin-top: 0 !important;
    margin-right: 10px;
  }
}

.contact-info > .txt {
  width: 100%;
  color: #656565;
  font-size: 18px;
  font-size: 1.125rem;
}

@media (max-width: 1024px) {
  .contact-info > .txt {
    font-size: 18px;
    font-size: 1.125rem;
    margin-top: 1rem;
  }
}

.contact-info-wrap {
  position: relative;
  background-color: #313131;
  padding: 3rem 2rem 1.25rem;
  margin-top: 60px;
  font-size: 18px;
  font-size: 1.125rem;
}

.contact-info-wrap::before {
  content: '';
  display: block;
  width: 60px;
  height: 60px;
  background-color: #ed8f3c;
  position: absolute;
  left: -30px;
  top: -30px;
}

.contact-info-wrap li {
  display: flex;
  align-items: flex-start;
}

@media (min-width: 1025px) {
  .contact-info-wrap li {
    margin-bottom: 1rem;
  }
}

.contact-info-wrap .icon {
  width: 45px;
  text-align: center;
  flex: none;
  margin-right: 10px;
  margin-top: -3px;
}

.contact-info-wrap .txt {
  color: #fff;
}

.contact-info-wrap .text-muted {
  color: #c0bfbf !important;
}

@media (max-width: 1024px) {
  .contact-info-wrap {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.contact-block {
  position: relative;
  background: #fff;
  padding: 1.5rem;
}

@media (min-width: 1025px) {
  .contact-block {
    display: flex;
    padding: 3rem 4rem;
  }
  .contact-block .contact-form,
  .contact-block .contact-info {
    width: 50%;
  }
  .contact-block .contact-form {
    padding-right: 70px;
  }
  .contact-block .contact-info {
    padding-left: 70px;
  }
}

@media (min-width: 1100px) and (max-width: 1600px) {
  .contact-block {
    padding: 2rem;
  }
  .contact-block .contact-form {
    padding-right: 1rem;
  }
  .contact-block .contact-info {
    padding-left: 1rem;
  }
}

@media (max-width: 1024px) {
  .contact-block .contact-info {
    padding-top: 0.5rem;
  }
}

.contact-block .formPickWrap {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

@media (min-width: 1025px) {
  .contact-block .formPickWrap {
    padding-left: 1rem;
  }
}

.contact-block.payReturn {
  background-color: transparent;
}

.contact-block.payReturn .contact-form {
  width: 100%;
  padding-right: 0px;
}

.contact-block.payReturn .contact-form .form-group > label {
  padding-top: 0;
}

.contact-block.payReturn .contact-form .form-group > label:first-child::before {
  width: 6px;
  height: 6px;
  top: 15px;
}

.contact-block.payReturn .contact-form .form-group .form-control {
  background-color: transparent;
}

.contact-block.payReturn .contact-form .form-group .form-control + .form-control {
  margin-top: 0.25rem;
}

@media (min-width: 1025px) {
  .contact-block.payReturn .contact-form .form-group .form-control + .form-control {
    margin-left: 20px;
    margin-top: 0;
  }
}

@media (min-width: 1025px) {
  .contact-block.payReturn .contact-form .form-group > label {
    flex: 0 0 145px;
  }
}

@media (max-width: 1024px) {
  .contact-block.payReturn .contact-form .form-group {
    flex-direction: column;
  }
}

.contact-block.payReturn .contact-form .formMainWrap {
  width: 100%;
}

@media (min-width: 1025px) {
  .contact-block.payReturn .contact-form .formPickWrap {
    max-width: 250px;
  }
}

@media (max-width: 1024px) {
  .contact-block.payReturn .contact-form .formPickWrap {
    justify-content: flex-start;
  }
}

@media (min-width: 1025px) {
  .contact-block.payReturn .contact-form select.form-control,
  .contact-block.payReturn .contact-form input.form-control {
    flex: none;
    max-width: 350px;
  }
}

.contact-block.payReturn .contact-form .zip-county, .contact-block.payReturn .contact-form .zip-districe {
  max-width: 160px;
}

@media (min-width: 1400px) {
  .contact-block.payReturn .contact-form .zip-county, .contact-block.payReturn .contact-form .zip-districe {
    max-width: 182px;
  }
}

@media (max-width: 1024px) {
  .contact-block.payReturn .contact-form .zip-county, .contact-block.payReturn .contact-form .zip-districe {
    max-width: 100%;
  }
}

.contact-block.payReturn .contact-form .formOtherWrap {
  max-width: 100%;
  padding-left: 0;
}

@media (min-width: 1025px) {
  .contact-block.payReturn .contact-form .formOtherWrap {
    flex: 0 0 900px;
    padding-left: 1rem;
  }
}

@media (max-width: 1024px) {
  .contact-block.payReturn .contact-form .formOtherWrap {
    align-items: flex-start !important;
  }
  .contact-block.payReturn .contact-form .formOtherWrap,
  .contact-block.payReturn .contact-form .formOtherWrap > .twzipcode-style {
    flex-direction: column;
  }
  .contact-block.payReturn .contact-form .formOtherWrap > span {
    padding-bottom: 10px;
  }
  .contact-block.payReturn .contact-form .formOtherWrap .select_style {
    margin-bottom: 10px;
  }
}

.contact-block.payReturn .form-wrap {
  margin-top: 0.5rem;
  padding-left: 0rem;
  padding-right: 0rem;
}

.contact-block.payReturn .btn-wrap li {
  width: auto;
  flex: none;
}

@media (max-width: 1024px) {
  .contact-block.payReturn {
    padding-top: 0.25rem;
  }
  .contact-block.payReturn .icon {
    display: none;
  }
  .contact-block.payReturn .btn-wrap {
    flex-direction: row;
  }
}

.card-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 2rem;
}

.card-wrap .card {
  width: auto;
  height: auto;
  margin: 2px 25px 40px;
  box-shadow: none;
  border: #b4b4b4 1px solid;
  border-radius: 40px;
  overflow: hidden;
}

.card-wrap .card__list_4 {
  flex: 0 0 calc((100% / 4) - 50px);
  max-width: calc((100% / 4) - 50px);
}

.card-wrap .card__list_3 {
  flex: 0 0 calc((100% / 3) - 50px);
  max-width: calc((100% / 3) - 50px);
}

.card-wrap .card p {
  position: relative;
  z-index: 3;
  margin: 0;
}

.card-wrap .card__body {
  padding: 0.75rem 2rem;
  line-height: 1.5;
}

.card-wrap .card__image-top {
  margin-bottom: 0.5rem;
}

.card-wrap .card__image {
  text-align: center;
  margin-bottom: 0.5rem;
}

.card-wrap .card__image > img {
  object-fit: cover;
  object-position: center;
}

.card-wrap .card__image.fullStyle::before {
  content: '';
  display: block;
  width: 100%;
  height: 10rem;
  position: absolute;
  top: 0;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,ffffff+100&1+0,0+100 */
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.8) 0%, rgba(255, 255, 255, 0) 80%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.8) 0%, rgba(255, 255, 255, 0) 80%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.8) 0%, rgba(255, 255, 255, 0) 80%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#00ffffff',GradientType=0 );
  /* IE6-9 */
}

.card-wrap .card__image.fullStyle > img {
  height: 450px;
}

.card-wrap .card__image .btnMagnifier {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  text-align: right;
  padding: 1.5rem 1.5rem;
}

.card-wrap .card__title {
  font-size: 20px;
  font-size: 1.25rem;
  color: #010101;
  margin-bottom: 0.25rem;
}

.card-wrap .card__caption {
  font-size: 18px;
  font-size: 1.125rem;
  color: #5f5f5f;
  margin-top: 0.5rem;
  margin-bottom: 0.75rem;
}

.card-wrap.index-card-wrap .card__title {
  padding: 4px 8px 2px;
  font-size: 22px;
  font-size: 1.375rem;
  font-weight: 700;
}

.card-wrap.index-card-wrap .card__description {
  padding: 8px 0;
  font-size: 17px;
  font-size: 1.0625rem;
  font-weight: 400;
  color: #332c2e;
}

@media (max-width: 810px) {
  .card-wrap {
    justify-content: flex-start;
    margin: 1rem 0;
  }
  .card-wrap .card {
    margin-left: 5px;
    margin-right: 5px;
    max-width: calc((100% / 2) - 10px);
    flex: 0 0 calc((100% / 2) - 10px);
  }
  .card-wrap .card__image > img {
    height: 167px;
  }
  .card-wrap .card__image.fullStyle > img {
    height: 180px;
  }
  .card-wrap .card__body {
    padding: 0.25rem 0.5rem 0.25rem 1.25rem;
  }
}

@media (max-width: 640px) {
  .card-wrap .card__title {
    font-size: 3.90625vw;
  }
  .card-wrap .card__caption {
    font-size: 3.125vw;
  }
}

.news__info {
  margin-top: 0.75rem;
}

.news__info .title {
  display: flex;
  align-items: center;
}

.news__info .title-head {
  position: relative;
  margin-bottom: 5px;
  line-height: 1.25;
}

.news__info .btn {
  border-radius: 25px;
  padding: 0.5rem 1rem;
  margin-right: 1rem;
  margin-bottom: 5px;
  border-style: dotted;
  font-size: 20px;
  font-size: 1.25rem;
  cursor: default !important;
}

.news__info .txt {
  font-size: 18px;
  font-size: 1.125rem;
  color: #706e6e;
  padding-top: 5px;
  padding: 0.5rem;
}

@media (min-width: 1025px) {
  .news__info {
    padding-right: 2rem;
  }
  .news__info .title-head::after {
    content: '';
    display: block;
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-image: url("../images/icon-clock.png");
    position: absolute;
    right: -40px;
    top: 0;
    opacity: 0;
  }
}

@media (max-width: 640px) {
  .news__info .btn {
    flex: none;
  }
}

.news__list {
  display: flex;
  width: 100%;
  border-bottom: 2px solid #cecece;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.news__list .card {
  border: #aaaaaa 1px solid;
}

.news__list .card__img, .news__list .card__body {
  display: flex;
}

.news__list .card__img {
  width: 100%;
  align-items: center;
  justify-content: center;
}

.news__list .card__body {
  max-width: 100%;
  flex-direction: row;
  justify-content: space-around;
}

.news__list .timeList {
  padding: 0.5rem 1rem;
}

.news__list:hover {
  background-color: #fff;
}

.news__list:hover .btn {
  background-color: #000;
  color: #fff;
}

.news__list:hover .title-head::after {
  opacity: 1;
}

@media (min-width: 1025px) {
  .news__list .card__img {
    flex: 0 0 280px;
  }
  .news__list .timeList {
    padding-top: 1rem;
    padding-left: 4rem;
    padding-right: 4rem;
  }
}

@media (max-width: 640px) {
  .news__list .card__img img {
    width: 100%;
  }
  .news__list,
  .news__list .card__body {
    flex-direction: column;
    align-items: flex-start;
  }
  .news__list .timeList {
    display: flex;
    align-items: flex-end;
  }
  .news__list .timeList p {
    margin-bottom: 0;
  }
  .news__list .timeList .date-ym {
    padding-left: 1rem;
  }
}

.news__detail_title .card__body {
  justify-content: flex-start;
}

.news__detail_title .btn {
  background-color: #000;
  color: #fff !important;
}

.news__detail_title .title-head::after {
  opacity: 1;
}

.news__detail_txt {
  padding: 1rem 0.5rem;
  border-bottom: 1px solid #323232;
}

@media (min-width: 1025px) {
  .news__detail_txt {
    padding: 3rem;
  }
}

.content-news-d {
  padding: 0 1rem 1rem;
}

@media (min-width: 1025px) {
  .content-news-d {
    padding: 2rem;
  }
}

.timeList {
  font-family: "Noto Serif TC";
  text-align: center;
  line-height: 1;
  font-weight: 600;
}

.timeList .date {
  color: #ed8f3c;
  font-size: 48px;
  font-size: 3rem;
  font-style: italic;
}

.timeList .date-ym {
  color: #838282;
  font-size: 20px;
  font-size: 1.25rem;
}

.about * {
  line-height: 1.5;
}

@media (min-width: 1025px) {
  .about * {
    line-height: 1.8;
  }
}

.about .bigTxt {
  font-size: 22px;
  font-size: 1.375rem;
  color: #202020;
}

.about-text {
  font-size: 17px;
  font-size: 1.0625rem;
  color: #5f5f5f;
}

.about-textFlex {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.about-textFlex .img {
  margin-bottom: 1rem;
}

.about-textFlex .txt {
  flex: 1;
}

.about-textFlex .txtTitle {
  color: #202020;
}

@media (min-width: 1025px) {
  .about-textFlex {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
  .about-textFlex .img + .txt {
    padding-left: 4rem;
  }
  .about-textFlex .img.order-lg-1 + .txt {
    padding-left: 0rem;
    padding-right: 4rem;
  }
}

@media (max-width: 1024px) {
  .about-textFlex {
    flex-direction: column;
    margin-top: 4rem;
  }
  .about-textFlex .img {
    flex: 1;
  }
}

.about-timeLine {
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  background-image: url("../images/about-timeLine-bg.png");
  padding-top: 3rem;
}

.about-timeLine-Wrap {
  margin-top: 5rem;
}

@media (min-width: 1025px) {
  .about-timeLine-Wrap {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    max-width: 70%;
    margin: -5em auto auto;
  }
}

@media (max-width: 1024px) {
  .about-timeLine {
    padding: 1.25rem 1rem !important;
  }
}

.about-DesignList {
  justify-content: space-around;
  margin-bottom: 2rem;
}

.about-DesignList + .about-DesignList {
  margin-top: 2rem;
}

.about-DesignList .txt {
  background-color: #fff;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
  border-radius: 100px 0 100px 0;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 3rem 3.75rem;
  z-index: 2;
}

.about-DesignList .txtTitle {
  margin-top: 2rem;
  font-size: 24px;
  font-size: 1.5rem;
  color: #202020;
}

.about-DesignList .txt ul.info {
  list-style-type: disc;
  color: #5f5f5f;
  padding-left: 35px;
  margin: 1.25rem 0 2.75rem;
}

@media (min-width: 1025px) {
  .about-DesignList + .about-DesignList {
    margin-top: 8rem;
  }
  .about-DesignList .txt {
    position: absolute;
    margin-bottom: -8rem;
  }
  .about-DesignList.top .img.left {
    flex: 0 0 560px;
  }
  .about-DesignList.top .img.right {
    flex: 0 0 400px;
  }
  .about-DesignList.bottom .img.left {
    flex: 0 0 395px;
  }
  .about-DesignList.bottom .img.right {
    flex: 0 0 560px;
  }
}

@media (max-width: 640px) {
  .about-DesignList .txt {
    order: -1;
    padding: 2rem 1.5rem;
  }
  .about-DesignList .txtTitle {
    font-size: 4.6875vw;
  }
  .about-DesignList .img {
    margin-bottom: 1rem;
  }
}

/* Timeline */
.timeline {
  border-bottom-right-radius: 2px;
  border-top-right-radius: 2px;
  color: #333;
  position: relative;
  line-height: 1.4em;
  padding: 0px 20px 20px;
  list-style: none;
  text-align: left;
}

.timeline:after {
  content: "";
  left: 5px;
  width: 2px;
  height: calc(100% + 70px);
  background: #ed8f3c;
  position: absolute;
  left: -11px;
  top: 41%;
  transform: translateY(-50%);
}

@media (max-width: 640px) {
  .timeline:after {
    left: 17px;
    height: calc(100% - 70px);
    top: 35%;
  }
}

.timeline h1,
.timeline h2,
.timeline h3 {
  font-size: 1.4em;
}

.timeline .event {
  display: flex;
  position: relative;
  padding-left: 10px;
  padding-bottom: 35px;
}

.timeline .event:before {
  position: absolute;
  display: block;
  top: 0;
  left: -2.5rem;
  color: #ed8f3c;
  content: attr(data-date);
  text-align: right;
  font-size: 24px;
  font-size: 1.5rem;
  min-width: 120px;
  font-family: "Montserrat";
}

.timeline .event .txt {
  position: relative;
  margin-bottom: 0;
  padding-left: 8rem;
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.5;
}

.timeline .event .txt::before {
  content: '';
  display: block;
  height: 18px;
  width: 18px;
  background: #ed8f3c;
  position: absolute;
  top: 0px;
  left: -50px;
}

@media (max-width: 640px) {
  .timeline .event .txt {
    padding-left: 5rem;
  }
  .timeline .event .txt::before {
    left: -20px;
  }
}

.timeline .event:last-of-type {
  padding-bottom: 0;
}

.timeline .event:last-of-type:after {
  height: 0;
  top: 0px;
}

@media (min-width: 1025px) {
  .timeline .event {
    padding-bottom: 70px;
  }
}

.orderNote-list {
  display: flex;
  margin: 10px 0;
}

.orderNote-list .icon {
  margin-top: -6px;
}

.orderNote-title p, .orderNote-txt p {
  margin-bottom: 0.1rem;
}

.orderNote-title {
  flex: 0 0 350px;
  padding-left: 1.5rem;
  padding-right: 4rem;
  margin-left: 60px;
  border-right: #cdcdcd 1px solid;
}

.orderNote-title .title {
  font-size: 22px;
  font-size: 1.375rem;
  margin-bottom: 10px;
  line-height: 1.3;
}

.orderNote-title.style__dot::before {
  width: 15px;
  height: 15px;
  left: -5px;
  top: -6px;
  transform: translateY(0);
}

.orderNote-title figure {
  margin-top: 30px;
}

.orderNote-info {
  padding-left: 20px;
}

.orderNote-item {
  font-size: 19px;
  font-size: 1.1875rem;
  margin-bottom: 0.5rem;
}

.orderNote-txt {
  padding-left: 1rem;
  font-size: 17px;
  font-size: 1.0625rem;
  color: #51504f;
  margin-bottom: 1.25rem;
}

.orderNote-txt:last-child {
  margin-bottom: 0.25rem;
}

.orderNote-txt a {
  font-size: 18px;
  font-size: 1.125rem;
}

.orderNote-txt .style__dot {
  padding-left: 1rem;
}

.orderNote-txt .style__dot::before {
  width: 7px;
  height: 7px;
  background-color: #615f5f;
  left: 0;
  top: 14px;
  transform: translateY(0);
}

.orderNote-txt > li {
  padding-bottom: 10px;
  border-bottom: 2px dotted #c8c8c8;
}

.orderNote-txt > li + li {
  margin-top: 0.5rem;
}

@media (max-width: 1024px) {
  .orderNote-list {
    flex-direction: column;
  }
  .orderNote-title {
    flex: 1;
    padding-left: 1.5rem;
    padding-right: 1rem;
    margin-left: 0px;
    border-right: none;
  }
  .orderNote-title figure {
    margin-top: 5px;
  }
  .orderNote-info {
    padding-left: 0;
  }
}

.styleRadius-150 {
  box-shadow: none !important;
  border-radius: 0 150px 0 150px !important;
}

.imgRadius-topRight-100, .imgRadius-bottomLeft-100 {
  overflow: hidden;
}

.imgRadius-topRight-100 {
  border-radius: 100px 0px 100px 0px;
}

.imgRadius-bottomLeft-100 {
  border-radius: 0px 100px 0px 100px;
}

.bgText::before {
  content: '';
  text-transform: uppercase;
  display: block;
  color: #e3e2e2;
  font-size: 7.89474vw;
  font-weight: 900;
  -webkit-text-stroke: 10px #e3e2e2;
  text-align: center;
  line-height: 1;
  position: absolute;
  left: 50%;
  top: auto;
  transform: translateX(-50%);
}

@media (min-width: 1090px) and (max-width: 1600px) {
  .bgText::before {
    font-size: 10.52632vw;
  }
}

@media (max-width: 1080px) {
  .bgText::before {
    top: 2%;
    font-size: 19.53125vw;
  }
}

.partnerTitle {
  width: 100%;
  color: #000;
  font-weight: 600;
  font-family: "Rubik", sans-serif;
  text-align: center;
  margin-bottom: 0.7rem;
}

.partner-DesignList {
  align-items: flex-end;
  justify-content: flex-start;
  flex-flow: row nowrap;
}

.partner-DesignList .txt {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 1 auto;
  max-width: 600px;
  min-height: 550px;
  padding: 2rem 6rem;
  margin-bottom: 2rem;
  overflow: hidden;
}

.partner-DesignList .txt p {
  margin-bottom: 0.25rem;
}

.partner-DesignList .txt::before {
  content: '';
  display: block;
  width: 100%;
  height: 700px;
  background-repeat: no-repeat;
  background-position: right;
  background-size: contain;
  background-image: url("../images/bg-circlePink.png");
  position: absolute;
  right: -40%;
  bottom: -25%;
}

.partner-DesignList .txt figure {
  margin-bottom: 2rem;
}

.partner-DesignList .txt .info {
  font-size: 1.10526vw;
  color: #4f4e4e;
}

@media (max-width: 1080px) {
  .partner-DesignList .txt .info {
    font-size: 3.90625vw;
  }
}

.partner-DesignList .img {
  overflow: hidden;
}

.partner-DesignList .img img {
  width: 100%;
}

.partner-DesignList .img-big {
  left: -11%;
  margin-right: -12%;
}

.partner-DesignList .img-small {
  position: relative;
  left: -6%;
  margin-bottom: 2.25%;
  z-index: 2;
}

.partner-DesignList .img-small:nth-last-of-type(1) {
  left: -2.5%;
  margin-bottom: 9%;
}

@media (max-width: 1080px) {
  .partner-DesignList {
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
  }
  .partner-DesignList .txt {
    width: 100%;
    max-width: 100%;
    min-height: auto;
    padding: 3rem 2rem 4rem;
  }
  .partner-DesignList .img {
    right: 0;
    left: 0 !important;
    margin-right: 0;
  }
  .partner-DesignList .img-small {
    width: 50%;
  }
  .partner-DesignList .img-big {
    order: 1;
  }
}

.partner-timeLine-Wrap {
  margin-top: 6rem;
  padding-top: 3.68421vw;
  overflow: hidden;
}

.partner-timeLine-Wrap .partnerTitle {
  font-size: 30px;
  font-size: 1.875rem;
}

.partner-timeLine-Wrap::before {
  content: 'History';
  top: -1.31579vw;
}

.partner-timeLine-Wrap .timeline::after {
  left: 0;
  background-color: #898989;
  top: 10px;
  transform: translateY(0%);
  width: 1px;
  height: 100%;
}

.partner-timeLine-Wrap .timeline .event {
  padding-bottom: 30px;
}

.partner-timeLine-Wrap .timeline .event .txt {
  padding-left: 40px;
}

.partner-timeLine-Wrap .timeline .event .txt p {
  margin-bottom: 0.5rem;
}

.partner-timeLine-Wrap .timeline .event .txt::before {
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background-color: #898989;
  left: -36px;
  top: 9px;
}

@media (max-width: 1080px) {
  .partner-timeLine-Wrap .timeline .event .txt {
    padding-left: 0;
  }
}

.partner-timeLine-Wrap .swiper-wrapper {
  /* 通过改变animation-timing-function 制作弹性切换效果 */
  transition: 2s cubic-bezier(0.68, -0.4, 0.27, 1.34) 0.2s;
}

@media (max-width: 1080px) {
  .partner-timeLine-Wrap .swiper-wrapper {
    padding-bottom: 5rem;
  }
}

.partner-timeLine-Wrap .swiper-button-next, .partner-timeLine-Wrap .swiper-button-prev {
  width: 60px;
  height: 60px;
  background: #fff;
  color: #000;
  border-radius: 50%;
  box-shadow: 0 0 12px 4px rgba(0, 0, 0, 0.1);
}

.partner-timeLine-Wrap .swiper-button-next::after, .partner-timeLine-Wrap .swiper-button-prev::after {
  content: '';
  display: inline-block;
  width: 16px;
  height: 19px;
  background-repeat: no-repeat;
  background-image: url("../images/icon-arrow03.png");
  background-position: center;
  background-size: contain;
  position: absolute;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

@media (max-width: 1080px) {
  .partner-timeLine-Wrap .swiper-button-next, .partner-timeLine-Wrap .swiper-button-prev {
    top: 27.34375vw;
  }
}

@media (max-width: 640px) {
  .partner-timeLine-Wrap .swiper-button-next, .partner-timeLine-Wrap .swiper-button-prev {
    width: 50px;
    height: 50px;
    top: 54.6875vw;
  }
}

.partner-timeLine-Wrap .swiper-button-prev::after {
  transform: rotate(-180deg) translate(-50%, -50%);
  transform-origin: 0px 0px;
}

@media (min-width: 1090px) and (max-width: 1600px) {
  .partner-timeLine-Wrap {
    padding-top: 5.26316vw;
  }
}

@media (max-width: 1080px) {
  .partner-timeLine-Wrap {
    padding-top: 10rem;
  }
  .partner-timeLine-Wrap::before {
    top: -4%;
  }
}

@media (max-width: 810px) {
  .partner-timeLine-Wrap {
    margin-top: 0rem;
  }
  .partner-timeLine-Wrap::before {
    top: 3.90625vw;
  }
}

@media (max-width: 640px) {
  .partner-timeLine-Wrap {
    padding-top: 7rem;
  }
}

.partner-timeLine-list {
  justify-content: space-around;
}

@media (min-width: 1025px) {
  .partner-timeLine-list {
    max-width: 90%;
    margin: auto;
  }
}

@media (max-width: 1080px) {
  .partner-timeLine-list {
    flex-direction: column;
  }
}

.partner-timeLine-img {
  text-align: center;
}

.partner-timeLine-img .img {
  display: inline-block;
  max-width: 400px;
  height: 325px;
}

.partner-portfolio-Wrap {
  margin-top: 6rem;
  padding-top: 5.5rem;
  overflow: hidden;
}

.partner-portfolio-Wrap::before {
  content: 'Portfolio';
  top: -0.52632vw;
}

@media (max-width: 1080px) {
  .partner-portfolio-Wrap::before {
    font-size: 15.625vw;
    top: -10%;
  }
}

@media (max-width: 810px) {
  .partner-portfolio-Wrap::before {
    top: -3%;
  }
}

@media (max-width: 640px) {
  .partner-portfolio-Wrap::before {
    top: 3.90625vw;
  }
}

.partner-portfolio-Wrap .partnerTitle {
  font-size: 36px;
  font-size: 2.25rem;
}

@media (max-width: 1080px) {
  .partner-portfolio-Wrap {
    margin-top: 1rem;
  }
}

.partnerCertify {
  position: relative;
  width: 100%;
  max-width: 1200px;
  margin: 5rem auto 0;
}

.partnerCertify .swiper-container {
  padding-bottom: 200px;
}

@media (max-width: 1080px) {
  .partnerCertify .swiper-container {
    padding-bottom: 150px;
  }
}

.partnerCertify .swiper-slide {
  width: 440px;
  height: 440px;
}

.partnerCertify .swiper-slide-circle {
  height: 100%;
  overflow: hidden;
  border: #cfcdcd 10px solid;
  border-radius: 50%;
  transition: 0.5s;
}

.partnerCertify .swiper-slide::after {
  content: '';
  display: block;
  width: 220px;
  height: 40px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../images/partner-shadow01.png");
  position: absolute;
  bottom: -90px;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0.75;
}

@media (max-width: 1080px) {
  .partnerCertify .swiper-slide::after {
    bottom: -60px;
  }
}

.partnerCertify .swiper-slide img {
  display: block;
}

.partnerCertify .swiper-slide-active .swiper-slide-circle {
  border-color: #eb6d80;
}

.partnerCertify .swiper-slide-active::after {
  opacity: 1;
}

@media (max-width: 1080px) {
  .partnerCertify .swiper-slide {
    width: 17rem;
    height: 17rem;
  }
}

.partnerCertify .swiper-pagination {
  margin-top: 4rem;
}

.partnerCertify .swiper-pagination-bullets {
  width: 100% !important;
  bottom: 20px;
}

.partnerCertify .swiper-pagination-bullet {
  margin: 0 14px;
  background-color: #828181;
  width: 10px;
  height: 10px;
  opacity: 1;
}

.partnerCertify .swiper-pagination-bullet-active {
  background-color: #eb6d80;
}

.partnerCertify .swiper-button-next, .partnerCertify .swiper-button-prev {
  width: 60px;
  height: 60px;
  background: #fff;
  color: #000;
  border-radius: 50%;
  box-shadow: 0 0 12px 4px rgba(0, 0, 0, 0.1);
  margin-top: -120px;
}

.partnerCertify .swiper-button-next::after, .partnerCertify .swiper-button-prev::after {
  content: '';
  display: inline-block;
  width: 16px;
  height: 19px;
  background-repeat: no-repeat;
  background-image: url("../images/icon-arrow03.png");
  background-position: center;
  background-size: contain;
  position: absolute;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.partnerCertify .swiper-button-prev::after {
  transform: rotate(-180deg) translate(-50%, -50%);
  transform-origin: 0px 0px;
}

@media (min-width: 1025px) {
  .partnerCertify .swiper-button-prev {
    left: calc(50% - 250px);
  }
  .partnerCertify .swiper-button-next {
    right: calc(50% - 250px);
  }
}

@media (max-width: 1080px) {
  .partnerCertify .swiper-button-next, .partnerCertify .swiper-button-prev {
    width: 50px;
    height: 50px;
    margin-top: -85px;
  }
  .partnerCertify .swiper-button-prev {
    left: 10px;
  }
  .partnerCertify .swiper-button-next {
    right: 10px;
  }
}

@media (max-width: 640px) {
  .partnerCertify .swiper-button-prev {
    left: 50px;
  }
  .partnerCertify .swiper-button-next {
    right: 50px;
  }
}

@media (max-width: 640px) {
  .partnerCertify {
    margin-top: 1rem;
  }
}

@media (min-width: 1025px) {
  .partner-timeLine-img,
  .partner-timeLine-list .timeline {
    flex: 0 0 50%;
  }
}

.partner-timeLine-list .timeline {
  margin-top: 1.5rem;
}

@media (max-width: 1080px) {
  .partner-timeLine-list .timeline {
    margin: 1.5rem 1rem;
  }
}

.fileUpload-info {
  padding-top: 10px;
  padding-bottom: 3rem;
}

.fileUpload-info .orderNote-txt {
  padding-left: 40px;
  padding-top: 0.5rem;
}

@media (max-width: 1080px) {
  .fileUpload-info {
    padding-top: 2rem;
  }
}

.fileUpload-form .contact-block {
  padding-top: 0;
  padding-left: 1rem;
  padding-right: 0;
}

.fileUpload-form .contact-block.payReturn .contact-form input.form-control {
  flex-grow: 1;
  flex: auto;
  max-width: 100%;
}

@media (min-width: 1100px) and (max-width: 1600px) {
  .fileUpload-form .contact-block.payReturn .contact-form .form-group > label {
    flex: none;
  }
}

.file-upload {
  display: block;
  text-align: center;
  font-size: 17px;
}

.file-upload .file-select {
  display: block;
  border: 1px solid #9c9b9c;
  color: #5f5f5f;
  cursor: pointer;
  padding: 10px 5px;
  text-align: left;
  overflow: hidden;
  position: relative;
}

.file-upload .file-select-button {
  display: inline-block;
  line-height: 1.5;
  color: #fff;
  background: #292828;
  border-radius: 20px;
  padding: 5px 25px;
}

.file-upload .file-select-name {
  line-height: 30px;
  display: inline-block;
  padding: 0 10px;
}

.file-upload .file-select:hover {
  border-color: #34495e;
  transition: all .2s ease-in-out;
}

.file-upload .file-select:hover .file-select-button {
  background: #34495e;
  color: #FFFFFF;
  transition: all .2s ease-in-out;
}

.file-upload.active .file-select {
  border-color: #ed8f3c;
}

.file-upload.active .file-select, .file-upload.active .file-select-button {
  transition: all .2s ease-in-out;
}

.file-upload.active .file-select-button {
  background: #ed8f3c;
  color: #FFFFFF;
}

.file-upload .file-select input[type=file] {
  z-index: 100;
  cursor: pointer;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  filter: alpha(opacity=0);
}

.file-upload .file-select.file-select-disabled {
  opacity: 0.65;
}

.file-upload .file-select.file-select-disabled:hover {
  cursor: default;
  display: block;
  border: 2px solid #dce4ec;
  color: #34495e;
  cursor: pointer;
  height: 40px;
  line-height: 30px;
  margin-top: 5px;
  text-align: left;
  background: #FFFFFF;
  overflow: hidden;
  position: relative;
}

.file-upload .file-select.file-select-disabled:hover .file-select-button {
  background: #dce4ec;
  color: #666666;
  padding: 0 10px;
  display: inline-block;
  height: 40px;
  line-height: 30px;
}

.file-upload .file-select.file-select-disabled:hover .file-select-name {
  line-height: 30px;
  display: inline-block;
  padding: 0 10px;
}

.uploadForm {
  width: 100%;
}

.file-upload-wrapper {
  position: relative;
  width: 100%;
  height: 60px;
  border: 1px solid #9c9b9c;
  color: #5f5f5f;
  cursor: pointer;
  padding-left: 5px;
  text-align: left;
  overflow: hidden;
  position: relative;
}

.file-upload-wrapper:after {
  content: attr(data-text);
  font-size: 18px;
  padding: 10px;
  display: block;
  pointer-events: none;
  z-index: 20;
  line-height: 40px;
  color: #999;
  border-radius: 5px 10px 10px 5px;
  font-weight: 300;
}

.file-upload-wrapper:before {
  content: '選擇檔案';
  display: inline-block;
  line-height: 1.5;
  color: #fff;
  background: #292828;
  border-radius: 20px;
  padding: 5px 25px;
  z-index: 25;
}

.file-upload-wrapper:hover:before {
  background: #3d8c63;
}

.file-upload-wrapper input {
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  height: 40px;
  margin: 0;
  padding: 0;
  display: block;
  cursor: pointer;
  width: 100%;
}

.index-content {
  padding: 2rem 1rem;
  overflow: hidden;
}

@media (max-width: 1024px) {
  .index-content {
    padding: 3rem 1.5rem;
  }
}

.index-news {
  padding-bottom: 10%;
  margin-top: 3%;
  margin-bottom: -7%;
}

.index-news::before {
  content: '';
  display: block;
  width: 105%;
  height: 10%;
  background-color: #fff;
  border-top-left-radius: 80% 40%;
  border-top-right-radius: 80% 40%;
  min-height: 600px;
  position: absolute;
  left: 50%;
  top: 5px;
  transform: translateX(-50%);
}

@media (max-width: 810px) {
  .index-news::before {
    border-top-left-radius: 80% 30%;
    border-top-right-radius: 80% 30%;
    min-height: 46.875vw;
    height: 5%;
    top: 0px;
  }
}

@media (max-width: 640px) {
  .index-news::before {
    min-height: 156.25vw;
    border-top-left-radius: 100% 30%;
    border-top-right-radius: 100% 30%;
  }
}

.index-news::after {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background-color: #fff;
  position: absolute;
  top: 15%;
  left: 0;
  z-index: -1;
}

@media (min-width: 1025px) {
  .index-news {
    padding-top: 5%;
  }
}

@media (max-width: 1024px) {
  .index-news {
    margin-top: 10%;
    padding-bottom: 20%;
  }
}

@media (min-width: 1101px) and (max-width: 1400px) {
  .index-news {
    padding-top: 8%;
    padding-left: 5rem;
    padding-right: 5rem;
    margin-bottom: -10%;
  }
}

@media (min-width: 768px) and (max-width: 1000px) {
  .index-news {
    padding-top: 15%;
    margin-top: 10%;
    margin-bottom: -11%;
  }
}

@media (max-width: 640px) {
  .index-news {
    margin-top: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 20%;
  }
  .index-news .wrapper-1400,
  .index-news .wrapper-1500 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.index-work {
  background-color: #fff;
  overflow: visible;
  margin-bottom: -26.31579vw;
}

.index-work > div {
  z-index: 2;
}

.index-work::after {
  content: '';
  display: block;
  width: 100%;
  height: 25vw;
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: contain;
  background-image: url("../images/index-news-bottomBg.png");
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 0;
}

.index-work .symbol {
  padding-top: 0;
}

.index-work .symbol::before {
  top: -30px;
}

@media (max-width: 1024px) {
  .index-work .symbol::before {
    top: -2.5rem;
  }
}

@media (min-width: 1101px) and (max-width: 1400px) {
  .index-work {
    padding-left: 5rem;
    padding-right: 5rem;
    margin-bottom: -31.57895vw;
  }
}

@media (max-width: 810px) {
  .index-work {
    margin-bottom: -50vw;
  }
}

.index-workCard {
  margin-top: 2rem;
}

.index-workCard .card-wrap {
  justify-content: center;
}

.index-workCard .card {
  border-radius: 2rem;
  overflow: visible;
}

.index-workCard .card .styleRadiu__image {
  border-radius: 2rem;
  box-shadow: 0 11px 15px -2px rgba(0, 0, 0, 0.25);
  overflow: hidden;
  height: 0;
  padding-bottom: 100%;
}

.index-workCard .card .styleRadiu__image img {
  height: 100%;
}

@media (min-width: 1025px) {
  .index-workCard .card .styleRadiu__image {
    max-width: calc(100% - (60px / 2));
    border-radius: 70px;
    padding-bottom: 88%;
  }
}

.index-workCard .card__body {
  padding: 1rem;
}

@media (min-width: 1025px) {
  .index-workCard .card__body {
    padding: 1.5rem 2.75rem 3.75rem;
  }
}

@media (min-width: 1100px) and (max-width: 1500px) {
  .index-workCard .card__body {
    padding: 1.25rem 1rem 2.25rem 2rem;
  }
}

@media (min-width: 1025px) {
  .index-workCard .card__title {
    font-size: 24px;
    font-size: 1.5rem;
  }
}

.index-workCard .card__caption {
  margin-top: 1rem;
  margin-bottom: 1.25rem;
}

@media (min-width: 1025px) {
  .index-workCard .card {
    border-radius: 70px;
  }
  .index-workCard .card p {
    font-size: 16px;
    font-size: 1rem;
  }
  .index-workCard .card:nth-child(odd) .styleRadiu__image {
    transform: rotate(-11deg);
    transform-origin: 18% calc(50% - 60px);
  }
  .index-workCard .card:nth-child(even) {
    transform: translateY(-5rem);
  }
  .index-workCard .card:nth-child(even) .styleRadiu__image {
    transform: rotate(11deg);
    transform-origin: 90% calc(50% + 100px);
  }
}

@media (max-width: 1080px) {
  .index-workCard .card__btn .btn {
    display: block;
  }
}

@media (min-width: 1025px) {
  .index-workCard {
    margin-top: 10rem;
  }
}

@media (min-width: 1101px) and (max-width: 1400px) {
  .index-workCard {
    margin-top: 8rem;
  }
  .index-workCard .card {
    flex: 0 0 calc((100% / 4) - 20px);
    max-width: calc((100% / 4) - 20px);
    margin-left: 10px;
    margin-right: 10px;
  }
}

.index-service {
  padding-top: 21.05263vw;
  padding-bottom: 13.15789vw;
  margin-bottom: -13.15789vw;
}

.index-service::before {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  background-image: url("../images/index-service-bg.png");
  position: absolute;
  left: 0;
  top: 0;
}

.index-service .partner-portfolio-Wrap {
  text-align: center;
  margin-top: 5rem;
}

@media (max-width: 1024px) {
  .index-service .partner-portfolio-Wrap {
    padding-top: 4rem;
  }
}

.index-service .partner-portfolio-Wrap .partnerTitle {
  display: inline-block;
  width: auto;
  margin-top: -2rem;
}

@media (min-width: 1025px) {
  .index-service .partner-portfolio-Wrap .partnerTitle::before {
    top: 15px;
  }
}

@media (max-width: 1024px) {
  .index-service .partner-portfolio-Wrap .partnerTitle::before {
    display: none;
  }
}

.index-service .partner-portfolio-Wrap::before {
  content: 'Product Item';
  width: 100%;
  color: #fcffff;
  -webkit-text-stroke: 10px #fff;
  opacity: 0.5;
}

@media (max-width: 1024px) {
  .index-service .partner-portfolio-Wrap::before {
    -webkit-text-stroke: 5px #fff;
    font-size: 11.09375vw;
    letter-spacing: 1px;
  }
}

@media (max-width: 1024px) {
  .index-service::before {
    top: -140vw;
    height: 765vw;
  }
}

@media (max-width: 1080px) {
  .index-service {
    padding-top: 10.9375vw;
    margin-bottom: -20.3125vw;
  }
}

@media (max-width: 640px) {
  .index-service::before {
    top: -126vw;
  }
}

.index-serviceCard .card {
  text-align: center;
  border: none;
  background: transparent;
}

@media (min-width: 1025px) {
  .index-serviceCard .card {
    flex: 0 0 calc(100% / 6 - 50px);
    max-width: calc(100% / 6 - 50px);
  }
}

@media (max-width: 1024px) {
  .index-serviceCard .card {
    flex: 0 0 calc(100% / 3 - 10px);
    max-width: calc(100% / 3 - 10px);
    margin-bottom: 1rem;
  }
  .index-serviceCard .card__image > img {
    height: auto;
  }
}

.index-serviceCard .card__caption {
  margin-top: 0;
  font-size: 1.26316vw;
}

@media (max-width: 1024px) {
  .index-serviceCard .card__caption {
    font-size: 3.90625vw;
  }
}

.index-banner {
  position: relative;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  padding-top: 2rem;
  padding-bottom: 8rem;
}

.index-banner::after {
  content: '';
  display: block;
  width: 100%;
  height: 10vw;
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
  background-image: url("../images/index-banner-bottomBg.png");
  position: absolute;
  bottom: 0;
  left: 0;
}

.index-banner .myIntrotxt {
  width: 100%;
  padding-left: 3rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.index-banner .myIntrotxt__title {
  font-size: 3.15789vw;
  color: #212020;
  font-weight: 900;
  line-height: 1.25;
}

.index-banner .myIntrotxt__text {
  font-size: 1.26316vw;
  color: #676666;
  margin: 40px 0;
}

.index-banner .myIntrotxt__btn .btn {
  font-size: 1.26316vw;
  padding: 1.5rem 3rem !important;
  border: none;
  color: #fff;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#40bafe+0,a442ff+100 */
  background: #40bafe;
  /* Old browsers */
  background: -moz-linear-gradient(left, #40bafe 0%, #a442ff 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #40bafe 0%, #a442ff 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #40bafe 0%, #a442ff 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#40bafe', endColorstr='#a442ff',GradientType=1 );
  /* IE6-9 */
  transition: 0.25s;
}

.index-banner .myIntrotxt__btn .btn:hover {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#fc02af+0,feb109+99 */
  background: #fc02af;
  /* Old browsers */
  background: -moz-linear-gradient(left, #fc02af 0%, #feb109 99%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #fc02af 0%, #feb109 99%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #fc02af 0%, #feb109 99%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fc02af', endColorstr='#feb109',GradientType=1 );
  /* IE6-9 */
}

.index-banner .myIntrotxt__iconBottom {
  position: absolute;
}

.index-banner .myIntrotxt__iconBottom.left {
  left: 0;
  bottom: -8vw;
  opacity: 1;
  animation: swing ease-in-out 1s infinite alternate;
}

.index-banner .myIntrotxt__iconBottom.right {
  right: 4vw;
  bottom: -6vw;
  animation: swing ease-in-out 1s 0.5s infinite alternate;
}

@media (min-width: 1101px) and (max-width: 1500px) {
  .index-banner .myIntrotxt__iconBottom.left {
    bottom: -12vw;
  }
  .index-banner .myIntrotxt__iconBottom.right {
    bottom: -8vw;
  }
}

@media (min-width: 1025px) {
  .index-banner .myIntrotxt {
    padding-top: 3.94737vw;
  }
}

@media (max-width: 1024px) {
  .index-banner .myIntrotxt__title {
    font-size: 6.25vw;
  }
  .index-banner .myIntrotxt__text {
    font-size: 3.125vw;
  }
  .index-banner .myIntrotxt__btn .btn {
    font-size: 2.8125vw;
    padding: 1rem 3rem !important;
  }
  .index-banner .myIntrotxt__iconBottom.left {
    left: 1rem;
    bottom: -8rem;
  }
}

@media (max-width: 640px) {
  .index-banner .myIntrotxt__title {
    font-size: 9.375vw;
  }
  .index-banner .myIntrotxt__text {
    font-size: 3.75vw;
  }
  .index-banner .myIntrotxt__btn .btn {
    font-size: 4.0625vw;
    padding: 1rem 3rem !important;
  }
}

.index-banner .mySwiper {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.index-banner .main {
  position: relative;
  width: 100% !important;
}

.index-banner .center {
  position: absolute;
  left: 50%;
  width: 50.52632vw;
  height: 49.21053vw;
}

@media (max-width: 1024px) {
  .index-banner .center {
    width: 100%;
    height: 96%;
  }
}

.index-banner .center .bottle-bg {
  width: 880px;
  height: 800px;
  object-fit: cover;
}

@media (max-width: 1024px) {
  .index-banner .center .bottle-bg {
    width: 260px;
    height: 390px;
  }
}

@media (max-width: 640px) {
  .index-banner .center .bottle-bg {
    width: 220px;
    height: 340px;
  }
}

.index-banner .center .bottle-bgColor {
  display: block;
  width: 45.78947vw;
  height: 0;
  padding-bottom: 100%;
  position: absolute;
  top: 0;
  z-index: -1;
  transform: translateX(-50%);
  background-repeat: no-repeat;
  background-position: top;
  background-size: contain;
  background-image: url("../images/index-banner-radius.png");
}

@media (max-width: 1500px) {
  .index-banner .center .bottle-bgColor {
    width: 54vw;
  }
}

@media (max-width: 1024px) {
  .index-banner .center .bottle-bgColor {
    width: 100%;
  }
}

.index-banner .center .bottle-bgLine {
  display: block;
  width: 52.63158vw;
  height: 0;
  padding-bottom: 100%;
  position: absolute;
  top: 0;
  z-index: -2;
  transform: translateX(-50%);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../images/index-banner-radius02.png");
}

@media (min-width: 1025px) {
  .index-banner .center .bottle-bgLine {
    top: -4.21053vw;
  }
}

@media (max-width: 1024px) {
  .index-banner .center .bottle-bgLine {
    width: 100%;
    top: -9.375vw;
  }
}

.index-banner .center .right-side__img {
  width: 100%;
  height: 100%;
}

.index-banner .center .right-side__icon {
  position: absolute;
}

.index-banner .center .right-side__icon.top {
  top: 7rem;
  left: 45%;
}

@media (min-width: 1101px) and (max-width: 1400px) {
  .index-banner .center .right-side__icon.top {
    top: 5rem;
  }
}

.index-banner .center .right-side__icon.bottom {
  left: 32.5%;
  top: 18.42105vw;
  z-index: 1;
}

@media (max-width: 1400px) {
  .index-banner .center .right-side__icon.bottom {
    left: 18.42105vw;
    top: 21.05263vw;
  }
}

@media (max-width: 1024px) {
  .index-banner .center .right-side__icon {
    display: none;
  }
}

.index-banner .center .right-side__color {
  max-width: 40vw;
  position: absolute;
  bottom: 10.52632vw;
  left: 6.84211vw;
  z-index: 1;
}

@media (min-width: 1101px) and (max-width: 1600px) {
  .index-banner .center .right-side__color {
    bottom: 6.31579vw;
  }
}

@media (max-width: 1024px) {
  .index-banner .center .right-side__color {
    max-width: 35vw;
    top: auto;
    bottom: 1rem;
    left: 13.15789vw;
  }
}

@media (min-width: 1025px) {
  .index-banner {
    flex-direction: row;
    padding-left: 9.47368vw;
    padding-top: 5rem;
    z-index: 1002;
    margin-top: -3rem;
  }
  .index-banner .myIntrotxt, .index-banner .mySwiper {
    width: 50%;
  }
  .index-banner .mySwiper {
    margin-right: 7.36842vw;
    margin-top: -2rem;
  }
  .index-banner .swiper-container {
    padding-bottom: 1.5rem;
    margin-bottom: 1rem;
    overflow: visible;
  }
}

@media (max-width: 1700px) {
  .index-banner {
    padding-left: 3.68421vw;
  }
  .index-banner .swiper-container {
    padding-bottom: 1.5rem;
    margin-bottom: -1rem;
  }
}

@media (min-width: 1100px) and (max-width: 1500px) {
  .index-banner .mySwiper {
    width: 62%;
  }
}

@media (min-width: 800px) and (max-width: 1050px) {
  .index-banner .swiper-container {
    padding-bottom: 1.5rem;
    margin-bottom: 0rem;
  }
}

@media (max-width: 1024px) {
  .index-banner {
    padding-left: 0;
    padding-top: 0;
    padding-bottom: 1rem;
  }
  .index-banner .mySwiper {
    margin-top: 8rem;
    margin-bottom: 3rem;
  }
  .index-banner .myIntrotxt {
    padding-left: 1.5rem;
  }
}

.index-banner .swiper-slide .bottle-img {
  transition-duration: 0.8s;
  transform: scale(0.5);
  opacity: 0;
  border-radius: 69% 65% 73% 61% / 70% 60% 80% 60%;
}

.index-banner .swiper-slide-active .bottle-img {
  opacity: 1;
  transform: scale(1);
  margin-top: 2rem;
  margin-left: -1.25rem;
}

@media (max-width: 1024px) {
  .index-banner .swiper-slide-active .bottle-img {
    margin-left: 0;
  }
}

.index-banner .swiper-pagination {
  position: relative;
  top: 1rem;
  z-index: 2;
}

.index-banner .swiper-pagination-bullet {
  width: 20px;
  height: 20px;
  background-color: #ffffff;
  border: #192563 3px solid;
  opacity: 1;
  margin: 0 0.5rem;
}

.index-banner .swiper-pagination-bullet-active {
  background-color: #192563;
}

@media (max-width: 1500px) {
  .index-banner .swiper-pagination {
    top: 4rem;
  }
}

@media (max-width: 1024px) {
  .index-banner .swiper-pagination {
    top: 1rem;
  }
}

@keyframes iconOpacity {
  to {
    opacity: 0;
  }
}

@keyframes swing {
  0% {
    transform: rotate(9deg);
  }
  100% {
    transform: rotate(-9deg);
  }
}

.imgStyle-wave {
  border: none;
  width: 100%;
  height: 20px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url("../images/imgLine-wave.png");
}

.serviceItem-list {
  position: relative;
  padding: 5rem 0;
  min-height: 42.10526vw;
}

.serviceItem-list .iconList {
  position: absolute;
}

.serviceItem-list .iconList-01 {
  width: 9.73684vw;
  height: 9.73684vw;
  top: 10.52632vw;
}

.serviceItem-list .iconList-02 {
  width: 8.42105vw;
  height: 8.42105vw;
  top: 17.10526vw;
  left: 9.47368vw;
}

.serviceItem-list .iconList-03 {
  width: 12.63158vw;
  height: 12.63158vw;
  top: 26.31579vw;
  left: 2.63158vw;
}

.serviceItem-list .iconList-04 {
  width: 13.15789vw;
  height: 13.15789vw;
  top: 2.36842vw;
  left: 11.57895vw;
}

.serviceItem-list .iconList-05 {
  width: 9.47368vw;
  height: 9.47368vw;
  top: 11.05263vw;
  left: 24.21053vw;
}

.serviceItem-list .iconList-06 {
  width: 11.31579vw;
  height: 11.31579vw;
  top: 21.05263vw;
  left: 18.42105vw;
}

.serviceItem-list .iconList-07 {
  width: 13.15789vw;
  height: 13.15789vw;
  top: 6.84211vw;
  left: 35.26316vw;
}

.serviceItem-list .iconList-08 {
  width: 9.47368vw;
  height: 9.47368vw;
  top: 20vw;
  left: 31.05263vw;
}

.serviceItem-list .iconList-09 {
  width: 8.42105vw;
  height: 8.42105vw;
  top: 30.52632vw;
  left: 28.42105vw;
}

.serviceItem-list .iconList-10 {
  width: 9.21053vw;
  height: 9.21053vw;
  top: 2.10526vw;
  left: 47.36842vw;
}

.serviceItem-list .iconList-11 {
  width: 9.47368vw;
  height: 9.47368vw;
  top: 18.42105vw;
  left: 45.26316vw;
}

.serviceItem-list .iconList-12 {
  width: 11.57895vw;
  height: 11.57895vw;
  top: 26.84211vw;
  left: 51.57895vw;
}

.serviceItem-list .iconList-13 {
  width: 12.10526vw;
  height: 12.10526vw;
  top: 12.10526vw;
  left: 54.73684vw;
}

.serviceItem-list .iconList-14 {
  width: 8.42105vw;
  height: 8.42105vw;
  top: 24.21053vw;
  left: 63.68421vw;
}

.serviceItem-list .iconList-15 {
  width: 8.42105vw;
  height: 8.42105vw;
  top: 5.26316vw;
  left: 65.78947vw;
}

.serviceItem-list .iconList-16 {
  width: 10vw;
  height: 10vw;
  top: 14.73684vw;
  left: 68.42105vw;
}

@media (max-width: 1024px) {
  .serviceItem-list .iconList {
    position: static;
    display: inline-block;
    width: calc(33% - 4px);
    height: auto;
  }
}

@media (max-width: 810px) {
  .serviceItem-list .iconList {
    width: calc(33% - 4px);
  }
}

@media (max-width: 640px) {
  .serviceItem-list .iconList {
    width: calc(50% - 4px);
  }
}

@media (min-width: 1800px) {
  .serviceItem-list {
    margin-left: -2.10526vw;
  }
}

@media (min-width: 1500px) and (max-width: 1700px) {
  .serviceItem-list {
    margin-left: 3.15789vw;
  }
}

@media (min-width: 1400px) and (max-width: 1499px) {
  .serviceItem-list {
    margin-left: 7.36842vw;
  }
}

@media (min-width: 1101px) and (max-width: 1399px) {
  .serviceItem-list {
    margin-left: 9.47368vw;
  }
}

@media (max-width: 1024px) {
  .serviceItem-list {
    padding-top: 1rem;
    padding-bottom: 0;
    margin-left: -15px;
    margin-right: -15px;
  }
}

.circleWrap {
  width: 100%;
  height: 0;
  padding-bottom: calc(100% - 6px);
  border-radius: 50%;
  border: #212020 3px solid;
  z-index: 1;
  cursor: pointer;
  transform: scale(1);
  transition: 0.25s;
}

.circleWrap .txt {
  font-size: 1.47368vw;
  line-height: 1.25;
  margin-bottom: 0;
}

.circleWrap .subtxt {
  display: block;
  margin-bottom: 5px;
}

.circleWrap .circle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
  height: 100%;
  justify-content: center;
  flex-direction: column;
  flex-wrap: nowrap;
  overflow: hidden;
}

.circleWrap .circle::after {
  content: '';
  display: block;
  width: 2.21053vw;
  height: 2.21053vw;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../images/icon-arrowCircle.png");
  position: absolute;
  transform: translateY(1000%);
  transition: 0.3s;
}

@media (min-width: 1025px) {
  .circleWrap .img {
    margin-bottom: 0.52632vw;
  }
  .circleWrap .img img {
    width: 4.73684vw;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }
  .circleWrap:hover {
    border-width: 0.42105vw !important;
    border-color: #fff;
    padding-bottom: calc(100% - 16px);
    transform: scale(1.15);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  }
}

@media (min-width: 1025px) and (min-width: 1101px) and (max-width: 1500px) {
  .circleWrap:hover {
    padding-bottom: calc(100% - 12px);
  }
}

@media (min-width: 1025px) {
  .circleWrap:hover .img {
    margin-bottom: 10px;
  }
}

@media (min-width: 1025px) and (min-width: 1101px) and (max-width: 1400px) {
  .circleWrap:hover .img {
    margin-bottom: 5px;
  }
}

@media (min-width: 1025px) {
  .circleWrap:hover .txt {
    margin-bottom: 5px;
  }
  .circleWrap:hover .circle::after {
    position: relative;
    transform: translateY(10px);
    opacity: 1;
    visibility: visible;
  }
}

@media (min-width: 1025px) and (min-width: 1101px) and (max-width: 1400px) {
  .circleWrap:hover .circle::after {
    width: 1.57895vw;
    height: 1.57895vw;
    transform: translateY(0px);
  }
}

@media (min-width: 1025px) {
  .circleWrap:hover .txt,
  .circleWrap:hover .subtxt {
    color: #fff;
  }
}

@media (max-width: 1024px) {
  .circleWrap .txt {
    font-size: 4.6875vw;
  }
  .circleWrap:hover .txt,
  .circleWrap:hover .subtxt {
    color: #fff !important;
  }
}

.circle-01 {
  border-color: #c82270;
}

.circle-01 .txt {
  color: #c82270;
}

.circle-01:hover {
  background-color: #c82270;
}

.circle-02 {
  border-color: #8174f0;
}

.circle-02 .txt {
  color: #8174f0;
}

.circle-02:hover {
  background-color: #8174f0;
}

.circle-03 {
  background-color: #fff;
}

.circle-03 .txt {
  color: #4c4b4b;
}

.circle-03 .circle .img img[src="assets/images/index-serviceItem-icon05-active.png"] {
  display: none;
}

.circle-03:hover {
  background-color: #4c4b4b;
}

.circle-03:hover .circle .img img[src="assets/images/index-serviceItem-icon05.png"] {
  display: none;
}

.circle-03:hover .circle .img img[src="assets/images/index-serviceItem-icon05-active.png"] {
  display: block;
}

.circle-03.border-w6 {
  padding-bottom: calc(100% - 12px);
}

.circle-04 {
  border-color: #bc3497;
}

.circle-04 .txt {
  color: #bc3497;
}

.circle-04:hover {
  background-color: #bc3497;
}

.circle-05 {
  border-color: transparent;
}

.circle-05::before {
  content: '';
  display: block;
  width: 104%;
  height: 104%;
  border-radius: 50%;
  background-image: linear-gradient(to bottom right, #fb91cf 0%, #b3bcd9 100%);
  z-index: -1;
}

.circle-05 .circle {
  background-color: #fff;
  border-radius: 50%;
}

.circle-05 .txt {
  color: #bc3497;
}

.circle-05:hover::before {
  display: none;
}

.circle-05:hover .circle {
  background-image: linear-gradient(to bottom right, #fb91cf 0%, #b3bcd9 100%);
}

.circle-06 {
  border-color: #aa75d6;
}

.circle-06 .txt {
  color: #aa75d6;
}

.circle-06:hover {
  background-color: #aa75d6;
}

.circle-07 {
  border-color: transparent;
}

.circle-07 .circle {
  background-image: linear-gradient(to bottom right, #2aa5f9 0%, #65ddd2 100%);
  border-radius: 50%;
}

.circle-08 {
  border-color: transparent;
}

.circle-08 .circle {
  background-image: linear-gradient(to bottom right, #ec8bc4 0%, #f5bdac 100%);
  border-radius: 50%;
}

.circle-09 {
  border-color: transparent;
}

.circle-09 .circle {
  background-image: linear-gradient(to bottom right, #f86e67 0%, #fd578e 100%);
  border-radius: 50%;
}

.circle-10 {
  border-color: #ae5da1;
  background-color: #ae5da1;
}

.circle-11 {
  border-color: transparent;
}

.circle-11 .circle {
  background-image: linear-gradient(to bottom right, #c14092 0%, #174ebf 100%);
  border-radius: 50%;
}

.border-w6 {
  border-width: 6px;
}

.proCard {
  margin-top: 1.5rem;
}

.proCard .card-wrap {
  margin-top: 0;
}

.proCard .card {
  border-color: transparent;
  overflow: hidden;
}

.proCard .card p {
  font-size: 18px;
  font-size: 1.125rem;
}

@media (max-width: 640px) {
  .proCard .card p {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.proCard .card:nth-child(2n) {
  transform: translateY(0);
}

.proCard .card:hover {
  border-color: #646464;
}

.proCard .card__item {
  position: absolute;
  left: 10%;
  top: 6%;
  z-index: 1;
}

.proCard .card__image {
  margin-top: 1rem;
}

.proCard .card__title,
.proCard .card__priceInfo {
  font-size: 20px;
  font-size: 1.25rem;
}

.proCard .card__title {
  color: #484747;
}

.proCard .card__body {
  padding-bottom: 2.75rem;
}

@media (max-width: 810px) {
  .proCard .card__caption {
    margin-top: 0.5rem;
    margin-bottom: 1rem;
  }
  .proCard .card__body {
    padding-bottom: 1rem;
  }
}

.pro-wrapper {
  align-items: flex-start;
}

.pro-wrapper .pro_sidemenu {
  width: 230px;
  padding: 15px 0;
}

.pro-wrapper .rightContent {
  width: calc(100% - 230px);
  padding-left: 6.57895vw;
  padding-top: 0.75%;
}

@media (min-width: 1101px) and (max-width: 1500px) {
  .pro-wrapper .pro_sidemenu {
    width: 180px;
  }
  .pro-wrapper .rightContent {
    width: calc(100% - 180px);
    padding-left: 2.63158vw;
  }
}

@media (max-width: 1024px) {
  .pro-wrapper .pro_sidemenu,
  .pro-wrapper .rightContent {
    width: 100%;
    padding: 0 0 15px 0;
  }
}

.pro_sideTitle {
  font-size: 30px;
  font-size: 1.875rem;
  font-weight: 600;
  color: #080404;
  margin-bottom: 1rem;
}

.pro_sidemenu_ul > li {
  list-style: none;
  width: 100%;
}

.pro_sidemenu_ul > li > a {
  color: #676666;
  font-size: 22px;
  font-size: 1.375rem;
  line-height: 1.5;
  display: block;
  position: relative;
  width: 100%;
  padding: 10px 20px;
}

.pro_sidemenu_ul > li > a::after {
  content: "\f107";
  font-family: "FontAwesome";
  display: block;
  position: absolute;
  top: 10px;
  right: 0;
  font-size: 1.5rem;
  transform: rotate(0deg);
  transition: 0.3s;
}

.pro_sidemenu_ul > li > .first_menu {
  display: none;
}

.pro_sidemenu_ul > li > .first_menu > li {
  list-style: none;
  padding: 15px 10px;
  margin-bottom: 2px;
}

.pro_sidemenu_ul > li > .first_menu > li > a {
  font-size: 18px;
  font-size: 1.125rem;
  color: #7f7f7f;
  display: block;
  padding-left: 20px;
  position: relative;
  line-height: 1;
}

.pro_sidemenu_ul > li.active > a {
  color: #000;
}

.pro_sidemenu_ul > li.active > a::after {
  transform: rotate(180deg);
}

.pro_sidemenu_ul > li.active > .first_menu {
  display: block;
}

@media (max-width: 1024px) {
  .pro_sidemenu_ul {
    display: none;
  }
  .pro_sidemenu_ul.active {
    display: block;
  }
}

.pro_sideMobilebtn {
  position: absolute;
  top: 0;
  right: 0;
}

.pro_sideMobilebtn .btn {
  padding: 0.5rem 1rem !important;
  color: #ed8f3c !important;
  border-color: #ed8f3c !important;
  background-color: transparent !important;
}

@media (max-width: 1024px) {
  .pro_sideMobilebtn {
    display: block !important;
  }
}

.txtIntro {
  color: #0084FF;
}

.prod_title {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #313131;
  padding-bottom: 16px;
}

.prod_name {
  padding-left: 10px;
}

.prod_txt {
  color: #5f5f5f;
  margin-top: 1.75rem;
  margin-bottom: 1.75rem;
}

.prod_info {
  border-top: 2px dotted #d1d1d1;
  border-bottom: 2px dotted #d1d1d1;
  padding: 30px 0;
}

.prod_info dl {
  position: relative;
  display: flex;
  margin-bottom: 0;
  padding-left: 20px;
}

.prod_info dl + dl {
  margin-top: 0.75rem;
}

.prod_info dl.style__dot.pro::before {
  top: 15px;
  transform: translateY(0);
}

.prod_info dt {
  flex: 0 0 100px;
  color: #5f5f5f;
  font-weight: 100;
  padding-right: 10px;
  display: inline-flex;
  justify-content: space-between;
}

.prod_info dt:after {
  content: ':';
}

.prod_info dd {
  margin-bottom: 0;
}

@media (max-width: 1024px) {
  .prod_info {
    margin-top: 1.5rem;
  }
}

.prod-rightContent {
  align-items: flex-start;
}

.prodWrap_img {
  margin-top: 2rem;
}

.prodWrap_img .swiper-container {
  overflow: hidden;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 1025px) {
  .prodWrap_img .swiper-container {
    min-height: 320px;
  }
}

.prodWrap_img .swiper-container-wrapper {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
}

@media (min-width: 1025px) {
  .prodWrap_img .swiper-container-wrapper {
    flex-flow: row nowrap;
    align-items: center;
  }
}

@media (max-width: 1080px) {
  .prodWrap_img .swiper-container-wrapper {
    flex-flow: column wrap;
  }
}

.prodWrap_img .swiper-button-next, .prodWrap_img .swiper-button-prev {
  width: 25px !important;
  height: 27px !important;
  background-image: url("../images/icon-arrow-right.png") !important;
  background-size: 25px 27px;
}

@media (min-width: 1101px) {
  .prodWrap_img .swiper-button-prev, .prodWrap_img .swiper-button-next {
    height: 19px !important;
    left: calc(10% - 13px);
    background-size: 25px 19px;
  }
  .prodWrap_img .swiper-button-prev {
    top: 14%;
    background-image: url("../images/icon-arrow-top.png") !important;
  }
  .prodWrap_img .swiper-button-next {
    top: auto;
    bottom: 9%;
    background-image: url("../images/icon-arrow-bottom.png") !important;
  }
}

@media (max-width: 1080px) {
  .prodWrap_img .swiper-button-prev, .prodWrap_img .swiper-button-next {
    bottom: 9.5%;
    top: auto;
  }
  .prodWrap_img .swiper-button-prev {
    left: -5px;
    transform: translateX(0%) rotate(-180deg);
  }
  .prodWrap_img .swiper-button-next {
    right: -5px;
  }
}

@media (max-width: 640px) {
  .prodWrap_img .swiper-button-prev, .prodWrap_img .swiper-button-next {
    bottom: 6%;
  }
}

.prodWrap_img .swiper-slide {
  text-align: center;
  background-size: cover;
  background-position: center;
  background-color: #fff;
  /* Center slide text vertically */
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  /* Slide content */
}

.prodWrap_img .swiper-slide .description,
.prodWrap_img .swiper-slide .title {
  display: block;
  opacity: 0;
  transition: 0.5s ease 0.5s;
}

.prodWrap_img .swiper-slide-active .description,
.prodWrap_img .swiper-slide-active .title {
  opacity: 1;
}

.prodWrap_img .swiper-slide-active .title {
  margin-bottom: 0.5rem;
  font-size: 24px;
  color: #000;
  transition: opacity 0.5s ease 0.5s;
}

.prodWrap_img .swiper-slide-active .description {
  font-size: 16px;
  color: #777;
  transition: opacity 0.5s ease 0.75s;
}

.prodWrap_img .gallery-top {
  position: relative;
  width: 100%;
  border-radius: 40px;
  border: #686868 1px solid;
}

@media (min-width: 1025px) {
  .prodWrap_img .gallery-top {
    width: 80%;
    margin-left: 10px;
    height: 480px;
  }
}

@media (min-width: 1101px) and (max-width: 1500px) {
  .prodWrap_img .gallery-top {
    height: 380px;
  }
}

@media (max-width: 1080px) {
  .prodWrap_img .gallery-top {
    width: 100%;
    height: 500px;
    margin-left: 0;
  }
}

@media (max-width: 640px) {
  .prodWrap_img .gallery-top {
    height: 40vh;
  }
}

.prodWrap_img .gallery-thumbs {
  width: 100%;
  padding-top: 10px;
}

@media (min-width: 1025px) {
  .prodWrap_img .gallery-thumbs {
    width: 20%;
    height: calc(50vh / 3);
    padding: 0px 0.75rem 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
}

@media (min-width: 1101px) and (max-width: 1500px) {
  .prodWrap_img .gallery-thumbs {
    min-height: 250px;
  }
}

@media (max-width: 1080px) {
  .prodWrap_img .gallery-thumbs {
    order: 1;
    width: calc(100% - 40px);
    margin: 10px 20px 0;
    min-height: 2vh;
  }
}

@media (max-width: 810px) {
  .prodWrap_img .gallery-thumbs {
    height: 16vh;
  }
}

@media (max-width: 640px) {
  .prodWrap_img .gallery-thumbs {
    height: 13vh;
  }
}

.prodWrap_img .gallery-thumbs .swiper-wrapper {
  flex-direction: row;
}

@media (min-width: 1101px) {
  .prodWrap_img .gallery-thumbs .swiper-wrapper {
    flex-direction: column;
  }
}

.prodWrap_img .gallery-thumbs .swiper-slide {
  width: 25%;
  flex-flow: row nowrap;
  overflow: hidden;
  height: 100%;
  opacity: 0.75;
  border-radius: 20px;
  border: #e4e4e4 1px solid;
  cursor: pointer;
}

@media (min-width: 1025px) {
  .prodWrap_img .gallery-thumbs .swiper-slide {
    flex-flow: column nowrap;
    width: 99%;
  }
}

.prodWrap_img .gallery-thumbs .swiper-slide .swiper-button-next,
.prodWrap_img .gallery-thumbs .swiper-slide .swiper-button-prev {
  position: static;
  transform: translateX(0%) rotate(90deg);
}

.prodWrap_img .gallery-thumbs .swiper-slide .swiper-button-prev {
  order: -1;
}

.prodWrap_img .gallery-thumbs .swiper-slide-thumb-active {
  opacity: 1;
  border: #686868 1px solid;
}

.prodWrap_intro {
  margin-top: 2rem;
}

@media (min-width: 1025px) {
  .prodWrap_intro {
    padding-left: 2rem;
  }
}

.prodWrap_info {
  margin-top: 5rem;
}

.prodWrap_info .nav-tabs {
  border-top: 1px solid #323232;
  border-bottom-width: 1px;
}

.prodWrap_info .nav-tabs > li {
  padding-left: 50px;
  padding-right: 50px;
}

.prodWrap_info .nav-tabs > li > a {
  position: relative;
  display: block;
  text-align: center;
  color: #706f6f;
  padding: 15px;
}

.prodWrap_info .nav-tabs > li > a::before {
  display: none !important;
}

.prodWrap_info .nav-tabs > li > a:hover, .prodWrap_info .nav-tabs > li > a.active {
  color: #000;
}

.prodWrap_info .nav-tabs > li > a:hover::after, .prodWrap_info .nav-tabs > li > a.active::after {
  content: '';
  display: block;
  width: 100%;
  height: 3px;
  background-color: #515151;
  position: absolute;
  left: 50%;
  bottom: -2.95px;
  transform: translateX(-50%);
}

@media (min-width: 1025px) {
  .prodWrap_info .nav-tabs {
    padding-left: 40px;
  }
  .prodWrap_info .nav-tabs > li {
    font-size: 20px;
    font-size: 1.25rem;
  }
}

@media (max-width: 1024px) {
  .prodWrap_info .nav-tabs > li {
    flex: 1;
    padding: 0;
    font-size: 3.4375vw;
  }
  .prodWrap_info .nav-tabs > li > a {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (max-width: 1024px) {
  .prodWrap_info {
    margin-top: 1rem;
  }
}

@media (max-width: 1080px) {
  .prodWrap_img, .prodWrap_intro {
    max-width: 100%;
    flex: 0 0 100%;
  }
}

.tab-content {
  border-bottom: 1px solid #323232;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.tab-content .orderNote-txt {
  color: #51504f;
  font-size: 16px;
  font-size: 1rem;
}

.tab-content .orderNote-txt .style__dot {
  padding-left: 1.5rem;
}

.tab-content .orderNote-txt .style__dot::before {
  left: 7px;
  top: 12px;
}

@media (min-width: 1025px) {
  .tab-content .orderNote-title {
    flex: 0 0 337px;
    padding-right: 2rem;
    margin-left: 0.5rem;
  }
}

.prod_intro {
  border-radius: 20px;
  border: #B5B5B5 2px solid;
  background-color: #fff;
  padding: 0.5rem;
  margin: 1.5rem 3rem;
}

.prod_intro_head, .prod_intro_body {
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-radius: 12px;
  line-height: 1.5;
  padding: 0.75rem 0;
  font-size: 18px;
  font-size: 1.125rem;
}

.prod_intro_head .item, .prod_intro_body .item {
  flex: 1;
  text-align: center;
}

.prod_intro_head {
  background-color: #ed8f3c;
  color: #fff;
}

.prod_intro_body:nth-child(odd) {
  background-color: #FAE5D2;
}

@media (max-width: 1024px) {
  .prod_intro {
    margin: 0;
  }
  .prod_intro_head, .prod_intro_body {
    font-size: 3.125vw;
  }
}

.prod_introContent {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

@media (min-width: 1025px) {
  .prod_introContent * + .imgSample {
    margin-left: 15px;
  }
}

@media (max-width: 640px) {
  .prod_introContent {
    flex-direction: column;
  }
}

.imgSample {
  margin-top: 5px;
}

.imgSample .imgList {
  margin: 0 2px 2px;
}

.prod_step {
  justify-content: center;
}

@media (min-width: 1025px) {
  .prod_step {
    margin: 4rem 0 8rem;
  }
}

.prod_step li {
  position: relative;
}

.prod_step li:nth-child(1) {
  z-index: 5;
}

.prod_step li:nth-child(2) {
  z-index: 4;
}

.prod_step li:nth-child(3) {
  z-index: 3;
}

.prod_step li:nth-child(4) {
  z-index: 2;
}

.prod_step li::after {
  content: '';
  display: block;
  width: 150px;
  height: 40px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../images/partner-shadow01.png");
  position: absolute;
  bottom: -50px;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0.75;
}

@media (min-width: 1025px) {
  .prod_step li + li {
    margin-left: -4rem;
  }
}

@media (max-width: 1024px) {
  .prod_step li::after {
    bottom: -40px;
  }
}

@media (max-width: 810px) {
  .prod_step li {
    flex: 0 0 50%;
    margin-bottom: 3rem;
  }
}

.prod_stepImg {
  position: relative;
}

.prod_stepCaption {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1.5;
  text-align: center;
}

.prod_stepCaption .title {
  font-size: 24px;
  font-size: 1.5rem;
}

.prod_stepCaption .titleStpe01 {
  color: #f54545;
}

.prod_stepCaption .titleStpe02 {
  color: #e6cb00;
}

.prod_stepCaption .titleStpe03 {
  color: #11c2c9;
}

.prod_stepCaption .titleStpe04 {
  color: #9265a3;
}

.prod_stepCaption .titleStpe05 {
  color: #41404a;
}

.prod_stepCaption .txtStep {
  font-size: 16px;
  font-size: 1rem;
  color: #41404a;
  text-transform: uppercase;
}

@media (max-width: 810px) {
  .prod_stepCaption {
    line-height: 1.25;
  }
  .prod_stepCaption .title {
    font-size: 3.90625vw;
  }
  .prod_stepCaption .txtStep {
    font-size: 3.125vw;
  }
}

.card-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 2rem;

  .card {
    width: auto;
    height: auto;
    margin: 2px 25px 40px;
    box-shadow:none;
    border: #b4b4b4 1px solid;
    border-radius: 40px;
    overflow: hidden;
    &__list_4{
      flex: 0 0 calc((100% / 4) - 50px);
      max-width: calc((100% / 4) - 50px);
    }

    
    &__list_3{
      flex: 0 0 calc((100% / 3) - 50px);
      max-width: calc((100% / 3) - 50px);
    }

    p{
      position: relative;
      z-index: 3;
      margin: 0;
    }

    &__body {
      padding: 0.75rem 2rem;
      line-height: 1.5;
    }

    &__image-top{
      margin-bottom: 0.5rem;
    }

    &__image {
      text-align: center;
      // box-shadow: 0 0 40px 3px rgba(0, 0, 0, 0.15);
      margin-bottom: 0.5rem;
      &>img {
        object-fit: cover;
        object-position: center;
      }

      // For Case page
      &.fullStyle{
        &::before{
          content: '';
          display: block;
          width: 100%;
          height: 10rem;
          position: absolute;
          top: 0;
          /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,ffffff+100&1+0,0+100 */
          background: -moz-linear-gradient(top,  rgba(0, 0, 0, 0.8) 0%, rgba(255, 255, 255, 0) 80%); /* FF3.6-15 */
          background: -webkit-linear-gradient(top,  rgba(0, 0, 0, 0.8) 0%, rgba(255, 255, 255, 0) 80%); /* Chrome10-25,Safari5.1-6 */
          background: linear-gradient(to bottom, rgba(0, 0, 0, 0.8) 0%, rgba(255, 255, 255, 0) 80%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#00ffffff',GradientType=0 ); /* IE6-9 */
        }
        &>img {
          height: 450px;
        }
      }

      .btnMagnifier{
        position: absolute;
        left: 0;
        top: 0;
        z-index: 2;
        width: 100%;
        height: 100%;
        text-align: right;
        padding: 1.5rem 1.5rem;
      }
    }

    &__title {
      @include fontSize(20px);
      color: #010101;
      margin-bottom: 0.25rem;
    }

    &__caption {
      @include fontSize(18px);
      color:#5f5f5f;
      margin-top: 0.5rem;
      margin-bottom: 0.75rem;
    }
  }

  &.index-card-wrap{
    .card {
      &__title {
        padding: 4px 8px 2px;
        @include fontSize(22px);
        font-weight: 700;
        p{
          @extend .ellipsis;
        }
      }

      &__description {
        padding: 8px 0;
        @include fontSize(17px);
        font-weight:400;
        color:#332c2e;
      }

    }
  }

  @include pad{
    justify-content: flex-start;
    margin:1rem 0;
    .card{
      margin-left: 5px;
      margin-right: 5px;
      max-width: calc((100% / 2) - 10px);
      flex:0 0 calc((100% / 2) - 10px);

      &__image {
        &>img {
          height: 167px;
        }
        
        // For Case page
        &.fullStyle{
          &>img {
            height: 180px;
          }
        }
      }

      &__body {
        padding: 0.25rem 0.5rem 0.25rem 1.25rem;
      }
    }
  }
  
  @include mobile{
    .card{
      &__title {
        font-size: get-vw-mobile(25px);
      }
      &__caption {
        font-size: get-vw-mobile(20px);
      }
    }
  }
}


/* Timeline */
.timeline {
    border-bottom-right-radius: 2px;
    border-top-right-radius: 2px;
    color: #333;
    position: relative;
    line-height: 1.4em;
    padding: 0px 20px 20px;
    list-style: none;
    text-align: left;

    &:after {
        content: "";
        left: 5px;
        width: 2px;
        height: calc(100% + 70px);
        background: $colorMain;
        position: absolute;
        left: -11px;
        top: 41%;
        transform: translateY(-50%);
        @include mobile{
            left: 17px;
            height: calc(100% - 70px);
            top: 35%;
        }
    }
  
    h1,
    h2,
    h3 {
        font-size: 1.4em;
    }
    
    .event {
      display:flex;
      position: relative;
      padding-left: 10px;
      padding-bottom: 35px;
      &:before {
          position: absolute;
          display: block;
          top: 0;
          left: -2.5rem;
          color: $colorMain;
          content: attr(data-date);
          text-align: right;
          @include fontSize(24px);
          min-width: 120px;
          font-family: $MontserratLight;
      }
  
      .txt{
          position:relative;
          margin-bottom: 0;
          padding-left: 8rem;
          @include fontSize(18px);
          line-height: 1.5;
          &::before{
              content:'';
              display: block;
              height: 18px;
              width: 18px;
              background: $colorMain;
              position: absolute;
              top: 0px;
              left: -50px;
          }

          @include mobile{
            padding-left: 5rem;
            &::before{
                left: -20px;
            }
          }
      }
  
      &:last-of-type {
          padding-bottom: 0;
          &:after {
              height:0;
              top: 0px;
          }
      }

      @include setMinRwd{
        padding-bottom: 70px;
      }
    } 
  }
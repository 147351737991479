.styleRadius-150{
    box-shadow: none !important;
    border-radius: 0 150px 0 150px !important;
}

.imgRadius{
    &-topRight-100,
    &-bottomLeft-100{
        overflow: hidden;
    }

    &-topRight-100{
        border-radius: 100px 0px 100px 0px;
    }

    &-bottomLeft-100{
        border-radius: 0px 100px 0px 100px;
    }
}

.bgText{
    &::before{
        content: '';
        text-transform: uppercase;
        display: block;
        color: #e3e2e2;
        font-size: get-vw(150px);
        font-weight: 900;
        -webkit-text-stroke: 10px #e3e2e2;
        text-align: center;
        line-height: 1;
        position: absolute;
        left: 50%;
        top: auto;
        transform: translateX(-50%);

        @include setWidthRwd(1090px,1600px){
            font-size: get-vw(200px);
        }
        
        @include pad1080{
            top: 2%;
            font-size: get-vw-mobile(125px);
        }
    }
}

.partner{
    &Title{
        width: 100%;
        color:#000;
        font-weight: 600;
        font-family: $Rubik;
        text-align: center;
        margin-bottom: 0.7rem;
    }

    &-DesignList{
        align-items: flex-end;
        justify-content: flex-start;
        flex-flow: row nowrap;
        
        .txt{
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            flex: 1 1 auto;
            max-width: 600px;
            min-height: 550px;
            padding:2rem 6rem;
            margin-bottom: 2rem;
            overflow: hidden;
            p{
                margin-bottom: 0.25rem;
            }

            &::before{
                content: '';
                display: block;
                width: 100%;
                height: 700px;
                @include setBackSet(right,contain);
                background-image: url('../images/bg-circlePink.png');
                position: absolute;
                right: -40%;
                bottom: -25%;
            }
            
            figure{
                margin-bottom: 2rem;
            }

            .info{
                font-size: get-vw(21px);
                color:#4f4e4e;
                @include pad1080{
                    font-size: get-vw-mobile(25px);
                }
            }
        }
        
        .img{
            overflow: hidden;
            img{
                width: 100%;
            }

            &-big{
                left: -11%;
                margin-right: -12%;
            }

            &-small{
                position: relative;
                left: -6%;
                margin-bottom: 2.25%;
                z-index: 2;
                
                &:nth-last-of-type(1){
                    left: -2.5%;
                    margin-bottom: 9%;
                }
            }
        }

        @include pad1080{
            align-items: flex-start;
            justify-content: center;
            flex-wrap: wrap;
            .txt{
                width: 100%;
                max-width: 100%;
                min-height: auto;
                padding: 3rem 2rem 4rem;
            }
            .img {
                right: 0;
                left: 0 !important;
                margin-right: 0;
                &-small{
                    width: 50%;
                }
                &-big{
                    order: 1;
                }
            }
        }
    }

    &-timeLine{
        &-Wrap{
            margin-top: 6rem;
            padding-top: get-vw(70px);
            overflow: hidden;

            .partnerTitle{
                @include fontSize(30px)
            }

            &::before{
                content: 'History';
                top: get-vw(-25px);
            }

            .timeline{
                &::after{
                    left: 0;
                    background-color: #898989;
                    top: 10px;
                    transform: translateY(0%);
                    width: 1px;
                    height: 100%;
                }

                .event {
                    padding-bottom: 30px;
                    .txt{
                        padding-left: 40px;
                        p{
                            margin-bottom: 0.5rem;
                        }
                        &::before{
                            width: 13px;
                            height: 13px;
                            border-radius: 50%;
                            background-color: #898989;
                            left: -36px;
                            top: 9px;
                        }
                        @include pad1080{
                            padding-left: 0;
                        }
                    }
                }
            }

            // 
            .swiper{
                &-wrapper{
                    /* 通过改变animation-timing-function 制作弹性切换效果 */
                    transition: 2s cubic-bezier(0.68, -0.4, 0.27, 1.34) 0.2s;
                    @include pad1080{
                        padding-bottom: 5rem;
                    }
                }

                &-button-next,
                &-button-prev {
                    width: 60px;
                    height: 60px;
                    background: #fff;
                    color: #000;
                    border-radius: 50%;
                    box-shadow: 0 0 12px 4px rgba(0,0,0,0.1);
                    &::after{
                        content: '';
                        display: inline-block;
                        width: 16px;
                        height: 19px;
                        background-repeat: no-repeat;
                        background-image: url('../images/icon-arrow03.png');
                        background-position: center;
                        background-size: contain;
                        position: absolute;
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%,-50%);
                    }

                    @include pad1080{
                        top: get-vw-mobile(175px);
                    }

                    @include mobile{
                        width: 50px;
                        height: 50px;
                        top: get-vw-mobile(350px);
                    }
                }

                &-button-prev{
                    &::after{
                        transform: rotate(-180deg) translate(-50%, -50%);
                        transform-origin: 0px 0px;
                    }
                }
            }

            @include setWidthRwd(1090px,1600px){
                padding-top: get-vw(100px);
            }

            @include pad1080{
                padding-top: 10rem; 

                &::before{
                    top: -4%;
                }
            }

            @include pad{
                margin-top: 0rem;
                &::before{
                    top: get-vw-mobile(25px);
                }
            }

            @include mobile{
                padding-top: 7rem;
            }
        }
        
        &-list{
            @extend %setFlex-center;
            justify-content: space-around;
            @include setMinRwd{
                max-width: 90%;
                margin: auto;
            }
            @include pad1080{
                flex-direction: column;
            }
        }

        &-img{
            text-align: center;
            .img{
                display: inline-block;
                max-width: 400px;
                height: 325px;
            }
        }
    }

    &-portfolio{
        &-Wrap{
            margin-top: 6rem;
            padding-top: 5.5rem;
            overflow: hidden;
            &::before{
                content: 'Portfolio';
                top: get-vw(-10px);
                @include pad1080{
                    font-size: get-vw-mobile(100px);
                    top: -10%;
                }

                @include pad{
                    top: -3%;
                }
                
                @include mobile{
                    top: get-vw-mobile(25px);
                }
            }
            .partnerTitle{
                @include fontSize(36px)
            }

            @include pad1080{
                margin-top: 1rem;
            }
        }
    }
}

.partnerCertify {
  position: relative;
  width: 100%;
  max-width: 1200px;
  margin: 5rem auto 0;

    .swiper{
        &-container {
            padding-bottom: 200px;
            @include pad1080{
                padding-bottom: 150px;
            }
        }

        &-slide {
            width: 440px;
            height: 440px;
            &-circle{
                height: 100%;
                overflow: hidden;
                border:#cfcdcd 10px solid;
                border-radius: 50%;
                transition: 0.5s;
                img{
                    @extend %imgPositon-center;
                }
            }

            &::after{
                content: '';
                display: block;
                width: 220px;
                height: 40px;
                @include setBackSet(center,contain);
                background-image: url('../images/partner-shadow01.png');
                position: absolute;
                bottom: -90px;
                left: 50%;
                transform: translateX(-50%);
                opacity: 0.75;
                @include pad1080{
                    bottom: -60px;
                }
            }

            img {
                display: block;
            }
            
            &-active{
                .swiper-slide-circle{
                    border-color: #eb6d80;
                }
                &::after{
                    opacity: 1;
                }
            }

            @include pad1080{
                width: 17rem;
                height: 17rem;
            }
        }
        
        &-pagination{
            margin-top: 4rem;
            &-bullets {
                width: 100% !important;
                bottom: 20px;
            }
            &-bullet {
                margin: 0 14px;
                background-color: #828181;
                width: 10px;
                height: 10px;
                opacity: 1;
                &-active {
                    background-color: #eb6d80;
                }
            }
        }

        &-button{
            &-next,
            &-prev {
                width: 60px;
                height: 60px;
                background: #fff;
                color: #000;
                border-radius: 50%;
                box-shadow: 0 0 12px 4px rgba(0,0,0,0.1);
                margin-top: -120px;
                &::after{
                    content: '';
                    display: inline-block;
                    width: 16px;
                    height: 19px;
                    background-repeat: no-repeat;
                    background-image: url('../images/icon-arrow03.png');
                    background-position: center;
                    background-size: contain;
                    position: absolute;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%,-50%);
                }
            }

            &-prev{
                &::after{
                    transform: rotate(-180deg) translate(-50%, -50%);
                    transform-origin: 0px 0px;
                }
            }
            
            @include setMinRwd{
                &-prev{
                    left: calc(50% - 250px);
                }

                &-next{
                    right: calc(50% - 250px);
                }
            }

            @include pad1080{
                &-next,
                &-prev {
                    width: 50px;
                    height: 50px;
                    margin-top: -85px;
                    
                }
                &-prev{
                    left: 10px;
                }

                &-next{
                    right: 10px;
                }
            }

            @include mobile{
                &-prev{
                    left: 50px;
                }

                &-next{
                    right: 50px;
                }
            }
        }
    }

    @include mobile{
        margin-top: 1rem;
    }
}

.partner-timeLine-img,
.partner-timeLine-list .timeline{
    @include setMinRwd{
        flex: 0 0 50%;
    }
}

.partner-timeLine-list .timeline{
    margin-top: 1.5rem;
    @include pad1080{
        margin: 1.5rem 1rem;
    }
}
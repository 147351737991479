.orderNote{
    &-list{
        display: flex;
        margin: 10px 0;
        .icon{
            margin-top: -6px;
        }
    }

    // 左邊
    &-title,
    &-txt{
        p{
            margin-bottom: 0.1rem;
        }
    }

    // 左邊
    &-title{
        flex:0 0 350px;
        padding-left: 1.5rem;
        padding-right: 4rem;
        margin-left: 60px;
        border-right: #cdcdcd 1px solid;
        .title{
            @include fontSize(22px);
            margin-bottom: 10px;
            line-height: 1.3;
        }

        &.style__dot{
            &::before {
                width: 15px;
                height: 15px;
                left: -5px;
                top: -6px;
                transform: translateY(0);
            }
        }
        figure{
            margin-top: 30px;
        }
    }

    // 右邊
    &-info{
        padding-left: 20px;
    }

        &-item{
            @include fontSize(19px);
            margin-bottom: 0.5rem;
        }

        &-txt{
            padding-left: 1rem;
            @include fontSize(17px);
            color:#51504f;
            margin-bottom: 1.25rem;
            &:last-child{
                margin-bottom: 0.25rem;
            }

            a{
                @include fontSize(18px);
            }

            .style__dot{
                padding-left: 1rem;
                &::before {
                    width: 7px;
                    height: 7px;
                    background-color: #615f5f;
                    left: 0;
                    top: 14px;
                    transform: translateY(0);
                }
            }
            &>li{
                padding-bottom: 10px;
                border-bottom: 2px dotted #c8c8c8;
                &+li{
                    margin-top: 0.5rem;
                }
            }
        }

    @include pad1024{
        &-list{
            flex-direction: column;
        }

         // 左邊
        &-title{
            flex:1;
            padding-left: 1.5rem;
            padding-right: 1rem;
            margin-left: 0px;
            border-right: none;
            figure {
                margin-top: 5px;
            }
        }
        
        // 右邊
        &-info{
            padding-left: 0;
        }
    }
}